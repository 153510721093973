
import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { SettingCategoryDto } from "../../domain";
import { CategoryMappers } from "../mappers"
import { JObject } from "../../../../common/data/models";
import { TawreedFileMapper } from "../../../upload/data";

export class CategoryRepository {


    public async getAll(): Promise<Array<SettingCategoryDto>> {
        return tawreedHttpService.post<JObject, Array<JObject>>(endpoints.SETTINGS_CATEGORY, {}, undefined, undefined, 'error')
            .then(async (res) => {
                const result: Array<SettingCategoryDto> = [];
                res.data?.forEach(el => {
                    result.push(CategoryMappers.toDto(CategoryMappers.fromJson(el)));
                })
                return result;
            });
    }
    public async addOrUpdate(data: SettingCategoryDto): Promise<SettingCategoryDto> {

        return tawreedHttpService.post<JObject, JObject>(endpoints.SETTINGS_CATEGORY_ADD_UPDATE, { ...data, image: '' }).then(async (res) => {

            await this.createOrUploadCategoryImage({ ...data, categoryId: res.data?.data.categoryId! });
            const result = await this.getDetails(res.data?.data.categoryId);
            return result;
        })
    }
    public async getDetails(data: number): Promise<SettingCategoryDto> {

        return tawreedHttpService.post<number, JObject>(endpoints.SETTINGS_CATEGORY_DETAILS, data, undefined, undefined, 'error')
            .then(async (res) => {
                return CategoryMappers.toDto(CategoryMappers.fromJson(res.data));
            });
    }

    public async createOrUploadCategoryImage(data: SettingCategoryDto) {
        console.log(data);
        if (data && data.categoryId && data.image && data.image.value instanceof File) {
            const uu = endpoints.UPLOAD_CATEGORY.replace(':categoryId', data.categoryId.toString());
            const cc = TawreedFileMapper.toFormData(data.image);
            return tawreedHttpService.upload(uu, cc);
        }
    }
    public async delete(data: number): Promise<any> {

        return tawreedHttpService.post<number, JObject>(endpoints.SETTINGS_CATEGORY_DELETE, data, undefined, undefined, 'error')
            .then(async (res) => {
                return res;
            });
    }

}
