import React from "react";
import {PartnerType} from "../../../data";
import {PartnerDto, PartnersService} from "../../../domain";
import {TawreedAutoComplete, TawreedControlRenderModeWithoutNested, TawreedControlRules} from "../../../../../common/components/form";
import {AutoCompleteChangeParams, AutoCompleteSelectParams} from "primereact/autocomplete";
import {GlobalContext} from "../../../../../context";
import {PartnerAutocompleteItemTemplate} from "./partner-autocomplete.item-template";

export type PartnerAutoCompleteComponentProps = {
    //
    partnerType?: PartnerType;
    active?: boolean;

    //
    service?: PartnersService;

    // autocomplete
    autoCompleteValue?: PartnerDto;
    autoCompleteName: string;
    autoCompleteRender: TawreedControlRenderModeWithoutNested;
    autoCompleteDisabled?: boolean;
    autoCompleteRules?: TawreedControlRules;
    autoCompleteField: string;
    autoCompleteOnSelect?(e: AutoCompleteSelectParams): void;
    autoCompleteOnChange?(e: AutoCompleteChangeParams): void;
};

export const PartnerAutoCompleteComponent: React.FC<PartnerAutoCompleteComponentProps> = (props) => {

    const context = React.useContext(GlobalContext);
    const service: PartnersService = props.service ?? new PartnersService();
    const search = (q: string) => {
        return service
            .search(props.partnerType, q, props.active)
            .then(res => {
                const result = [];
                if (res.data && res.data.content) {
                    result.push(...res.data.content);
                }
                return result;
            });
    };

    return (
        <TawreedAutoComplete name={props.autoCompleteName}
                             render={props.autoCompleteRender}
                             disabled={props.autoCompleteDisabled}
                             rules={props.autoCompleteRules}
                             field={props.autoCompleteField}
                             value={props.autoCompleteValue}
                             scrollHeight={'25em'}
                             onSelect={props.autoCompleteOnSelect}
                             onChange={props.autoCompleteOnChange}
                             onSearch={search}
                             itemTemplate={e => <PartnerAutocompleteItemTemplate rowData={e} partnersMenu={context?.constants?.constants?.partnerTypes}/>}/>
    );
}
