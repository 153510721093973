import { JObject } from "../../../../common/data/models";
import { PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { SaleOfferQuery } from "../../data/models/sale-offer-query";
import {SalesOfferRepository} from "../../data/repositories/index";
import {salesOfferDto} from "../dtos"
import { SalesOfferDetailsDto } from "../dtos/sales-offer-details";
import { SalesOfferItemDto } from "../dtos/sales-offer-item";
import { SummaryDto } from "../dtos/summary";
import {salesOfferDetailsMapper, salesOfferItemMapper, salesOfferMapper} from '../mappers'
import { SummaryMapper } from "../mappers/summary-mapper";

export class SalesOfferService{
    private readonly repository:SalesOfferRepository;

    constructor(){
        this.repository = new SalesOfferRepository();
    }

    public searchSalesoffer(request: PaginatedRequestParams): Promise<PaginatedResponse<salesOfferDto>> {
       
        return this.repository.searchSalesoffer(request).then(res => {
            return {
                ...res!,
                data: { ...res!.data!, content: res!.data!.content.map(e => salesOfferMapper.fromJson(e)) ?? [] },
            };
        });;
    }
    public create(form: SalesOfferDetailsDto, items: Array<SalesOfferItemDto>): Promise<number> {
        form.items = items;
        return this.repository.create(salesOfferDetailsMapper.toJson(form));
    }

    public activateAll(salesOffers: salesOfferDto[]): Promise<boolean> {
        const ids = salesOffers.map(e => e.salesOfferId!);
        return this.repository.activateAll(ids);
    }

    public deactivateAll(salesOffers: salesOfferDto[]): Promise<boolean> {
        const ids = salesOffers.map(e => e.salesOfferId!);
        return this.repository.deactivateAll(ids);
    }
    public activate(id: number): Promise<boolean> {
        return this.repository.activateAll([id]);
    }

    public deactivate(id: number): Promise<boolean> {
        return this.repository.deactivateAll([id]);
    }
    public deleteAll(ids: number[]): Promise<boolean> {
        return this.repository.deleteAll(ids);
    }
    public getDetails(id: number): Promise<SalesOfferDetailsDto> {
        return this.repository.getDetails(id);
    }

    public calcSellingPrice(SellingPriceItemDto :JObject): Promise<number> {
        return this.repository.calcSellingPrice(SellingPriceItemDto);
    }

    public calcSubTotalItem(grossTotalItemDto :JObject): Promise<number> {
        return this.repository.calcSubTotalItem(grossTotalItemDto);
    }

    public calcSummary(items: SalesOfferItemDto[]): Promise<SummaryDto> {
        var s = salesOfferItemMapper.toJsonArray(items);
        return this.repository.calcSummary(salesOfferItemMapper.toJsonArray(items)[0]).then(res =>{
            return SummaryMapper.fromJson(res);
            
        });
    }
}