import { BalanceDto } from "../../domain";
import { PrimeIcons } from "primereact/api";
import { ObjectUtils } from "../../../../helpers/object";
import { JObject } from "../../../../common/data/models";
import { Routes } from "../../../../router";
import balanceImg from "../../../../assets/dashoardicon/Balance.svg"


export const BalanceMapper = {

    fromJson(json?: JObject, scale?: number): BalanceDto {
        return {
            totalCredit: {
                name: 'sales',
                label: 'lbl_dashboard_balance_credit_total',
                image: balanceImg,
                icon: PrimeIcons.BARS,
                value: (constants) => `${ObjectUtils.toCurrency(json?.totalCredit, scale)} ${constants.info.currencyCode}`,
                to: (_constants, user) => Routes.ReportsFinancialStatementsPerPartner
            },
            totalDebit: {
                name: 'sales',
                label: 'lbl_dashboard_balance_debit_total',
                icon: PrimeIcons.BARS,
                value: (constants) => `${ObjectUtils.toCurrency(json?.totalDebit, scale)} ${constants.info.currencyCode}`,
                to: undefined,
            },
        };
    },
}
