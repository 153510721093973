import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { FC, useEffect } from "react";
import React from "react";
import { tawreedTranslationService } from "../../../../common/translations";
import { PaymentCommunication, PaymentTransaction } from "../../domain";
import { Panel } from "primereact/panel";
import { TawreedCalendar, TawreedFormField, TawreedInputNumber, TawreedInputText, TawreedMenuDropdown } from "../../../../common";

export interface PaymentOrderTrasactionProps {
    visible: boolean;

    hide: () => void;
    data: PaymentTransaction

}
export const OrderTrasactionDialog: FC<PaymentOrderTrasactionProps> =({ visible, hide, data })=>{
    // console.log(data.length);
    
    const [panelToggel, setPanelToggel] = React.useState<boolean[]>([]);

    const DeliveryCostDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text"/>
        </div>);
    }
    useEffect(()=>{
        if(data){
            var toggleArray: boolean[] = [];
            data.paymentCommunications.forEach(communication=>{
                toggleArray.push(false);
            });
            setPanelToggel(toggleArray);
        }
    },[data])
    return (
        <Dialog style={{ minWidth: '80%' }} header={tawreedTranslationService.translate('lbl_transaction_details')} visible={visible} footer={<DeliveryCostDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
             <React.Fragment>
            {data &&<Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                    <TawreedFormField name="paymentTransactionId" className="field col-12 md:col-6" title="lbl_payment_txn_paymentTransactionId">
                        <TawreedInputText name="paymentTransactionId" render="standalone" value={data.paymentTransactionId} disabled />
                    </TawreedFormField>
                    <TawreedFormField name="createdAt" className="field col-12 md:col-6" title="lbl_payment_txn_createdat">
                        <TawreedCalendar name="createdAt" render="standalone" value={data.createdAt} disabled />
                    </TawreedFormField>
                    <TawreedFormField name="paymentPartner" className="field col-12 md:col-6" title="lbl_payment_txn_payment_partner">
                        <TawreedInputText name="paymentPartner" render="standalone" value={data.paymentPartner.partnerName} disabled />
                    </TawreedFormField>
                    <TawreedFormField name="sellerPartner" className="field col-12 md:col-6" title="lbl_payment_txn_seller_partner">
                        <TawreedInputText name="sellerPartner" render="standalone" value={data.sellerPartner.partnerName} disabled />
                    </TawreedFormField>
                    <TawreedFormField name="buyerPartner" className="field col-12 md:col-6" title="lbl_payment_txn_buyer_partner">
                        <TawreedInputText name="buyerPartner" render="standalone" value={data.buyerPartner.partnerName} disabled />
                    </TawreedFormField>
                    <TawreedFormField name="amount" className="field col-12 md:col-6" title="lbl_payment_txn_amount">
                        <TawreedInputNumber mode="decimal" name="amount" render="standalone" value={data.amount} disabled />
                    </TawreedFormField>
                    <TawreedFormField name="status" className="field col-12 md:col-6" title="lbl_payment_txn_txnstatus">
                        <TawreedMenuDropdown disabled={true} menu="paymentransactionStatuses" name="status" render="standalone"  value={data.status}/>
                    </TawreedFormField>
                    <TawreedFormField name="type" className="field col-12 md:col-6" title="lbl_payment_txn_type">
                        <TawreedMenuDropdown disabled={true} menu="paymentTransactionType" name="type" render="standalone"  value={data.type}/>
                    </TawreedFormField>
                </div>
            </Panel>
}
             {
                    data && data.paymentCommunications.map((communication,i) => {
                        
                        return (
                            <Panel toggleable={false} collapsed={panelToggel[i]} onToggle={(e) => {
                                var toggle = panelToggel;
                                toggle[i] = e.value;
                                setPanelToggel(toggle);
                            }}
                                header={<span>{tawreedTranslationService.translate('lbl_payment_communication') + (i+1)}</span>}
                                className={'col-12'}>
                                <div className="grid">
                                <TawreedFormField name="paymentCommunicationId" className="field col-12 md:col-6" title="lbl_payment_communication_id">
                                        <TawreedInputText name="paymentCommunicationId" render="standalone" value={communication.paymentCommunicationId} disabled />
                                    </TawreedFormField>
                                    <TawreedFormField name="createdAt" className="field col-12 md:col-6" title="lbl_payment_communication_create_at">
                                        <TawreedCalendar name="createdAt" render="standalone" value={communication.createdAt} disabled />
                                    </TawreedFormField>
                                    <TawreedFormField name="responseStatus" className="field col-12 md:col-6" title="lbl_payment_communication_response_status">
                                        <TawreedInputText name="responseStatus" render="standalone" value={communication.responseStatus} disabled />
                                    </TawreedFormField>
                                </div>
                                <Splitter>
                                    <SplitterPanel>
                                        <Panel toggleable={false} header={tawreedTranslationService.translate('lbl_communication_request')}>
                                            {Object.keys(JSON.parse(communication.request)).map((key, i) => (
                                                <p key={i}>
                                                    <span>{key}: </span>
                                                    <span>{JSON.parse(communication.request)[key]}</span>
                                                </p>
                                            ))
                                            }
                                        </Panel>
                                    </SplitterPanel>
                                    <SplitterPanel>
                                        <Panel toggleable={false} header={tawreedTranslationService.translate('lbl_communication_response')}>
                                            {Object.keys(JSON.parse(communication.response)).map((key, i) => (
                                                <p key={i}>
                                                    <span>{key}: </span>
                                                    <span>{JSON.parse(communication.response)[key]}</span>
                                                </p>
                                            ))
                                            }
                                        </Panel>
                                    </SplitterPanel>
                                </Splitter>


                            </Panel>
                            
                        ) 
                })
             }
            </React.Fragment>
        </Dialog>
    )
} 