import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { TawreedAction, TawreedActionDialog } from "../../../../common/actions";
import { TawreedFormField, TawreedPassword } from "../../../../common/components/form";
import { tawreedTranslationService } from "../../../../common/translations";

export type UserChangePasswordDialogProps = {

};

export type UserChangePasswordDialogState = {
    /**
     *
     */
    visible: boolean;

    /**
     * 
     */
    password?: string;
    confirmedPassword? : string;
    error?: string
}

export class UserChangePasswordDialog extends React.Component<UserChangePasswordDialogProps, UserChangePasswordDialogState> implements TawreedActionDialog<any, any, string> {

    constructor(props: UserChangePasswordDialogProps) {
        super(props);

        this.state = {
            visible: false,
            password: undefined,
            confirmedPassword: undefined,
        };
    }

    private resolve: ((newPassword: string) => void) | undefined;
    private reject: (() => void) | undefined;

    private UserChangePasswordDialogFooter = () => {
        return (
            <div>
                <Button
                    label={tawreedTranslationService.translate('lbl_cancel')}
                    icon={PrimeIcons.TIMES}
                    onClick={() => this.onHide()}
                    className="p-button-text" />
                <Button
                    label={tawreedTranslationService.translate('lbl_accept')}
                    icon={PrimeIcons.CHECK}
                    onClick={() => this.onAccept()}
                    disabled={!this.state || !this.state.password || !this.state.password.length|| 
                        this.state.confirmedPassword != this.state.password} />
            </div>
        );
    }

    private onHide(): void {
        this.setState({ ...this.state, visible: false });
        if (this.reject) {
            this.reject();
        }
    }

    private onAccept(): void {
        if (this.resolve) {
            if (this.state.password && this.state.password.length) {
                this.setState({ ...this.state, visible: false });
                this.resolve(this.state.password);
            }
        }
    }

    private onPasswordChange(newPassword: string): void {
        this.setState({ ...this.state, password: newPassword })
    }

    private onConfirmPasswordChange(newPassword: string): void {
        if(newPassword ===this.state.password){

            this.setState({ ...this.state, confirmedPassword: newPassword, error:undefined})
        }
        else{
            this.setState({ ...this.state, confirmedPassword: newPassword ,error: "lbl_users_confirmed_password_not_equal" })
        }
    }

    private check():void{
        if(!this.state.password|| !this.state.password.length)
        {
            this.setState({ ...this.state, error: "lbl_users_new_password_required"})
        }
        else{
            if(!this.state.confirmedPassword|| !this.state.confirmedPassword.length)
            {
                this.setState({ ...this.state, error: "lbl_users_confirmed_password_required"})
            }
            else{

                if(this.state.confirmedPassword != this.state.password)
                {
                    this.setState({ ...this.state, error: "lbl_users_confirmed_password_not_equal"})
                }
                else{
                    this.setState({ ...this.state, error: undefined})
                }
            }
        }
    }

    public show(action: TawreedAction): Promise<string> {
        this.setState({ ...this.state, visible: true });
        return new Promise<string>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    public render() {
        //
        //
        return (
            <Dialog header={tawreedTranslationService.translate('lbl_change_password')}
            maximizable
                footer={this.UserChangePasswordDialogFooter()}
                onHide={() => this.onHide()}
                visible={this.state.visible}
                position="top"
                breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '33vw' }}
                draggable={true}
                closable={true}
                focusOnShow
                id="dialog-users-password-change"
                closeOnEscape={false}>
                <div className="grid formgrid">
                    <TawreedFormField name="newPassword" title="lbl_users_new_password" hint="*" hintStyle={{color:"red"}} className="field col-12">
                        <TawreedPassword render="standalone" value={this.state.password}  onBlur={e=>this.check()}  onChange={e => this.onPasswordChange(e.target.value)} />
                    </TawreedFormField>
                    <TawreedFormField name="confirmedPassword" title="lbl_users_confirmed_password" hintStyle={{color:"red"}}  hint="*" className="field col-12">
                        <TawreedPassword render="standalone" value={this.state.confirmedPassword}  onBlur={e=>this.check()} onChange={e => this.onConfirmPasswordChange(e.target.value)} />
                    </TawreedFormField>
                    <span style={{color:"red"}} className="ml-2">{tawreedTranslationService.translate(this.state.error)}</span>
                </div>
            </Dialog>
        );
    }
}
