import React, {KeyboardEvent} from 'react';
import {InputText, InputTextProps} from "primereact/inputtext";

export type TawreedInputSearchProps = InputTextProps & { onEnterPressed?: (value: any) => void };

export const TawreedInputSearch: React.FC<TawreedInputSearchProps> = (props) => {

    const [state, setState] = React.useState<any>(props.value ?? props.defaultValue ?? '');

    function onKeyPress(e: KeyboardEvent<HTMLInputElement>): void {
        const value = (e.target as HTMLInputElement).value;
        if (e.key === 'Enter') {
            onValueChange(value);
        }
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        setState(value);
        if (value === '') {
            onValueChange('');
        }
    }

    function onValueChange(e: string) {
        if (props.onEnterPressed) {
            props.onEnterPressed(e);
        }
    }

    return <InputText className={props.className} 
                      id={props.name}
                      name={props.name}
                      type={props.type}
                      placeholder={props.placeholder}
                      value={state} 
                      style={props.style}
                      onKeyPress={onKeyPress} onChange={onChange} autoComplete={props.autoComplete}/>
}
