import React from "react";
import { TopUpPartnerForm, TopUpPartnerTable } from "../components/partners";
import { TopUpTransactionForm, TopUpTransactionTable } from "../components/transactions";
import { WalletTransactionTable } from "../components/walletTransaction"
import { WalletTransactionForm } from "../components/walletTransaction/wallet-transaction-form"

/**
 * This page shows all topup partners in Tawreed platform
 * @returns
 */
export const TopUpPartnersPage: React.FC = () => <TopUpPartnerTable />;


/**
 * This page shows details about one topup partner or create new
 * @returns
 */
export const TopUpPartnerPage: React.FC = () => <TopUpPartnerForm />;

/**
 * @returns
 */
export const TopUpTransactionsPage: React.FC = () => <TopUpTransactionTable />;


/**
 *
 * @returns
 */
export const TopUpTransactionPage: React.FC = () => <TopUpTransactionForm />;


/**
 *
 * @returns
 */
export const WalletTransactionPage: React.FC = () => < WalletTransactionTable />;

/**
 *
 * @returns
 */
export const WalletTransactionDetails: React.FC = () => < WalletTransactionForm />;
