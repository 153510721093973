import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { CategoryService, SettingCategoryDto } from '../../domain';
import { CategorySetting } from '../../data';
import { Routes } from '../../../../router';
import { TawreedTable, TawreedTableProps } from '../../../../common/components/table';
import { CssUtils } from "../../../../helpers/css";
import { TawreedAction, TawreedActionName } from '../../../../common/actions';
import { PrimeIcons } from 'primereact/api';

export const CategoryTable: React.FC = (props) => {


    const history = useHistory();
    const service: CategoryService = React.useMemo(() => new CategoryService(), []);


    const [data, setData] = useState<Array<SettingCategoryDto>>([])

    const [loader, setLoader] = useState(false);

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.SettingsCategoryNew);
            resolve();
        })
    }

    React.useEffect(() => {
        setLoader(true)
        service.getAll().then(res => {
            setData(res);
            setLoader(false)
        })
    }, [service])

    const onForm = (sender?: CategorySetting): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.SettingsCategoryDetials.replace(':id', sender!.categoryId.toString()));
            resolve();
        })
    }


    const tawreed: TawreedTableProps = {
        pagination: true,
        dataKey: 'categoryId',
        name: 'tbl.categories',
        title: 'lbl_category',
        outLoader: loader,
        ds: {
            mode: 'basic',
            data: data,
        },
        columns: [
            {
                field: "categoryId",
                header: "lbl_category_id",
                style: CssUtils.width('22.5%'),
            },
            {
                field: "categoryAr",
                header: "lbl_category_ar",
                style: CssUtils.width('22.5%'),
            },
            {
                field: "categoryEn",
                header: "lbl_category_en",
                style: CssUtils.width('22.5%'),
            },
        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.CategoryNew, 'stateless', 'lbl_new', PrimeIcons.PLUS, onNew),
                // new TawreedAction(TawreedActionName.ProductsDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete'),
            ],
        },
        row: {
            onClick: new TawreedAction(TawreedActionName.CategoryDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onForm, 'p-button-secondary', false),
        },
    }

    return (<TawreedTable  {...tawreed} />);
}
