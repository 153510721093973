import React from 'react'
import { PrimeIcons } from 'primereact/api';
import { useHistory } from 'react-router-dom';

import { TawreedActionName, TawreedAction } from '../../../../../common/actions';
import { TawreedTable, TawreedTableProps } from '../../../../../common/components/table';
import { Routes } from '../../../../../router';
import { OrderDto, orderService as service } from '../../domain';
import { TawreedTableFilters } from '../../../../../common/components/table/filters';
import { GlobalContext } from '../../../../../context';
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { OrderStatus } from "../../data";
import { PartnerType } from "../../../../partners/data";
import { CssUtils } from "../../../../../helpers/css";
import { DataTableSelectionModeType } from 'primereact/datatable';
import { TawreedTableDataSourceTrigger } from '../../../../../common/components/table/source';

export type OrdersTableProps = {
    /**
     * use returned orders context?
     */
    returned?: boolean;

    /**
     * status id
     */
    statusId?: OrderStatus;

    /**
     * partner type
     */
    partnerType?: PartnerType;
    partnerId?: number;

    /**
     * carrierId
     */
    carrierId?: number;

    /**
     * actions
     */
    actions?: Array<TawreedAction>;

    name: string;

    selectionMode?: DataTableSelectionModeType;
    selectionSelectAllOnLoad?: boolean;
    triggerMode?: TawreedTableDataSourceTrigger;

    pagination?: boolean;
    status?:number,
    title?: string
}

export const OrdersTable: React.FC<OrdersTableProps> = ({ pagination, triggerMode, name,title, actions, returned, statusId, partnerId, partnerType, carrierId, selectionMode, selectionSelectAllOnLoad,status }) => {

    const {auth: { user }, constants: { constants } } = React.useContext(GlobalContext);

    const history = useHistory();

    const onForm = (sender?: OrderDto): Promise<void> => {
        return new Promise((resolve) => {
            const url = returned ? Routes.SalesOrdersReturnedDetails : Routes.SalesOrdersDetails;
            if (url && sender && sender.orderId) {
                history.push(url.replace(':id', sender.orderId.toString()))
            }
            resolve();
        });
    };
    const getStatusColumnDependOnPage = () => {
        const arr = [];
        if (!status)
            arr.push({
                dataType: 'text',
                field: "statusId",
                header: "lbl_sales_orders_status",
                sortable: true,
                filter: true,
                ...TawreedTableFilters.dropdown.dropdown("statusId", constants?.orderStatuses ?? [], 'value', 'label', 'in'),
                style: CssUtils.width('10%'),
            })
        return arr;
    }
    const getStoreColumnDependOnPage = () => {
        const arr = [];
        if (user?.roleCode != 'STORE_ADMIN' && user?.roleCode != 'STORE_DELIVERY' && user?.roleCode != 'STORE_SALES_MAN')
            arr.push({
                dataType: 'text',
                field: "store.partnerName",
                header: "lbl_sales_orders_store",
                sortable: true,
                sortField: 'store',
                filter: true,
                filterField: 'store',
                style: CssUtils.width('15%'),
            })
        return arr;
    }
    const getCustomerColumnsDependOnPage = () => {
        const arr = [];
        if (user?.roleCode != 'CUSTOMER_ADMIN')
            {
                arr.push({
                dataType: 'text',
                field: "customer.partnerName",
                header: "lbl_sales_orders_customer",
                sortable: true,
                sortField: 'customer',
                filter: true,
                filterField: 'customer',
                style: CssUtils.width('15%', CssUtils.color('var(--success-color)')),
            });
            arr.push({
                dataType: 'text',
                field: "customer.address.state",
                header: "lbl_sales_orders_customer_state",
                sortable: true,
                sortField: 'customerStateName',
                filter: true,
                filterField: 'customerStateName',
                
                style: CssUtils.width('10%'),
            })
        }
        return arr;
    }

    const getCarrierColumnDepnedOnPage = () =>{
        const arr = [];
        if (user?.roleCode != 'DELIVERY' && user?.roleCode != 'STORE_DELIVERY' && status != OrderStatus.Placed)
        arr.push({
            dataType: 'text',
            field: "carrier.partnerName",
            header: "lbl_sales_orders_carrier",
            sortable: true,
            sortField: 'carrierName',
            filter: true,
            filterField: 'carrierName',
            
            style: CssUtils.width('15%'),
        });
        return arr;
    }

    const tawreed: TawreedTableProps = {
        header: true,
        pagination: pagination ?? true,
        dataKey: 'customer',
        name: name,
        title: returned ? 'lbl_sales_returned_orders' : (title ? title :'lbl_sales_orders'),
        ds: {
            trigger: triggerMode,
            mode: 'lazy',
            onSearch: (req) => service.search(req, returned),
        },
        columns: returned ?
            [
                {
                    dataType: 'text',
                    field: "statusId",
                    header: "lbl_sales_orders_status",
                    sortable: true,
                    filter: true,
                    ...TawreedTableFilters.dropdown.dropdown("statusId", constants?.orderStatuses ?? [], 'value', 'label', 'in'),
                    style: CssUtils.width('10%'),
                },
                {
                    dataType: 'numeric',
                    field: "orderId",
                    header: "lbl_sales_orders_return_id",
                    sortable: true,
                    filter: true,
                    style: CssUtils.width('15%'),
                },
                {
                    dataType: 'numeric',
                    field: "salesOrderId",
                    header: "lbl_sales_orders_order_id",
                    sortable: true,
                    filter: true,
                    style: CssUtils.width('15%'),
                },
                {
                    field: "orderDate",
                    header: "lbl_sales_orders_date",
                    sortable: true,
                    filter: true,
                    ...TawreedTableFilters.date.range("orderDate", 'datetime'),
                    style: CssUtils.width('15%'),
                },
                {
                    dataType: 'text',
                    field: "customer.partnerName",
                    header: "lbl_sales_orders_customer",
                    sortable: true,
                    sortField: 'customer',
                    filter: true,
                    filterField: 'customer',
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'text',
                    field: "store.partnerName",
                    header: "lbl_sales_orders_store",
                    sortable: true,
                    sortField: 'store',
                    filter: true,
                    filterField: 'store',
                    style: CssUtils.width('20%'),
                },
                {
                    field: "orderTotal",
                    header: "lbl_sales_orders_order_total",
                    sortable: true,
                    filter: true,
                    ...TawreedTableFormatters.decimal('orderTotal', user?.scale, 0, CssUtils.width('15%')),
                    className: CssUtils.className.field.amount,
                },
            ]
            :
            [
                ...getStatusColumnDependOnPage(),
                {
                    dataType: 'numeric',
                    field: "orderId",
                    header: "lbl_sales_orders_header_order_id",
                    sortable: true,
                    filter: true,
                    style: CssUtils.width('15%', CssUtils.color('var(--danger-color)')),
                },
                {
                    field: "orderDate",
                    header: "lbl_sales_orders_header_date",
                    sortable: true,
                    filter: true,
                    ...TawreedTableFilters.date.range("orderDate", 'datetime'),
                    style: CssUtils.width('15%'),
                },
                ...getStoreColumnDependOnPage(),             
                ...getCustomerColumnsDependOnPage(),
                ...getCarrierColumnDepnedOnPage(),
                {
                    dataType: 'text',
                    field: "priceListItem.name",
                    header: "lbl_sales_orders_header_pricelistid",
                    sortable: true,
                    sortField: 'priceListName',
                    filter: true,
                    filterField: 'priceListName',
                    style: CssUtils.width('15%'),
                },
                {                    
                    field: "orderTotal",
                    header: "lbl_sales_orders_header_order_total",
                    sortable: true,
                    filter: true,
                    ...TawreedTableFormatters.decimal('orderTotal', user?.scale, 0, CssUtils.width('10%')),
                    className: CssUtils.className.field.amount,
                },
            ],
        toolbar: {
            actions: actions ?? [],
        },
        row: {
            onClick: new TawreedAction(TawreedActionName.OrdersDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onForm),
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                carrierId: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: carrierId,
                            matchMode: 'equals',
                        }
                    ]
                },
                customerId: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: partnerType && partnerType === PartnerType.Customer ? partnerId : undefined,
                            matchMode: 'equals',
                        }
                    ]
                },
                storeId: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: partnerType && partnerType === PartnerType.Store ? partnerId : undefined,
                            matchMode: 'equals',
                        }
                    ]
                },
                statusId: {
                    operator: 'AND',
                    constraints: [
                        status ? { value: [status], matchMode: 'in' }: (statusId ? { value: [statusId], matchMode: 'in' } : { value: null, matchMode: 'in' }),
                    ]
                },
                priceListName:{
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        }
                    ]
                },
                customerStateName: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        }
                    ]
                },
                carrierName: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        }
                    ]
                },
                orderId: {
                    operator: 'AND',
                    constraints: [
                        { value: null, matchMode: 'equals' },
                    ]
                },
                orderDate: {
                    operator: 'AND',
                    constraints: [
                        { value: null, matchMode: 'dateIs' }
                    ]
                },
                customer: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        }
                    ]
                },
                store: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        }
                    ]
                },
                orderTotal: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'orderId',
                sortOrder: -1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: selectionMode ?? 'single',
            selectAllOnLoad: selectionSelectAllOnLoad ?? undefined,
        },
    }

    return (<TawreedTable  {...tawreed} />);
}
