import { DateUtils } from "../../../../helpers/date";
import { PaymentCommunication } from "../../domain";
import { JObject } from "../../../../common/data/models";

export const PaymentCommunicationMapper = {

    fromJson(obj: JObject): PaymentCommunication
    {
        return {
            createdAt: DateUtils.fromISO(obj.createdAt),
            updatedAt: DateUtils.fromISO(obj.updatedAt),
            paymentCommunicationId: obj.paymentCommunicationId,
            request: obj.request,
            response: obj.response,
            responseStatus: obj.responseStatus
        }
    }
}