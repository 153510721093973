import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { Routes } from '../../../../../router';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { JObject } from '../../../../../common/data/models';
import { ImportHelperService, StoreRawDto, StoreRawMatchDto } from '../../../import-helper/domain';
import { OrderList } from 'primereact/orderlist';
import { Knob } from 'primereact/knob';
import { ImageViewer } from './image-viewer';
// import "./helper.scss"
import { IStoreProductRawStats } from '../../../../constants';
import { ProductDialog } from '../../../store-products/presentaion/components/product-dialog';
import { ProductDto, ProductMatchDto, ProductsService } from '../../../products/domain';
export interface ImportMatchProps {
    visible: boolean;

    hide: () => void;

    accept: (data: JObject) => void;

    productId: number;
    productName: string;

}

export const UnverifiedMatchDialog: FC<ImportMatchProps> = ({ visible, hide, accept, productId,productName }) => {

    const [data, setData] = useState<ProductMatchDto[]>([]);
    const [selectedItem, setSelectedItem] = useState<ProductMatchDto | undefined>(undefined);
    const [popUp, setPopUp] = useState(false);
    const UnverifiedDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={false} />
            <Button label={tawreedTranslationService.translate('lbl_update_match')} icon="pi pi-check" onClick={() => {
                accept(selectedItem!)
                hide();
            }} className="p-button-success" disabled={!selectedItem} />

        </div>);
    }
    const service: ProductsService = useMemo(() => new ProductsService(), []);

    const updateMatches = (result: ProductDto) => {
        const newMatches = [...data ?? []];
        newMatches.push({
            categoryId: result.categoryId,
            productId: result.productId,
            score: 0,
            displayName: result.displayName,
            internationalCode: result.internationalCode,
            productName: result.productName,
            baseImage: `${process.env.REACT_APP_S3_BUCKET}/products/${result.internationalCode}`,
            secondImage: `${process.env.REACT_APP_S3_BUCKET}/categories/${result.categoryId}`

        })
        setData(newMatches);
    }

    useEffect(() => {
        service.getMatches(productId).then((res:any) => {
            setData(res);
            if (res.matches && res.matches.length) {
                let item = (res.status === IStoreProductRawStats.EXACT_MATCH) ? res.matches[0] : (res.matches.find((el:any) => el.status === 'MANUAL') ?? undefined);
                setSelectedItem(item);
            }
        })
    }, [service, productId])


    const itemTemplate = (item: ProductMatchDto) => {
        return (
            // <div onClick={() => { setSelectedItem(item) }} className={`flex flex-wrap p-2 align-items-center gap-3 ${selectedItem && selectedItem.storeProductRawMatchId === item.storeProductRawMatchId ? "selectedBonus" : ""}`}>
            <div onClick={() => { setSelectedItem(item) }} className={`flex flex-wrap p-2 align-items-center gap-3 ${selectedItem && selectedItem.productId === item.productId ? "selectedBonus" : ""}`}>
                <ImageViewer baseImage={item.baseImage ?? ""} secondImage={item.secondImage ?? ""}></ImageViewer>
                <div className="flex-1 flex flex-column gap-2 xl:mr-8">
                    <span className="font-bold">{item.displayName}</span>
                    <div className="flex align-items-center gap-2">
                        <i className="pi pi-tag text-sm"></i>
                        <Link to={Routes.ProductsDetails.replace(':id', item!.productId!.toString())} target='_blank' >{item.internationalCode}</Link>
                    </div>
                </div>
                <div>
                    <span className="font-bold text-900"><Knob size={70} value={+(item.score! * 100).toFixed(0)} readOnly /></span>
                </div>
            </div>
        );
    };




    return (
        <Dialog maximizable resizable style={{ minWidth: '70%' }} header={''} visible={visible} footer={<UnverifiedDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            {!data && <div className="form-tawreed-loader text-center">
                <i className=" pi-spin pi pi-spinner" />
            </div>}
            {data && <OrderList listStyle={{ minHeight: 'auto' }} dataKey="id" value={data ?? []} onChange={(e) => { console.log(e) }} itemTemplate={itemTemplate} header={tawreedTranslationService.translate("lbl_matches")+ (" - " + productName) } ></OrderList>}
            {data && <div className="p-2 font-bold">
                <NavLink to={"#"} onClick={() => setPopUp(true)}>{tawreedTranslationService.translate("lbl_store_product_all_browse")}</NavLink>
            </div>}
            {popUp &&
                <ProductDialog
                    accept={(data: ProductDto) => {
                        updateMatches(data)
                        setPopUp(false);
                    }}
                    hide={() => setPopUp(false)}
                    isDiscount
                    visible={popUp}
                    isVerfied={true}
                    productName={productName.split(' ').at(0)}
                >

                </ProductDialog>
            }
        </Dialog>
    )
}
