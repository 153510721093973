import { PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { DeliveryPartnerRepository } from "../../data";
import { DeliveryPartner } from "../dtos";

export class DeliveryPartnerService {

    private readonly repository;

    constructor() {
        this.repository = new DeliveryPartnerRepository();
    }

    public search(req: PaginatedRequestParams): Promise<PaginatedResponse<DeliveryPartner>> {
        return this.repository.search(req);
    }

    /**
     * Create or update partner instance
     * @param type
     * @param form
     */
    public createOrUpdate(form: DeliveryPartner): Promise<DeliveryPartner> {
        return this.repository.createOrUpdate(form);
    }

    /**
     * Delete partner by id
     * @param id
     */
    public delete(id: number): Promise<boolean> {
        return this.repository.delete(id);
    }

    public deleteDeliveryCost(id: number): Promise<boolean>{
        return this.repository.deleteDeliveryCost(id);
    }

    /**
     * Delete all partners by ids
     * @param ids
     */
    public deleteAll(ids: number[]): Promise<boolean> {
        return this.repository.deleteAll(ids)
    }

    /**
     * Get partner details by id
     * @param id
     */
    public getDetails(id: number): Promise<DeliveryPartner> {
        return this.repository.getDetails(id);
    }

    public activateAll(ids: number[]): Promise<boolean> {
        return this.repository.activateAll(ids);
    }

    public deactivateAll(ids: number[]): Promise<boolean> {
        return this.repository.deactivateAll(ids);
    }
}