import React from "react"
import { Panel } from "primereact/panel"
import { useParams } from "react-router-dom"

import { TawreedCalendar, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputText, TawreedMenuDropdown } from "../../../../../common/components"
import { tawreedTranslationService } from "../../../../../common/translations"
import { PartnerType } from "../../../../partners/data"
import { PartnerBusinessTypeDropdown } from "../../../../partners/presentaion/components/partner-business-type-dropdown"
import { PaymentTransactionService, PaymentTransaction } from "../../../domain"
import { GlobalContext } from "../../../../../context"

export const PaymentTransactionForm: React.FC = () => {

    const { id } = useParams<{ id: string | undefined }>();
    const { auth: { user } } = React.useContext(GlobalContext);
    const service: PaymentTransactionService = React.useMemo(() => new PaymentTransactionService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<PaymentTransaction>();

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {
            if (mounted) {
                setMode('Create');
                setData(undefined);
            }
        }
        return () => {
            mounted = false;
        };
    }, [id, service]);


    const onSubmit: TawreedFormSubmitProps<PaymentTransaction> = { showButton: false, resetOnSubmit: false };

    return (
        <TawreedForm title="lbl_payment_transactions"
            dataKey="paymentTransactionId" data={data}
            submit={onSubmit}
            loading={loading} mode={mode}
            useBack=".." className="grid form-grid">
            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                    <TawreedFormField name="paymentTransactionId" className="field col-12 md:col-6" title="lbl_payment_transactions">
                        <TawreedInputText name="paymentTransactionId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="createdAt" className="field col-12 md:col-6" title="lbl_payment_txn_createdat">
                        <TawreedCalendar name="createdAt" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="paymentPartner.partnerName" className="field col-12 md:col-6" title="lbl_payment_txn_payment_partner">
                        <TawreedInputText name="paymentPartner.partnerName" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="sellerPartner.partnerName" className="field col-12 md:col-6" title="lbl_payment_txn_seller_partner">
                        <TawreedInputText name="sellerPartner.partnerName" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                    
                    <TawreedFormField name="buyerPartner.partnerName" className="field col-12 md:col-6" title="lbl_payment_txn_buyer_partner">
                        <TawreedInputText name="buyerPartner.partnerName" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>


                    {/* <TawreedFormField name="topUpPartner.businessType" className="field col-12 md:col-6" title="lbl_topup_txn_topuptype">
                        <PartnerBusinessTypeDropdown partnerType={PartnerType.TopUp} name="topUpPartner.businessType" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField> */}

                    <TawreedFormField name="amount" className="field col-12 md:col-6" title="lbl_payment_txn_amount">
                        <TawreedInputNumber mode="decimal" name="amount" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="status" className="field col-12 md:col-6" title="lbl_payment_txn_txnstatus">
                        <TawreedMenuDropdown name="status" menu="paymentransactionStatuses" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="mawridUniqueReference" className="field col-12 md:col-6" title="lbl_payment_txn_mawrid_unique_reference">
                        <TawreedInputText name="mawridUniqueReference" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="partnerUniqueReference" className="field col-12 md:col-6" title="lbl_payment_txn_partner_unique_reference">
                        <TawreedInputText name="partnerUniqueReference" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="type" className="field col-12 md:col-6" title="lbl_payment_txn_type">
                        <TawreedMenuDropdown name="type" menu="paymentTransactionTypes" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="otpTrials" className="field col-12 md:col-6" title="lbl_topup_txn_otp_trials">
                        <TawreedInputNumber mode="decimal" name="otpTrials" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    {/* <TawreedFormField name="transactionCashBackValue" className="field col-12 md:col-6" title="lbl_topup_txn_txncbvalue">
                        <TawreedInputNumber mode="decimal" name="transactionCashBackValue" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="transactionFeesTotal" className="field col-12 md:col-6" title="lbl_topup_txn_txnfeestotal">
                        <TawreedInputNumber mode="decimal" name="transactionFeesTotal" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                    {
                        user &&
                        (user.roleCode === 'ADMIN' || user.roleCode === 'TOPUP_ADMIN' || user.roleCode === 'FINANCE') &&
                        <React.Fragment>
                            <TawreedFormField name="transactionFeesPartner" className="field col-12 md:col-6" title="lbl_topup_txn_txnfeespartner">
                                <TawreedInputNumber mode="decimal" name="transactionFeesPartner" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                            </TawreedFormField>

                            <TawreedFormField name="transactionFeesPlatform" className="field col-12 md:col-6" title="lbl_topup_txn_txnfeesplatform">
                                <TawreedInputNumber mode="decimal" name="transactionFeesPlatform" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                            </TawreedFormField>

                            <TawreedFormField name="transactionLog" className="field col-12 md:col-6" title="lbl_topup_txn_txnlog">
                                <TawreedInputText name="transactionLog" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                            </TawreedFormField>
                        </React.Fragment>
                    }
                    <TawreedFormField name="topUpReferenceId" className="field col-12 md:col-6" title="lbl_topup_txn_referenceid">
                        <TawreedInputText name="topUpReferenceId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField> */}
                </div>
            </Panel>
        </TawreedForm>
    )
}