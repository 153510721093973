import { JObject } from "../../../../common/data/models";
import { WalletTransactionDto } from "../../domain";


export const WalletTransactionMapper = {

    fromJson(obj: JObject): WalletTransactionDto {
        return {
            walletTransactionId: obj.walletTransactionId,
            walletTransactionReference: obj.walletTransactionReference,
            walletId: obj.walletId,
            amount: obj.amount,
            transactionTime: obj.transactionTime,
            channel: obj.channel,
            debitor: obj.debitor,
            description: obj.description,
            status: obj.status,
            logs: obj.logs,
            failedReason: obj.failedReason,
            partnerTopup: obj.partnerTopup,
            partnerName: obj.partnerName,
        };
    },
}
