import { Product } from "../models";
import { JObject } from "../../../../../common/data/models";
import { ProductTranslatableMapper } from "./product-translatable";
import { ProductDto } from "../../domain";
import { ProductFieldMapper } from "./product-field";
import { TawreedFileMapper } from "../../../../upload/data";

export const ProductMapper = {
    /**
     * Create cart model from json object
     * @param json
     * @returns {Partner}
     */
    fromJson: (json?: JObject): Product => {
        return {
            productId: json?.productId,
            internationalCode: json?.internationalCode,
            productName: json?.productName,
            productNameEn: json?.productNameEn,
            company: json?.company,
            active: json?.active,
            retailPrice: json?.retailPrice,
            productTax: json?.productTax,
            image: json?.image,
            translations: Array.from<any>(json?.translations ?? []).map(e => ProductTranslatableMapper.fromJson(e)),
            categoryId: json?.categoryId,
            fields: Array.from<any>(json?.fields ?? []).map(e => ProductFieldMapper.fromJson(e)),
            price: json?.price,
            keywords: json?.keywords,
            displayName: json?.displayName,
            isVerified: json?.isVerified,
            alternativeNames: json?.alternativeNames,
            sellerCount: json?.sellerCount,
        }
    },

    /**
     *
     */
    toJson: (model: Product): JObject => {
        return {
            ...model,
            translations: model.translations.map(e => ProductTranslatableMapper.toJson(e)),
        };
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {Product}
     */
    fromDto: (dto: ProductDto): Product => {
        return {
            productId: dto.productId!,
            internationalCode: dto.internationalCode!,
            active: dto.active!,
            company: dto.company!,
            productName: dto.productName!.trim(),
            productNameEn: dto.productNameEn!.trim(),
            translations: [
                {
                    langCode: 'en',
                    company: dto.companyEn!,
                    productName: dto.productNameEn!.trim(),
                }
            ],
            retailPrice: dto.retailPrice!,
            categoryId: dto.categoryId!,
            productTax: dto.productTax!,
            // image: undefined,
            fields: dto.fields ?? [],
            price: dto.price,
            keywords: dto.keywords,
            displayName: dto.displayName,
            
            isVerified: dto.isVerified,
            alternativeNames: dto.alternativeNames
        }
    },

    /**
     *
     */
    toDto: (model: Product): ProductDto => {
        return {
            productId: model.productId,
            internationalCode: model.internationalCode,
            active: model.active,
            productName: model.productName,
            productNameEn: model.productNameEn,
            company: model.company,
            companyEn: model.translations[0]?.company,
            retailPrice: model.retailPrice,
            categoryId: model.categoryId,
            productTax: model.productTax,
            image: TawreedFileMapper.fromJson({
                fileName: `PRODUCTS_IMAGE_${model.productId}`,
                value: model.image,
            }),
            fields: model.fields?.map(e => ProductFieldMapper.toDto(e)),
            price: model.price,
            keywords: model.keywords,
            displayName: model.displayName,
            sellerCount: model.sellerCount,
            isVerified: model.isVerified
        };
    },
}
