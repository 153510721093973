import React from "react"
import { Panel } from "primereact/panel"
import { useParams } from "react-router-dom"

import { TawreedCalendar, TawreedDropdown, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputText, TawreedMenuDropdown } from "../../../../../common/components"
import { tawreedTranslationService } from "../../../../../common/translations"
import { PaymentTransaction, PaymentorderService, PaymentOrder } from "../../../domain"
import { GlobalContext } from "../../../../../context"
import { PaymentOrderTransactionsTable } from "./payment-order-transctions-table"

export const PaymentOrderForm: React.FC = () => {

    const { id } = useParams<{ id: string }>();
    const { auth: { user } } = React.useContext(GlobalContext);
    const service: PaymentorderService = React.useMemo(() => new PaymentorderService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<PaymentOrder>();

    React.useEffect(() => {
        setLoading(true);
            service.getDetails(Number.parseInt(id))
                .then(res => {
                    setLoading(false);
                    setData(res);
                })
                .catch(() => {
                    setLoading(false);
                })
    }, [id, service]);


    const onSubmit: TawreedFormSubmitProps<PaymentTransaction> = { showButton: false, resetOnSubmit: false };

    return (
        <TawreedForm title="lbl_payment_orders"
            dataKey="paymentTransactionId" data={data}
            submit={onSubmit}
            loading={loading} mode={"Edit"}
            useBack=".." className="grid form-grid">
            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                    <TawreedFormField name="paymentOrderId" className="field col-12 md:col-6" title="lbl_payment_orders_id">
                        <TawreedInputText name="paymentOrderId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                    <TawreedFormField name="orderId" className="field col-12 md:col-6" title="lbl_payment_orders_orderId">
                        <TawreedInputText name="orderId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                    <TawreedFormField name="cartId" className="field col-12 md:col-6" title="lbl_payment_orders_cart_id">
                        <TawreedInputText name="cartId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                

                    <TawreedFormField name="createdAt" className="field col-12 md:col-6" title="lbl_payment_orders_createdat">
                        <TawreedCalendar name="createdAt" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="sellerPartner.partnerName" className="field col-12 md:col-6" title="lbl_payment_orders_seller_partner">
                        <TawreedInputText name="sellerPartner.partnerName" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                    
                    <TawreedFormField name="buyerPartner.partnerName" className="field col-12 md:col-6" title="lbl_payment_orders_buyer_partner">
                        <TawreedInputText name="buyerPartner.partnerName" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="paymentOrderAmount" className="field col-12 md:col-6" title="lbl_payment_orders_payment_amount">
                        <TawreedInputNumber mode="decimal" name="paymentOrderAmount" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>                    
                    <TawreedFormField name="total" className="field col-12 md:col-6" title="lbl_payment_orders_order_total">
                        <TawreedInputNumber mode="decimal" name="total" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                    <TawreedFormField name="paymentOrderStatus" className="field col-12 md:col-6" title="lbl_payment_orders_status">
                        <TawreedMenuDropdown disabled={true} menu="paymentOrderStatuses" name="paymentOrderStatus" render="form" />
                    </TawreedFormField>

                    <TawreedFormField name="blockAmountTrials" className="field col-12 md:col-6" title="lbl_payment_orders_block_amount_trials">
                        <TawreedInputText name="blockAmountTrials" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <PaymentOrderTransactionsTable data={data?.transactions} className="col-12">

                    </PaymentOrderTransactionsTable>
                </div>
            </Panel>
        </TawreedForm>
    )
}