
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedFormField, TawreedInputFile, TawreedInputNumber, TawreedInputText, TawreedMenuDropdown } from '../../../../../common';
import { StatesDropdown } from '../../../../states';
import { TawreedFile } from '../../../../upload';
import { Panel } from 'primereact/panel';
import { OrderList } from 'primereact/orderlist';
import { PrimeIcons } from 'primereact/api';

export interface OrderDocumentUploadDialogProps {
    visible: boolean;

    hide: () => void;

    accept: (data: { files: Array<File>, title: string, type: string }) => void;

}

export const OrderDocumentUploadDialog: FC<OrderDocumentUploadDialogProps> = ({ visible, hide, accept }) => {


    const [dialogState, setDialogState] = useState<{
        files: Array<File> | undefined,
        title: string | undefined,
        type?: string;
    }>({
        files: [],
        title: undefined,
        type: undefined,
    });


    useEffect(() => {

        setDialogState({
            files: [],
            title: undefined,
            type: undefined,
        })
    }, [visible])


    const OrderDocumentUploadDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" /*disabled={dialogState.loading}*/ />
            <Button onClick={() => {
                accept({
                    files: dialogState.files!,
                    title: dialogState.title!,
                    type: dialogState.type!
                });
                hide();
            }} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" disabled={!dialogState || !dialogState.files?.length || !dialogState.title || !dialogState.type}
            // loading={dialogState.loading}
            />
        </div>);
    }
    const itemTemplate = (el: File) => {
        return (
            // <div onClick={() => { setSelectedItem(item) }} className={`flex flex-wrap p-2 align-items-center gap-3 ${selectedItem && selectedItem.storeProductRawMatchId === item.storeProductRawMatchId ? "selectedBonus" : ""}`}>
            <div className={`flex flex-wrap p-2 align-items-center gap-3`}>
                <div className="flex-1 flex flex-column gap-2 xl:mr-8">
                    <span className="font-bold">{el.name}</span>
                </div>
                <div>
                    <span className="font-bold text-900"> <Button onClick={(e) => setDialogState({ ...dialogState, files: (dialogState.files ?? []).filter((el1: File) => el1.name !== el.name) })} icon={PrimeIcons.TRASH} className='p-button-danger'></Button>
                    </span>
                </div>
            </div>
        );
    };

    return (
        <Dialog style={{}} header={tawreedTranslationService.translate('lbl_document_files_add_file')} visible={visible} footer={<OrderDocumentUploadDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <form className="formgrid grid" >
                <React.Fragment>
                    <TawreedFormField title="lbl_files_file" className="field col-12" name="file">
                        <TawreedInputFile render='standalone' name="file" onChange={
                            (e: TawreedFile | undefined) => setDialogState({ ...dialogState, files: e && e.value instanceof File ? [...dialogState.files ?? [], e.value] : dialogState.files })
                        } value={null} />
                    </TawreedFormField>
                    <OrderList className='col-12' listStyle={{ minHeight: 'auto' }} dataKey="id" value={dialogState.files ?? []} onChange={(e) => { console.log(e) }} itemTemplate={itemTemplate} header={tawreedTranslationService.translate("lbl_files")} ></OrderList>

                    <TawreedFormField name="title" title="lbl_document_files_title" className="field col-12 md:col-12 mt-3">
                        <TawreedInputText value={dialogState?.title} name="title" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                            setDialogState({ ...dialogState, title: e.target.value });
                        }} />

                    </TawreedFormField>
                    <TawreedFormField name="type" title="lbl_reports_documents_documenttype" className="field col-12 md:col-12">
                        <TawreedMenuDropdown value={dialogState.type} menu="orderDocumentTypes" name="type" render="standalone" onChange={(e) => { setDialogState({ ...dialogState, type: e.target.value }) }} />
                    </TawreedFormField>

                </React.Fragment>
            </form>
        </Dialog>
    )
}
