import React from "react";
import {TawreedFormField, TawreedMenuDropdown} from "../../../../../../../../common/components/form";
import {tawreedTranslationService} from "../../../../../../../../common/translations";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {PrimeIcons} from "primereact/api";
import {TawreedActionDialog} from "../../../../../../../../common/actions";
import {GlobalContext} from "../../../../../../../../context";

export type OrderCancelDialogProps = {};

export type OrderCancelDialogState = {

    reason: number | undefined;

    visible: boolean;
}

export class OrderCancelDialog extends React.Component<OrderCancelDialogProps, OrderCancelDialogState> implements TawreedActionDialog<any, any, number> {

    static contextType = GlobalContext;

    constructor(props: OrderCancelDialogProps) {
        super(props);

        this.state = {
            visible: false,
            reason: undefined,
        };
    }

    private resolve: ((carrier: number) => void) | undefined;
    private reject: (() => void) | undefined;

    private OrderCancelDialogFooter = () => {
        return (
            <div>
                <Button label={tawreedTranslationService.translate('lbl_cancel')} icon={PrimeIcons.TIMES} onClick={() => this.onHide()} className="p-button-text"/>
                <Button label={tawreedTranslationService.translate('lbl_accept')}
                        icon={PrimeIcons.CHECK}
                        onClick={() => this.onAccept()}
                        disabled={!this.state || !this.state.reason}/>
            </div>
        );
    }

    private onHide(): void {
        if (this.reject) {
            this.setState({...this.state, visible: false});
            this.reject();
        }
    }

    private onAccept(): void {
        if (this.resolve) {
            if (this.state.reason) {
                this.setState({...this.state, visible: false});
                this.resolve(this.state.reason);
            }
        }
    }

    private onReasonSelect(value: number): void {
        this.setState({...this.state, reason: value})
    }

    public show(): Promise<number> {
        this.setState({...this.state, visible: true});
        return new Promise<number>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    public render() {
        return (
            <Dialog header={tawreedTranslationService.translate('lbl_sales_orders_cancel')}
                    footer={this.OrderCancelDialogFooter()}
                    onHide={() => this.onHide()}
                    visible={this.state.visible}
                    position="top"
                    breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '33vw'}}
                    draggable={false}
                    closable={false}
                    closeOnEscape={false}>
                <div className="grid formgrid">
                    <TawreedFormField name="carrier" title="lbl_sales_orders_cancel_reason" className="field col-12">
                        <GlobalContext.Consumer>
                            {
                                (context) => <TawreedMenuDropdown render="standalone" menu={context.auth.user?.roleCode === "CUSTOMER_ADMIN" ? "cancelReasonsCustomer" : "cancelReasons"} value={this.state.reason} onChange={e => this.onReasonSelect(e.value)}/>
                            }
                        </GlobalContext.Consumer>
                    </TawreedFormField>
                </div>
            </Dialog>
        );
    }
}
