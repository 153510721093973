import React, { FC, useEffect, useMemo, useState } from 'react';
export interface ImageViewerProps {
    baseImage: string;

    secondImage: string;



}

export const ImageViewer: FC<ImageViewerProps> = ({ baseImage, secondImage }) => {

    const [state, setState] = useState(baseImage);

    return (
        <img style={{ width: "100px", height: "100px" }} className="shadow-2 flex-shrink-0 border-round" src={state} onError={() => { setState(secondImage) }} alt={""} />

    )
}
