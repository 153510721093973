import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { FileUpload, FileUploadBeforeUploadParams, FileUploadErrorParams, FileUploadHandlerParam, FileUploadSelectParams, FileUploadUploadParams } from 'primereact/fileupload';
import React, { FC, useRef, useState } from 'react'
import { tawreedTranslationService } from '../../../../common/translations';
import { PrimeIcons } from 'primereact/api';

export interface DeleteMyAccountNotesProps {
    visible: boolean;

    hide: () => void;
    accept: () => void
}

export const DeleteMyAccountNotes: FC<DeleteMyAccountNotesProps> = ({ visible, hide, accept }) => {


    const ImportProductsDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon={PrimeIcons.TIMES} className="p-button-text" disabled={false} onClick={hide} />
            <Button label={tawreedTranslationService.translate('lbl_continue')} icon="pi pi-check" onClick={accept} disabled={false} />
        </div>);
    }

    return (
        <Dialog style={{ maxWidth: '50%' }} header={tawreedTranslationService.translate('lbl_delete_my_account')} visible={visible} footer={<ImportProductsDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>

            <h6 className='font-bold'>{tawreedTranslationService.translate("lbl_delete_1")}</h6>
            <ul className='p-confirm-dialog-message'>
                <li className='my-4 font-bold p-confirm-dialog-message'>{tawreedTranslationService.translate("lbl_delete_2")}</li>
                <li className='p-confirm-dialog-message font-bold '>
                    <p>{tawreedTranslationService.translate("lbl_delete_3")}</p>
                    <ol className=''>
                        <li className='my-2 font-bold'>{tawreedTranslationService.translate("lbl_delete_4")}</li>
                        <li className='my-2 font-bold' >{tawreedTranslationService.translate("lbl_delete_5")}</li>
                        <li className='my-2 font-bold' >{tawreedTranslationService.translate("lbl_delete_6")}</li>
                    </ol>
                </li>
            </ul>
        </Dialog>
    )
}
