import classNames from 'classnames';
import React from 'react'
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { ObjectUtils } from '../../../../../helpers/object';
import { IFileService, TawreedFile } from '../../../../../modules/upload/domain';
import { TawreedFilesList, TawreedFilesListProps1 } from '../../../../../modules/upload/presentation';
import { TawreedFilesForm } from '../../../../../modules/upload/presentation/components/files-form';
import { TawreedAction, TawreedActionData } from '../../../../actions';
import { tawreedTranslationService } from '../../../../translations';
import { TawreedFormFieldProps } from '../../containers';
import { CONTROL_DEFAULT_CLASSNAME, TawreedControlProps } from '../control';

import './index.scss';

export type TawreedInputUploadProps = TawreedControlProps & TawreedFormFieldProps & TawreedFilesListProps1 & { children?: React.ReactElement; service: IFileService, onUpload?: TawreedActionData<any>, onDownload?: TawreedActionData<any>, actions?: Array<TawreedAction<TawreedFile>> };

export const TawreedInputUpload: React.FC<TawreedInputUploadProps> = (props) => {

    const form = useFormContext();
    const [file, setFile] = React.useState<TawreedFile>();

    const inputField = {
        get: (field?: ControllerRenderProps) => {
            if (props.render === 'standalone') {
                return props.value;
            } else if (field) {
                let value = field.value;
                if (props.render === 'form-nested') {
                    value = ObjectUtils.getNested(field.value, props.nested);
                }
                value = value || [];
                return value;
            } else {
                return undefined;
            }
        },
        set: (file: TawreedFile) => {

            const changeIndex = (value: any,) => {
                const ii = Array.from<TawreedFile>(value);
                const i = ii.findIndex(e => e.fileName === file.fileName);
                if (i >= 0 || i < ii.length) {
                    ii[i] = file;
                }
                return ii;
            }

            if (props.render === 'standalone') {
                if (props.value) {
                    props.value = changeIndex(props.value);
                }
            } else if (props.name) {
                const name = props.name;
                const value = form.getValues()[name];

                if (value) {
                    if (props.render === 'form-nested') {
                        const nested = ObjectUtils.getNested(value, props.nested);
                        if (nested) {
                            form.setValue(name, { ...value, [props.nested]: changeIndex(nested) });
                        }

                    } else if (props.render === 'form') {
                        form.setValue(name, changeIndex(value));
                    }
                }
            }
        }
    }

    const actions = React.useCallback(
        (sender: TawreedFile) => {

            const aa: TawreedAction<TawreedFile>[] = [];

            // upload action
            if (props.onUpload) {
                const onUpload = (e?: TawreedFile) => {
                    return new Promise<void>((resolve, reject) => {
                        if (e) {
                            setFile(e);
                            return resolve();
                        }
                        return reject();
                    });
                }
                aa.push(new TawreedAction(props.onUpload.name, props.onUpload.type, props.onUpload.title, props.onUpload.icon, onUpload, props.onUpload.className ?? ''));
            }

            // download action
            if (sender && typeof sender.value === 'string' && props.onDownload) {
                const onDownload = (e?: TawreedFile) => {
                    return new Promise<void>((resolve, reject) => {
                        if (e && e.value && typeof e.value === 'string') {

                            return props.service
                                .download({ ...e, fileName: e.fileName.concat("." + e.value.split(".")[1] ?? "pdf") })
                                .then(() => resolve())
                                .catch(() => reject());
                        }
                        return reject();
                    });
                }
                aa.push(new TawreedAction(props.onDownload.name, props.onDownload.type, props.onDownload.title, props.onDownload.icon, onDownload, props.onDownload.className ?? ''));
            }

            // actions
            if (props.actions && props.actions.length) {
                aa.push(...props.actions);
            }
            return aa;
        },
        [props.service, props.actions, props.onUpload, props.onDownload]);

    //
    const fileUploadForm = {
        onUpload: async (e: File) => {
            const ee = { ...file!, value: e };
            inputField.set(ee);
            //
            fileUploadForm.onCancel();
            // const res = await props.service.upload({ ...file!, value: e });
            // if (res) {
            //     //
            // }
        },
        onCancel: () => setFile(undefined),
    }

    //
    return (
        <React.Fragment>
            {
                file &&
                <TawreedFilesForm visible title='lbl_upload' onUpload={fileUploadForm.onUpload} onCancel={fileUploadForm.onCancel} />
            }
            {
                (!form || !form.control || !props.name || props.render === 'standalone')
                    ?
                    <TawreedFilesList {...props} id={props.name} className={classNames(CONTROL_DEFAULT_CLASSNAME, 'inputupload', props.className)} files={inputField.get(undefined)} actions={actions} />
                    :
                    <Controller name={props.name} rules={props.rules} render={({ field, fieldState }) => {
                        let id = field.name;
                        if (props.render === 'form-nested') {
                            id = `${props.name}.${props.nested}`;
                        }

                        return (
                            <React.Fragment>
                                <TawreedFilesList {...props} id={id} className={classNames(CONTROL_DEFAULT_CLASSNAME, 'inputupload', props.className, { 'p-invalid': fieldState.invalid })} files={inputField.get(field)} actions={actions} />
                                {
                                    props.name &&
                                    form.formState &&
                                    form.formState.errors &&
                                    form.formState.errors[props.name] &&
                                    <small className="p-error ml-2">{tawreedTranslationService.translate(form.formState.errors[props.name]?.message as any)}</small>
                                }
                            </React.Fragment>
                        );
                    }} />
            }
        </React.Fragment>
    )
}
