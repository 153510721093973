import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect } from 'react'
import { tawreedTranslationService } from '../../../../common/translations';
import { TawreedDropdown, TawreedFormField, TawreedInputNumber, TawreedMenuDropdown } from '../../../../common';
import { DiscountTypesBasicsDropDown } from '../../../catalog/store-products/presentaion/components/discount-types-basics';
import { CommissionDto } from '../../domain';
import { GlobalContext } from '../../../../context';


export interface CommissionDialogProps {
    visible: boolean;
    partnerId: number;

    hide: () => void;

    accept: (data: { type: string, value: number, paymentModeId: number, commissionId?: number }) => void;

}

export const CommissionDialog: FC<CommissionDialogProps> = ({ visible, hide,accept, partnerId }) => {


    const { constants: { constants } } = React.useContext(GlobalContext);

    const [data, setData] = useState<CommissionDto>({
        priceListId: undefined,
        partnerId: partnerId,
        paymentModeId: undefined
    });
    useEffect(() => {

        setData({
            priceListId: undefined,
            partnerId: partnerId,
            paymentModeId: undefined,
            value: undefined,
            type: undefined
        })
    }, [visible])

    const CommissionDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" />
            <Button onClick={() =>
                accept({ type: data.type!, value: data.value!, paymentModeId: data.paymentModeId!, commissionId: data.commissionId})} label={tawreedTranslationService.translate('lbl_accept')
            } icon="pi pi-check" disabled={!data || !data.type || !data.paymentModeId || !data.value}
                 />
        </div>);
    }

    return (
        <Dialog style={{}} header={''} visible={visible} footer={<CommissionDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <form className="form grid" >

                    <TawreedFormField className="field col-6 md:col-6" name="discountType" title="lbl_commission_type">
                        <DiscountTypesBasicsDropDown value={data?.type} name="discountType" render="standalone"
                         onChange={(e) => {
                            setData({ ...data, type: e.value })
                            // _props.onChange({ ...data, type: e.value }, false)
                        }} />
                    </TawreedFormField>
                    <TawreedFormField name="discountValue" title="lbl_commission_value" className="field col-6 md:col-6">
                        <TawreedInputNumber mode="decimal" value={data?.value} name="discountValue"
                         render="standalone" onChange={(e) => {
                            setData({ ...data, value: e.value ? e.value : 0 })
                            // _props.onChange({ ...data, value: e.value ? e.value : 0 }, false)
                        }} />
                    </TawreedFormField>
                    
                    <TawreedFormField name="discountValue" title="lbl_payment_mode" className="field col-6 md:col-6">

                    <TawreedMenuDropdown className="field col-12 md:col-12"
                    menu={"paymentModes"} render="standalone" value={data?.paymentModeId} onChange={(e) => {
                        setData({ ...data, paymentModeId: e.value ? e.value : 0 })
                        // _props.onChange({ ...data, value: e.value ? e.value : 0 }, false)
                    }}></TawreedMenuDropdown>
                    </TawreedFormField>
            </form>
        </Dialog>
    )
}
