import { OrderRepository } from ".";
import { JObject } from "../../../../../common/data/models";
import { buildPaginatedRequest, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../../common/pagination";
import { endpoints } from "../../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../../http";
import { convertPartnerTypeToString, PartnerType } from "../../../../partners/data";
import { OrderDto, PartnerOrderDto } from "../../domain";
import { PartnerOrderMapper } from "../mappers";
import { OrderStatus } from "../models";
import { TawreedDataTableFilterMeta } from "../../../../../common/components/table";
import { ObjectUtils } from "../../../../../helpers/object";

export class OrderStoreRepository {

    private orders: OrderRepository;

    /**
     *
     */
    constructor() {
        this.orders = new OrderRepository();
    }

    /**
     * Search
     * @param statusId
     * @param partnerType
     * @param params
     * @param returned
     * @returns
     */
    public search(statusId: OrderStatus, partnerType: PartnerType, params: PaginatedRequestParams, returned?: boolean): Promise<PaginatedResponse<PartnerOrderDto>> {
        let endpoint: string = '';
        if (returned) {
            switch (statusId) {
                case OrderStatus.Shipped:
                    endpoint = endpoints.RETURNED_SHIPPED_ORDERS_SEARCH;
                    break;
                default:
                    throw new Error('Unsupported status id');
            }
        } else {
            switch (statusId) {
                case OrderStatus.Placed:
                case OrderStatus.Processing:
                case OrderStatus.READY_FOR_DELIVERY:
                    endpoint = endpoints.STORES_ORDERS_SEARCH;
                    break;
                case OrderStatus.Shipped:
                    endpoint = endpoints.CUSTOMERS_ORDERS_SEARCH;
                    break;
                default:
                    throw new Error('Unsupported status id');
            }
        }
        const query = {
            sort: params.sort ? buildSortParamsToString(params.sort) : undefined,
            page: params.page,
            size: params.size
        };
        const data = {
            statusId: statusId,
            [convertPartnerTypeToString(partnerType)]: params.keyword ?? '',
        };

        return tawreedHttpService
            .post<JObject, Result<JObject>>(endpoint, data, query, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: {
                        ...res!.data!, content: res!.data!.content.map(e => {
                            return PartnerOrderMapper.toDto(PartnerOrderMapper.fromJson(e))
                        }) ?? [],
                    }
                }
            });
    }


    /**
     * Details
     * @returns
     * @param page
     * @param statusId
     * @param storeId
     * @param customerId
     * @param carrierId
     * @param keyword
     * @param returned
     */
    public getDetails(page: number, statusId: OrderStatus, storeId?: number, customerId?: number, carrierId?: number, keyword?: any, returned?: boolean): Promise<PaginatedResponse<OrderDto>> {
        const filters: TawreedDataTableFilterMeta = {
            orderId: {
                operator: 'AND',
                constraints: [
                    {
                        value: keyword,
                        matchMode: 'contains',
                    },
                ],
            },
            statusId: {
                operator: 'AND',
                constraints: [
                    {
                        value: statusId,
                        matchMode: 'equals',
                    },
                ],
            },
            storeId: {
                operator: 'AND',
                constraints: [
                    {
                        value: storeId ?? undefined,
                        matchMode: 'equals',
                    },
                ],
            },
            customerId: {
                operator: 'AND',
                constraints: [
                    {
                        value: customerId ?? undefined,
                        matchMode: 'equals',
                    },
                ],
            },
            carrierId: {
                operator: 'AND',
                constraints: [
                    {
                        value: carrierId ?? undefined,
                        matchMode: 'equals',
                    },
                ],
            },
        };
        return this.orders.search(buildPaginatedRequest({ page: page, size: ObjectUtils.MAX_INT, sort: { sortField: 'orderId', sortOrder: -1, multiSortMeta: undefined }, filters: filters }), returned);
    }

    /**
     * Details
     * @returns
     * @param statusId
     * @param partnerType
     * @param partnerId
     * @param carrierId
     * @param returned
     */
    public getSummary(statusId: OrderStatus, partnerType: PartnerType, partnerId: number, carrierId: number, returned: boolean | undefined): Promise<PartnerOrderDto> {
        let endpoint: string = '';
        if (returned) {
            switch (statusId) {
                case OrderStatus.Shipped:
                    endpoint = endpoints.RETURNED_SHIPPED_ORDERS_GET;
                    break;
                default:
                    throw new Error('Unsupported status id');
            }
        } else {
            switch (statusId) {
                case OrderStatus.Placed:
                case OrderStatus.Processing:
                case OrderStatus.READY_FOR_DELIVERY:
                    endpoint = endpoints.STORES_ORDERS_GET;
                    break;
                case OrderStatus.Shipped:
                    endpoint = endpoints.CUSTOMERS_ORDERS_GET;
                    break;
                default:
                    throw new Error('Unsupported status id');
            }
        }
        const data = {
            statusId: statusId,
            [convertPartnerTypeToString(partnerType) + 'Id']: partnerId,
            carrierId: carrierId,
        };
        return tawreedHttpService
            .post<JObject, JObject>(endpoint, data, undefined, undefined, 'error')
            .then(res => PartnerOrderMapper.toDto(PartnerOrderMapper.fromJson(res.data)));
    }


    /**
     *
     * @param ids
     */
    public shipAll(ids: number[]): Promise<boolean> {
        const data = ids;
        return tawreedHttpService.post<JObject, JObject>(endpoints.ORDER_SHIP_ALL, data).then(res => res && res.status === 200);
    }

    /**
     *
     * @param ids
     */
    public returnAll(ids: number[]): Promise<boolean> {
        const data = ids;
        return tawreedHttpService.post<JObject, JObject>(endpoints.ORDER_RETURN_ALL, data).then(res => res && res.status === 200);
    }

    /***
     *
     * @param ids
     */
    public deliverAll(ids: number[]): Promise<boolean> {
        const data = ids;
        return tawreedHttpService.post<JObject, JObject>(endpoints.ORDER_DELIVER_ALL, data).then(res => res && res.status === 200);
    }
}


