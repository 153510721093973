import React, { FC, useEffect, useMemo, useState } from "react";
import { Panel } from "primereact/panel";
import { useHistory, useParams } from "react-router-dom";

import { TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitProps, TawreedInputPlain, TawreedMenuDropdown, TawreedNavLink } from "../../../../common/components/form";
import { tawreedTranslationService } from "../../../../common/translations";
import { CommissionDto, CommissionService, ConnectionDto, MappingDto, PartnerDto, PartnersService, StoreProductCategoryDto, StoreZoneDto } from "../../domain";


import './image-card.scss';
import { GlobalContext } from "../../../../context";
import { AddressService } from "../../../states/domain/services";
import { State } from "../../../states/domain/dtos";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { PrimeIcons } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { PartnerType } from "../../data";
import { Routes } from "../../../../router";

export const ConnectionView: FC = () => {
    // di
    const { id } = useParams<{ id: string | undefined }>();
    const service: PartnersService = useMemo(() => new PartnersService(), []);
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = useState<ConnectionDto>({
        partnerConnectionId: 0,
        fromPartner: {partnerId: -1},
        toPartner:{partnerId:-1}
    });

    const [visible, setVisible] = React.useState(false);
    const [actions, setActions] = useState<TawreedAction[]>([]);
    let disabeld = true;
    
    const [state, setState] = React.useState<number>();
    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            service.getConnection(Number.parseInt(id))
                .then(res => {
                    console.log(res);
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                   
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })

        }
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line
    }, [id, service]);

    useEffect(()=>{
        const next = [
            new TawreedAction(TawreedActionName.StoreProductsCreate, 'statefull', (e) => tawreedTranslationService.translate('lbl_setItem'), PrimeIcons.PLUS, onUpdate),
            new TawreedAction(TawreedActionName.StoreProductsCreate, 'statefull',  (e) => tawreedTranslationService.translate('lbl_removeItem'), PrimeIcons.TRASH, onRemove,'p-button-danger ml-1', 'confirm'),
        ];
        setActions(next);
    },[data])
    const onUpdate = (): Promise<void> => {
        return new Promise((_resolve, reject) => {
            setVisible(true);
        })
    }
    const update = (): Promise<void> =>{
        return new Promise((resolve)=>{
            setLoading(true);
            setVisible(false);
            service.setClassification([Number.parseInt(id!)],state).then((res)=>{
                service.getConnection(Number.parseInt(id!))
                .then(res => {
                    console.log(res);
                    setLoading(false);
                    setData(res);
                })
                .catch(() => {
                    setLoading(false);
                })
            });
        });
    }
    const onRemove = (): Promise<void> => {
        return new Promise((_resolve, reject) => {
            setLoading(false);
            return service.setClassification([Number.parseInt(id!)],undefined)
            .then(() => service.getConnection(Number.parseInt(id!))
            .then(res => {
                console.log(res);
                setLoading(false);
                setData(res);
            })
            .catch(() => {
                setLoading(false);
            })).catch(err => console.error(err));
        })
    }

    const onSubmit: TawreedFormSubmitProps<PartnerDto> = {
        showButton: false,
        resetOnSubmit: false,
        onSubmit: undefined
    };
    const DialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={()=>setVisible(false)} className="p-button-text" disabled={false} />

            <Button label={tawreedTranslationService.translate('lbl_save')} icon="pi pi-check" onClick={() => update()} 
                disabled={state === 0}
             />
        </div>);
    }

    return (
        <React.Fragment>
            <TawreedForm title={'lbl_connections'}
                dataKey="partnerConnectionId" data={data}
                submit={onSubmit}
                loading={loading} mode={mode}
                actions={actions}
                 className="grid form-grid">
           <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                    <div className="grid">

                      
                        <TawreedFormField name="store" className="field col-12 md:col-6" title="lbl_partners_from_partner_name">
                            <TawreedInputPlain  name="fromPartner" nested="partnerName" render="form-nested" />
                        </TawreedFormField>
                        <TawreedFormField name="customer" className="field col-12 md:col-6" title="lbl_partners_to_partner_name">

                            <TawreedInputPlain  name="toPartner" nested="partnerName" render="form-nested" />
                        </TawreedFormField>
                        <div className="field-tawreed field col-12 md:col-6">
                                <label>{tawreedTranslationService.translate("lbl_partners_Classification")}</label>
                                <br/>
                                <span>{data?.classificationLabel}</span>
                            </div>
                    </div>
                </Panel>

                </TawreedForm>
                {visible && <Dialog style={{ minWidth: '60%' }} header={tawreedTranslationService.translate("lbl_setItem")} visible={visible} footer={<DialogFooter />} onHide={()=>setVisible(false)} draggable={false} position="top" closable={true} closeOnEscape={true} maximizable>
                
            <TawreedFormField name="amount" title={"lbl_partners_Classification"} className="field col-12 lg:col-12 md:col-12">
                        <TawreedMenuDropdown menu="classifications" render="standalone"
                         value={state} onChange={(e)=>setState(e.value)}/>
                    </TawreedFormField>
            </Dialog>
}
            
        </React.Fragment>

    );
}
