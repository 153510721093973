import React from "react";
import {TawreedFormField, TawreedInputText, TawreedMenuDropdown} from "../../../../../../../../common/components/form";
import {tawreedTranslationService} from "../../../../../../../../common/translations";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {PrimeIcons} from "primereact/api";
import {TawreedActionDialog} from "../../../../../../../../common/actions";
import {GlobalContext} from "../../../../../../../../context";

export type OrderReturnDialogProps = {};

export type OrderReturnDialogState = {

    reason: number | undefined;
    reasonAr: string | undefined;
    reasonEn: string | undefined;
    visible: boolean;
    otherReason: boolean;
}

export class OrderReturnDialog extends React.Component<OrderReturnDialogProps, OrderReturnDialogState> implements TawreedActionDialog<any, any, number> {

    static contextType = GlobalContext;

    constructor(props: OrderReturnDialogProps) {
        super(props);

        this.state = {
            visible: false,
            reason: undefined,
            reasonAr: undefined,
            reasonEn: undefined,
            otherReason: false,
        };
    }

    //private resolve: ((reason: number, reasonAr: string | undefined, reasonEn: string | undefined) => void) | undefined;
    private resolve: ((reason: any) => void) | undefined;
    private reject: (() => void) | undefined;

    private OrderCancelDialogFooter = () => {
        return (
            <div>
                <Button label={tawreedTranslationService.translate('lbl_cancel')} icon={PrimeIcons.TIMES} onClick={() => this.onHide()} className="p-button-text"/>
                <Button label={tawreedTranslationService.translate('lbl_accept')}
                        icon={PrimeIcons.CHECK}
                        onClick={() => this.onAccept()}
                        disabled={!this.state || !this.state.reason || 
                            (this.state.reason == -1 && (!this.state.reasonAr || !this.state.reasonEn))}/>
            </div>
        );
    }

    private onHide(): void {
        if (this.reject) {
            this.setState({...this.state, visible: false});
            this.reject();
        }
    }

    private onAccept(): void {
        if (this.resolve) {
            if (this.state.reason) {
                this.setState({...this.state, visible: false});
                this.resolve({reason: this.state.reason, 
                    reasonAr: this.state.reasonAr, reasonEn: this.state.reasonEn});
            }
        }
    }

    private onReasonSelect(value: number): void {
        let other = false;
        if(value == -1){
            other = true;
        }
        this.setState({...this.state, reason: value, otherReason: other})
    }

    public show(): Promise<number> {
        this.setState({...this.state, visible: true});
        return new Promise<number>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    public render() {
        return (
            <Dialog header={tawreedTranslationService.translate('lbl_sales_orders_cancel')}
                    footer={this.OrderCancelDialogFooter()}
                    onHide={() => this.onHide()}
                    visible={this.state.visible}
                    position="top"
                    breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '33vw'}}
                    draggable={false}
                    closable={false}
                    closeOnEscape={false}>
                <div className="grid formgrid">
                    <TawreedFormField name="carrier" title="lbl_sales_orders_return_reason" className="field col-12">
                        <GlobalContext.Consumer>
                            {
                                // (context) => <TawreedMenuDropdown render="standalone" menu={context.auth.user?.roleCode === "CUSTOMER_ADMIN" ? "cancelReasonsCustomer" : "cancelReasons"} value={this.state.reason} onChange={e => this.onReasonSelect(e.value)}/>
                                (context) => <TawreedMenuDropdown render="standalone" 
                                    menu={"returnReasons"} value={this.state.reason} 
                                    onChange={e => this.onReasonSelect(e.value)}/>
                            }
                        </GlobalContext.Consumer>
                    </TawreedFormField>
                    {this.state.otherReason &&
                        <React.Fragment>
                    <TawreedFormField name="carrier" title="lbl_sales_orders_return_reason_ar" className="field col-12">
                            <TawreedInputText name="reasonAr" render="standalone" value={this.state.reasonAr}
                            onChange={e => this.setState({...this.state, reasonAr: e.target.value})}/>
                    </TawreedFormField> 
                    <TawreedFormField name="carrier" title="lbl_sales_orders_return_reason_en" className="field col-12">
                            <TawreedInputText name="reasonEn" render="standalone"  value={this.state.reasonEn} 
                            onChange={e => this.setState({...this.state, reasonEn: e.target.value})}/>
                    </TawreedFormField> 
                    </React.Fragment>
    }
                </div>
            </Dialog>
        );
    }
}
