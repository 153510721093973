
import React, { useState } from "react";
import { PrimeIcons } from "primereact/api";
import { ColumnBodyOptions } from "primereact/column";
import { TawreedTableEditorCallback } from "../../../../../common/components/table/editors";
import { Button } from "primereact/button";
import { TawreedTable, TawreedTableProps } from "../../../../../common";
import { CssUtils } from "../../../../../helpers/css";
import { classNames } from "primereact/utils";
import { tawreedTranslationService } from "../../../../../common/translations";
import { OrderDocumentUploadDialog } from "./order-upload-dialog";
import { FileService, TawreedFile } from "../../../../upload";
import { DateUtils } from "../../../../../helpers/date";
import { GlobalContext } from "../../../../../context";
import { OrderDownloadFilesDialog } from "./order-form/order-download-files";



export const OrderDocumentTable: React.FC<any> = (props) => {
    const [state, setState] = useState<boolean>(false);
    const { constants: { constants } } = React.useContext(GlobalContext);
    const fileService: FileService = React.useMemo(() => new FileService(), []);
    const [visible, setVisable] = useState<{ visable: boolean, data: Array<string>, title: string; }>({ visable: false, data: [], title: "" });


    const downloadButton = (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {


        return <Button
            style={{ width: "60%", height: "35px" }}
            icon={PrimeIcons.DOWNLOAD}
            className="p-button-praimary"
            loading={props.loading}
            onClick={(e) => {
                setVisable({ visable: true, data: rowData.files, title: rowData.title, });
                //  props.downloadDocumentFile(rowData.fileId, rowData.title + ("." + (rowData.fileId?.split(".")?.[1] ?? ".pdf")));
                e.preventDefault();
            }}
        >
        </Button>
    }
    const hide = () => {
        setState(false);
    }

    const accept = (data: { files: Array<File>, title: string, type: string }) => {
        // let file: TawreedFile = { fileName: data.file.name, value: data.file, type: data.type };
        const files: Array<TawreedFile> = data.files.map(el => {
            return {
                fileName: el.name,
                value: el,
                type: data.type,
            }
        })
        // service.createOrUpdateFile(f,title,502);
        props.addDocumentFile(files, data.title, data.type);

    }
    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'basic',
            data: props.data ? props.data : [],
        },
        name: 'lbl_document_files',
        outLoader: props.loading,
        dataKey: '',
        columns: [

            {
                field: "title",
                header: "lbl_document_files_title",
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return (
                        <p>{options.title}</p>
                    )
                },
            },
            {
                field: "userName",
                header: "lbl_user_name",
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return (
                        <p>{options.userName}</p>
                    )
                },
            },
            {
                field: "partnerName",
                header: "lbl_users_account_name",
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return (
                        <p>{options.partnerName ?? ""}</p>
                    )
                },
            },
            {
                field: "createdAt",
                header: "lbl_reports_statements_createdat",
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return (
                        <p>{DateUtils.format(options.createdAt, 'date')}</p>
                    )
                },
            },
            {
                dataType: 'numeric',
                field: "download",
                style: CssUtils.width('5%'),
                body: downloadButton
            },
        ],
        header: {
            left: {
                search: false,
                custom: (
                    <span>
                        {tawreedTranslationService.translate(props.title)}
                    </span>
                )
            },
            right: {
                clear: false,
                custom: (
                    <div className="formgroup-inline">
                        <i className={classNames(PrimeIcons.PLUS, 'bouns_table_plus')} onClick={() => setState(true)}></i>
                    </div>
                )
            }
        }
    };

    return (
        <React.Fragment>
            <div className="col-12 md:col-12"><TawreedTable {...tawreed} /></div>
            <OrderDocumentUploadDialog
                visible={state}
                hide={hide}
                accept={accept}
            ></OrderDocumentUploadDialog>
            <OrderDownloadFilesDialog
                visible={visible.visable}
                hide={() => setVisable({ visable: false, data: [], title: "" })}
                accept={() => setVisable({ visable: false, data: [], title: "" })}
                data={visible.data}
                title={visible.title ?? ""}
            ></OrderDownloadFilesDialog>
        </React.Fragment>
    );
}
