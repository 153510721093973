import React from "react";
import { PrimeIcons } from "primereact/api";
import { useHistory } from "react-router-dom";

import { TawreedAction, TawreedActionName } from "../../../../../common/actions";
import { TawreedTable, TawreedTableProps } from "../../../../../common/components/table";
import { CssUtils } from "../../../../../helpers/css";
import { Routes } from "../../../../../router";
import { TopUpTransaction, TopUpTransactionService } from "../../../domain";
import { TawreedTableFilters } from "../../../../../common/components/table/filters";
import { GlobalContext } from "../../../../../context";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";


export const TopUpTransactionTable: React.FC = () => {
    const history = useHistory();
    const service = React.useMemo(() => new TopUpTransactionService(), []);
    const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext);

    const onDetails = (sender?: TopUpTransaction): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sender && sender.txnTopUpId) {
                history.push(Routes.TopUpTransactionsDetails.replace(':id', sender.txnTopUpId.toString()));
                resolve();
            } else {
                reject();
            }
        });
    }

    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        dataKey: 'txnTopUpId',
        name: 'tbl.topup.transactions',
        title: 'lbl_topup_transactions',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            {
                field: "txnTopUpId",
                header: "lbl_topup_txn_txntopupid",
                sortable: true,
                filter: true,
                style: CssUtils.width('15%'),
            },
            {                
                field: "createdAt",
                header: "lbl_topup_txn_createdat",
                sortable: true,
                filter: true,
                style: CssUtils.width('15%'),
                ...TawreedTableFilters.date.range("createdAt", 'datetime'),
            },
            {
                field: "topUpPartner.partnerName",
                header: "lbl_topup_txn_topuppartner",
                sortable: true,
                sortField: 'topUpPartnerName',
                filter: true,
                filterField: 'topUpPartnerName',
                style: CssUtils.width('20%'),
            },
            {
                field: "partner.partnerName",
                header: "lbl_topup_txn_partner",
                sortable: true,
                sortField: 'partnerName',
                filter: true,
                filterField: 'partnerName',
                style: CssUtils.width('20%'),
            },
            {
                field: "transactionValue",
                header: "lbl_topup_txn_txnvalue",
                sortable: true,
                filter: true,
                ...TawreedTableFormatters.decimal('transactionValue', user?.scale, undefined, CssUtils.width('15%')),
            },
            {
                field: "transactionStatus",
                header: "lbl_topup_txn_txnstatus",
                sortable: true,
                filter: true,
                style: CssUtils.width('15%'),
                ...TawreedTableFilters.dropdown.dropdown("transactionStatus", constants && constants.transactionStatuses ? constants.transactionStatuses : [], 'value', 'label', 'in'),
            },
        ],
        row: {
            onClick: new TawreedAction(TawreedActionName.TopUpTransactionsDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onDetails, 'p-button-secondary', false),
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'txnTopUpId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                'createdAt': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'dateIs',
                        },
                    ]
                },
                'topUpPartnerName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'partnerName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'transactionValue': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                'transactionStatus': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },                
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'createdAt',
                sortOrder: -1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
        },
    }
    return (<TawreedTable  {...tawreed} />);
}