import React,{FC,useEffect,useState} from 'react';
import { TawreedTable, TawreedTableProps } from "../../../../common/components/table";
import { tawreedTranslationService } from "../../../../common/translations";
import { CssUtils } from "../../../../helpers/css";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { GlobalContext } from '../../../../context';
import { AuthUtils } from '../../../auth/domain';
import { TawreedTableFilters } from "../../../../common/components/table/filters";
import { TawreedTableFormatters } from "../../../../common/components/table/formatters";
import { OrderTrasactionDialog } from './order-transaction-dialog';
import { PaymentTransaction } from '../../domain';

export const OrderTransactionsTable: FC<any> = (props) =>{
    const [state, setState] = useState<boolean>(false);
    const [data, setData] = useState<PaymentTransaction>();
    const lang = AuthUtils.current().language;
    const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext);
    const hide = () => {
        setState(false);
    }
    
    const columns = [
        {
            field: "paymentTransactionId",
            header: "lbl_payment_txn_paymentTransactionId",
            style: CssUtils.width('3%'),
        },
        {                
            field: "createdAt",
            header: "lbl_payment_txn_createdat",
            style: CssUtils.width('8%'),
            ...TawreedTableFilters.date.range("createdAt", 'datetime'),
        },
        {
            field: "paymentPartner.partnerName",
            header: "lbl_payment_txn_payment_partner",
            style: CssUtils.width('15%'),
        },
        {
            field: "sellerPartner.partnerName",
            header: "lbl_payment_txn_seller_partner",
            style: CssUtils.width('15%'),
        },
        {
            field: "buyerPartner.partnerName",
            header: "lbl_payment_txn_buyer_partner",
            style: CssUtils.width('15%'),
        },
        {
            field: "amount",
            header: "lbl_payment_txn_amount",
            ...TawreedTableFormatters.decimal('amount', user?.scale, undefined, CssUtils.width('10%')),
        },
        {
            field: "status",
            header: "lbl_payment_txn_txnstatus",
            style: CssUtils.width('10%'),
            ...TawreedTableFilters.dropdown.dropdown("status", constants && constants.paymentransactionStatuses ? constants.paymentransactionStatuses : [], 'value', 'label', 'in'),
        },
        {
            field: "type",
            header: "lbl_payment_txn_type",
            style: CssUtils.width('15%'),
            ...TawreedTableFilters.dropdown.dropdown("type", constants && constants.paymentTransactionType ? constants.paymentTransactionType : [], 'value', 'label', 'in'),
        },
        {
            field: "state",
            header: "lbl_payment_txn_state",
            style: CssUtils.width('5%')
        },
          {
                field: "actions",
                body: (options: any) => {
                    return <Button
                        type="button"
                        style={{ width: "100%", height: "35px" }}
                        icon={PrimeIcons.EYE}
                        className="p-button-success"
                        tooltip={tawreedTranslationService.translate('lbl_view')}
                        onClick={() => {
                            setData(options);
                            setState(true);
                            }} />
                },
                style: CssUtils.width('12%'),
            }
        ];
        
    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'basic',
            data: props.data ? props.data : [],
        },
        name: 'lbl_payment_order_trasactions',
        dataKey: '',
        columns: columns,
    };
    return ( 
        <div className="col-12 md:col-12">
            <TawreedTable {...tawreed} />

            <OrderTrasactionDialog
                visible={state}
                hide={hide}
                data={data!}
            />
        </div>
    )
}