import { JObject } from "../../../../common/data/models";
import { PartnerMapper, PartnerType } from "../../../partners/data";
import { DeliveryCost, DeliveryPartner } from "../../domain";
import { DeliveryCostMapper } from "./delivery-cost";

export const DeliveryPartnerMapper = {

    fromJson(obj: JObject): DeliveryPartner {
        return {
            deliveryPartnerId: obj.deliveryPartnerId,
            titleAr: obj.titleAr,
            titleEn: obj.titleEn,
            codCost: obj.codCost,
            codSelling: obj.codSelling,
            requiredOtp:obj.requiredOtp,
            deliveryCosts: obj.deliveryCosts?.map((el:JObject)=> DeliveryCostMapper.fromJson(el)),
            mawridUniqueId: obj.mawridUniqueId,
            ...PartnerMapper.toDto(PartnerMapper.fromJson(obj)),
        };
    },

    toJson(obj: DeliveryPartner): JObject {
        return {
            deliveryPartnerId: obj.deliveryPartnerId,
            titleAr: obj.titleAr,
            titleEn: obj.titleEn,
            codCost: obj.codCost,
            codSelling: obj.codSelling,
            requiredOtp:obj.requiredOtp,
            mawridUniqueId: obj.mawridUniqueId,
            deliveryCosts: obj.deliveryCosts?.map((el:DeliveryCost)=> DeliveryCostMapper.toJson(el)),
            ...PartnerMapper.toJson(PartnerMapper.fromDto(obj)),
        };
    }
}
