import React, { FC, useMemo, useState } from 'react'
import { PrimeIcons } from 'primereact/api';
import { TawreedAction, TawreedActionName } from '../../../../../common/actions';
import { TawreedTable, TawreedTableProps } from '../../../../../common/components/table';
import { StoreProductsService, StoreProductsStageService } from '../../domain';
import { useHistory } from 'react-router-dom';
import { StoreProductStage } from '../../data';
import { Routes } from '../../../../../router';
import { CssUtils } from "../../../../../helpers/css";
import { AuthUtils } from '../../../../auth/domain';
import { GlobalContext } from '../../../../../context';
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedTableFilters } from '../../../../../common/components/table/filters';
import { Tag } from 'primereact/tag';
import { DownloadProductsDialog } from './downloadProductsDialog';
import { JObject } from '../../../../../common/data/models';

export const StoreProductsTable: FC = (_props) => {

    const history = useHistory();
    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);
    const service1: StoreProductsStageService = useMemo(() => new StoreProductsStageService(), []);
    const [reload, setReload] = useState(false);
    const [importDialogVisible, setImportDialogVisible] = useState(false);
    const [downloadDialogVisible, setDownloadDialogVisible] = useState(false);
    const [loading, setLoading] = useState(false);


    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);



    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.StoreProductsNew);
            resolve();
        })
    }
    const onForm = (sender?: StoreProductStage): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.StoreProductsDetails.replace(':id', sender!.storeProductId.toString()));
            resolve();
        })
    };

    const onDelete = (sender?: StoreProductStage | Array<StoreProductStage>): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!sender) {
                reject('No item selected');
            } else if (Array.isArray(sender)) {
                setReload(false);
                return service.deleteAll(sender.map(e => e.storeProductId)).then(() => setReload(true)).catch(err => console.error(err));
            } else {
                setReload(false);
                return service.delete(sender.storeProductId!).then(() => setReload(true)).catch(err => console.error(err));
            }
        })
    };
    const onImport = (): Promise<void> => {
        return new Promise((resolve) => {
            setImportDialogVisible(true);
            resolve();
        });
    }
    const onDownloadCatalog = (): Promise<void> => {
        return new Promise((resolve) => {
            setDownloadDialogVisible(true);
            resolve();
        });
    }
    const onDownloadTemplate = (): Promise<void> => {
        return service1.downloadTemplate().then();
    }
    const downloadCatalog = (data: JObject): Promise<void> => {
        setLoading(true)
        data.storeName = data.storeName ?? user?.partnerName;
        return service1.downloadCatalog(data).then(() => {
            setLoading(false);
            setDownloadDialogVisible(false);
        });
    }

    const onActivate = (sender?: StoreProductStage | Array<StoreProductStage>): Promise<void> => {
        return new Promise((resolve, reject) => {
            let products: StoreProductStage[] | undefined = undefined;
            if (Array.isArray(sender)) {
                products = sender;
            } else if (sender) {
                products = [sender];
            }
            if (!products || !products.length) {
                reject('No item selected');
            } else {
                setReload(false);
                setLoading(true)
                service.activateAll(products)
                    .then(() => {
                        setReload(true);
                        setLoading(false)
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                        setLoading(false)
                    });
            }
        });
    };

    const onDeactivate = (sender?: StoreProductStage | Array<StoreProductStage>): Promise<void> => {
        return new Promise((resolve, reject) => {
            let products: StoreProductStage[] | undefined = undefined;
            if (Array.isArray(sender)) {
                products = sender;
            } else if (sender) {
                products = [sender];
            }
            if (!products || !products.length) {
                reject('No item selected');
            } else {
                setReload(false);
                setLoading(true)
                service.deactivateAll(products)
                    .then(() => {
                        setReload(true);
                        setLoading(false)
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                        setLoading(false)
                    });
            }
        });
    };

    const getDependOnRole = () => {
        const arr = [];
        if (user?.roleCode !== 'STORE_ADMIN')
            arr.push({
                field: "store.partnerName",
                header: "lbl_store_products_store_name",
                sortable: true,
                sortField: 'storeName',
                filter: true,
                filterField: 'storeName',
                style: CssUtils.width('30%'),
            })
        return arr;
    }

    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        
        outLoader: loading,
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.search(q),
        },
        reload: reload,
        dataKey: 'storeProductId',
        name: 'tbl.store.products',
        title: 'lbl_store_products',
        columns: [
            {
                field: "storeProductId",
                header: "lbl_store_products_id",
                sortable: true,
                filter: true,
                style: CssUtils.width('5%'),
            },
            ...getDependOnRole(),
            {
                field: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                header: "lbl_store_products_product_id",
                sortable: true,
                sortField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                filter: true,
                filterField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                style: CssUtils.width(user?.roleCode !== 'STORE_ADMIN' ? '30%' : '40%', CssUtils.color('var(--danger-color)')),
                bodyStyle: { 'unicode-bidi': 'plaintext' }
            },

            {
                dataType: "numeric",
                field: "tax",
                header: "lbl_store_products_tax",
                sortable: true,
                filter: true,
                filterField: 'tax',
                style: CssUtils.width(user?.roleCode !== 'STORE_ADMIN' ? '10%' : '20%'),
                className: CssUtils.className.field.amount,
            },
            {
                field: "categoryId",
                header: "lbl_category_id",
                sortable: true,
                sortField: "categoryId",
                filter: true,
                filterField: 'categoryId',
                ...TawreedTableFilters.dropdown.dropdown('categoryId', constants?.productCategories ?? [], 'value', 'label', 'in', (label: string) => <Tag rounded severity={'success'} style={{ width: '6rem' }} value={label} />),
                style: CssUtils.width(user?.roleCode !== 'STORE_ADMIN' ? '18%' : '20%'),
            },
            {
                field: "active",
                header: "lbl_product_active",
                sortable: true,
                ...TawreedTableFilters.boolean("active"),
                style: CssUtils.width(user?.roleCode !== 'STORE_ADMIN' ? '15%' : '20%'),
            },
        ],
        toolbar: {
            actions: [

                new TawreedAction(TawreedActionName.StoreProductsCreate, 'stateless', 'lbl_store_product_new', PrimeIcons.PLUS, onNew),
                new TawreedAction(TawreedActionName.StoreProductsDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete'),
                new TawreedAction(TawreedActionName.StoreProductsActivate, 'statefull', (e) => tawreedTranslationService.translate('lbl_activate_all', e?.length ?? 0), PrimeIcons.CHECK, onActivate, 'p-button-secondary', 'confirm'),
                new TawreedAction(TawreedActionName.StoreProductsDeactivate, 'statefull', (e) => tawreedTranslationService.translate('lbl_deactivate_all', e?.length ?? 0), PrimeIcons.CHECK, onDeactivate, 'p-button-secondary', 'confirm'),
                // new TawreedAction(TawreedActionName.StoreProductsDownloadTemplate, 'stateless', 'lbl_store_products_download_template', PrimeIcons.DOWNLOAD, onDownloadTemplate),
                new TawreedAction(TawreedActionName.StoreProductsDownloadTemplate, 'stateless', "lbl_store_products_download", PrimeIcons.DOWNLOAD, onDownloadCatalog),
            ],
        },
        //canImport: new TawreedAction(TawreedActionName.StoreProductsStageImport, 'stateless', 'lbl_store_products_import', PrimeIcons.UPLOAD, onImport),
        row: {
            onClick: new TawreedAction(TawreedActionName.StoreProductsDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onForm, 'p-button-secondary', false),
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'storeProductId':{
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'storeName': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'contains' }],
                },
                'titleAr': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'contains' }],
                },
                'titleEn': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'contains' }],
                },
                'quantity': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'tax': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'categoryId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'in',
                        },
                    ]
                },
            },
        },
        sort: {
            sortMode: 'multiple',
            initial: {
                sortField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                sortOrder: 1,
                multiSortMeta: undefined,
            },

        },
        selection: {
            mode: 'checkbox',
        },
    }

    return (
        <React.Fragment>
            <TawreedTable  {...tawreed} />
            <DownloadProductsDialog
                visible={downloadDialogVisible}
                hide={() => {
                    setDownloadDialogVisible(false);
                    setReload(true);
                }}
                download={downloadCatalog}
                loading={loading}
                isStore={user?.roleCode === 'STORE_ADMIN' ? true : false}
                partnerId={user?.partnerId!}

            />
        </React.Fragment>
    );
}
