import { OrderDto, orderService } from "../../../../../domain";
import { OrderFormListener } from "../../order-form.props";

export const onDeliver = (data: any, listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
   
    return orderService
        .deliver(data)
        .then(res => {
            if (res && listener) {
                listener({ type: 'delivered', sender: res });
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}

export const onConfirmReadyShipAndDeliver = (data: any, listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
   
    return orderService
        .confirmReadyShipAndDeliver(data)
        .then(res => {
            if (res && listener) {
                listener({ type: 'delivered', sender: res });
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}
export const onReadyShipAndDeliver = (data: any, listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
   
    return orderService
        .readyShipAndDeliver(data)
        .then(res => {
            if (res && listener) {
                listener({ type: 'delivered', sender: res });
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}

export const onShipAndDeliver = (data: any, listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
   
    return orderService
        .shipAndDeliver(data)
        .then(res => {
            if (res && listener) {
                listener({ type: 'delivered', sender: res });
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}