import { PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { TopUpPartnerRepository } from "../../data";
import { TopUpPartner } from "../dtos";

export class TopUpPartnerService {

    private readonly repository;

    constructor() {
        this.repository = new TopUpPartnerRepository();
    }

    public search(req: PaginatedRequestParams): Promise<PaginatedResponse<TopUpPartner>> {
        return this.repository.search(req);
    }

    /**
     * Create or update partner instance
     * @param type
     * @param form
     */
    public createOrUpdate(form: TopUpPartner): Promise<TopUpPartner> {
        return this.repository.createOrUpdate(form);
    }

    /**
     * Delete partner by id
     * @param id
     */
    public delete(id: number): Promise<boolean> {
        return this.repository.delete(id);
    }

    /**
     * Delete partner by id
     * @param id
     */
    public cleanup(id: number): Promise<boolean> {
        return this.repository.cleanup(id);
    }


    /**
     * Delete all partners by ids
     * @param ids
     */
    public deleteAll(ids: number[]): Promise<boolean> {
        return this.repository.deleteAll(ids)
    }

    /**
     * Get partner details by id
     * @param id
     */
    public getDetails(id: number): Promise<TopUpPartner> {
        return this.repository.getDetails(id);
    }
}