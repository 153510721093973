import React from "react";
import {tawreedTranslationService} from "../../../../../common/translations";
import {Panel} from "primereact/panel";
import {CssUtils} from "../../../../../helpers/css";
import {ObjectUtils} from "../../../../../helpers/object";
import { GlobalContext } from "../../../../../context";

export type OrderFormSummaryProps = {
    // controls
    className?: string;
    // data
    returned?: boolean;
    subTotal?: number;
    shippingTotal?: number;
    discount?: number;
    total?: number;
    totalTax?:number;
    grossTotal?: number;

};

export const OrderFormSummary: React.FC<OrderFormSummaryProps> = (props) => {
    const { auth: { user } } = React.useContext(GlobalContext);
    return (
        <Panel header={tawreedTranslationService.translate('lbl_sales_orders_summary')} className={props.className}>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <div className="grid">
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_gross_total')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.grossTotal, user?.scale)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_total_discount')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.discount, user?.scale)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_sub_total')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.subTotal, user?.scale)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_total_tax')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.totalTax, user?.scale)}
                        </div>
                        {
                            !props.returned &&
                            <React.Fragment>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_sales_orders_shipping_total')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {ObjectUtils.toFixed(props.shippingTotal, user?.scale)}
                                </div>
                            </React.Fragment>
                        }

                        <hr style={{width: '100%'}}/>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_total')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.total, user?.scale)}
                        </div>
                    </div>
                </div>
            </div>
        </Panel>
    );
}
