import React from "react"
import { Panel } from "primereact/panel"
import { useParams } from "react-router-dom"

import { TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputText, TawreedInputTextarea, TawreedMenuDropdown } from "../../../../../common/components"
import { tawreedTranslationService } from "../../../../../common/translations"
import { WalletTransactionDto, WalletTransactionService } from "../../../domain"
import { DateUtils } from "../../../../../helpers/date"

export const WalletTransactionForm: React.FC = () => {

    const { id } = useParams<{ id: string | undefined }>();
    const service: WalletTransactionService = React.useMemo(() => new WalletTransactionService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<WalletTransactionDto>();

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {
            if (mounted) {
                setMode('Create');
                setData(undefined);
            }
        }
        return () => {
            mounted = false;
        };
    }, [id, service]);


    const onSubmit: TawreedFormSubmitProps<WalletTransactionDto> = { showButton: false, resetOnSubmit: false };

    return (
        <TawreedForm title="lbl_topup_transactions"
            dataKey="walletTransactionId" data={data}
            submit={onSubmit}
            loading={loading} mode={mode}
            useBack=".." className="grid form-grid">
            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">

                    <TawreedFormField name="walletTransactionId" className="field col-12 md:col-6" title="lbl_wallet_transaction_wallet_transaction_id">
                        <TawreedInputText name="walletTransactionId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                    <TawreedFormField name="status" className="field col-12 md:col-6" title="lbl_wallet_transaction_status">
                        <TawreedMenuDropdown name="status" menu="transactionStatuses" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="walletTransactionReference" className="field col-12 md:col-6" title="lbl_wallet_transaction_wallet_transaction_reference">
                        <TawreedInputText name="walletTransactionReference" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="walletId" className="field col-12 md:col-6" title="lbl_wallet_transaction_wallet_id">
                        <TawreedInputText name="walletId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="amount" className="field col-12 md:col-6" title="lbl_wallet_transaction_amount">
                        <TawreedInputNumber mode="decimal" name="amount" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                    <TawreedFormField name="partnerTopup" className="field col-12 md:col-6" title="lbl_topup_txn_topuppartner">
                        <TawreedInputText name="partnerTopup" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="partnerName" className="field col-12 md:col-6" title="lbl_topup_txn_partner">
                        <TawreedInputText name="partnerName" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="transactionTime" className="field col-12 md:col-6" title="lbl_wallet_transaction_transaction_time">
                        <TawreedInputText value={data?.transactionTime ? DateUtils.format(data?.transactionTime, 'date') : ''} render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="channel" className="field col-12 md:col-6" title="lbl_wallet_transaction_channel">
                        <TawreedInputText name="channel" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="debitor" className="field col-12 md:col-6" title="lbl_wallet_transaction_debitor">
                        <TawreedInputText name="debitor" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="description" className="field col-12 md:col-6" title="lbl_wallet_transaction_description">
                        <TawreedInputTextarea name="description" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                    <TawreedFormField name="failedReason" className="field col-12 md:col-6" title="lbl_faield_reason">
                        <TawreedInputTextarea name="failedReason" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                </div>
            </Panel>
        </TawreedForm>
    )
}
