import React from "react";
import { useFormContext } from "react-hook-form";
import { TawreedFormField, TawreedInputText } from "../../../../../common/components/form";
import { CategoryField } from "../../domain/dtos/category-fields";

export type ProductDynamicFieldProps = {

    index: number;
    field: CategoryField;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ProductDynamicField: React.FC<ProductDynamicFieldProps> = ({ index, field, onChange }) => {

    const form = useFormContext();

    //
    const [state, setState] = React.useState<{ name: string; value: string }>();

    React.useEffect(
        () => {

            let mounted = true;
            if (mounted) {
                const nameField = `fields[${index}].fieldId`;
                const valueField = `fields[${index}].value`;

                setState({ name: nameField, value: valueField });
                form.setValue(nameField, field.fieldId);
            }
            return () => { mounted = false; }

        }, [index, field, form]);

    return (
        <React.Fragment>
            {
                state &&
                state.name &&
                <TawreedInputText onChange={(e) => { onChange(e) }} hidden aria-hidden name={state.name} render="form" rules={{ required: field.required ? 'msg_field_is_required' : false }} />
            }
            {
                state &&
                state.value &&
                <TawreedFormField className="field col-12 md:col-6" name={state.value} title={field.fieldName}>
                    <TawreedInputText name={state.value} render="form" rules={{ required: field.required ? 'msg_field_is_required' : false }} onChange={(e) => { onChange(e) }} />
                </TawreedFormField>
            }
        </React.Fragment>
    );
}
