import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect, useContext } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedFormField, TawreedInputNumber } from "../../../../../common/components/form";
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../../../context';
import { CategoriesDropdown } from './categories-dropdown';


export interface VerifyProductDialogProps {
    visible: boolean;

    hide: () => void;

    accept: (caategoryId: number) => void;

}

export const VerifyProductDialog: FC<VerifyProductDialogProps> = ({ visible, hide, accept }) => {


    const [dialogState, setDialogState] = useState<number | undefined>(0);





    const VerifyProductDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={false} />
            <Button label={tawreedTranslationService.translate('lbl_okay')} icon="pi pi-check" onClick={() => {
                accept(dialogState!)
                hide();
            }} className="p-button-success" disabled={!dialogState || dialogState === 0 || dialogState === 7} />

        </div>);
    }
    const history = useHistory();
    const { constants: { constants } } = useContext(GlobalContext);



    return (
        <Dialog style={{ minWidth: '60%' }} header={tawreedTranslationService.translate("lbl_select")} visible={visible} footer={<VerifyProductDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <div className="grid">
                <TawreedFormField className="field col-12 md:col-12" name="categoryId" title="lbl_product_category">
                    <CategoriesDropdown name="categoryId" render="standalone" rules={{ required: 'msg_field_is_required' }} value={dialogState} onChange={(e) => { setDialogState(e.value); }} />
                </TawreedFormField>
            </div>
        </Dialog>
    )
}
