import React, { useState } from "react";
import { PrimeIcons } from "primereact/api";
import { useHistory } from "react-router-dom";

import { TawreedAction, TawreedActionName } from "../../../../../common/actions";
import { TawreedTable, TawreedTableProps } from "../../../../../common/components/table";
import { CssUtils } from "../../../../../helpers/css";
import { Routes } from "../../../../../router";
import { BrokenOrderService } from "../../../domain";
import { TawreedTableFilters } from "../../../../../common/components/table/filters";
import { GlobalContext } from "../../../../../context";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { Button } from "primereact/button";


export const BrokenOrderTable: React.FC = () => {
    const history = useHistory();
    const service = React.useMemo(() => new BrokenOrderService(), []);
    const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext);    
    const [reload, setReload] = useState(false);

    const onResolve = (financialBrokenOrderId: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            setReload(true);
                service.resolve(financialBrokenOrderId)
                    .then((result:boolean) => {
                        if(result){
                            setReload(false);
                            resolve();
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
        });
    };
    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        dataKey: 'financialBrokenOrderId',
        name: 'tbl.payment.broken.order',
        title: 'lbl_broken_orders',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        reload: reload,
        columns: [
            {
                field: "financialBrokenOrderId",
                header: "lbl_broken_orders_financialBrokenOrderId",
                sortable: true,
                filter: true,
                style: CssUtils.width('15%'),
            },
            {                
                field: "createdAt",
                header: "lbl_broken_orders_createdat",
                sortable: true,
                filter: true,
                style: CssUtils.width('15%'),
                ...TawreedTableFilters.date.range("createdAt", 'datetime'),
            },
            {
                field: "sellerPartner.partnerName",
                header: "lbl_broken_orders_seller_partner",
                sortable: true,
                sortField: 'store',
                filter: true,
                filterField: 'store',
                style: CssUtils.width('20%'),
            },
            {
                field: "buyerPartner.partnerName",
                header: "lbl_broken_orders_buyer_partner",
                sortable: true,
                sortField: 'customer',
                filter: true,
                filterField: 'customer',
                style: CssUtils.width('20%'),
            },
            {
                field: "amount",
                header: "lbl_broken_orders_amount",
                sortable: true,
                filter: true,
                ...TawreedTableFormatters.decimal('amount', user?.scale! ,undefined, CssUtils.width('15%')),
            },{
                field: "reason",
                header: "lbl_broken_orders_reason",
                sortable: true,
                filter: true,
                ...TawreedTableFormatters.decimal('amount', user?.scale!, undefined, CssUtils.width('15%')),
            },
            {
                field: "actions",
                // header: "lbl_add_to_cart",
                body: (options: any) => {
                    return <Button
                        type="button"
                        style={{ width: "100%", height: "35px" }}
                        icon={PrimeIcons.UNDO}
                        className="p-button-success"
                        onClick={() => {
                            onResolve(options.financialBrokenOrderId);
                            }} />
                },
                style: CssUtils.width('12%'),
            }
        ],
        // row: {
        //     onClick: new TawreedAction(TawreedActionName.PaymentTransactionsDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onDetails, 'p-button-secondary', false),
        // },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'financialBrokenOrderId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                'createdAt': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'dateIs',
                        },
                    ]
                },
                'store': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'customer': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'amount': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },   
                'financialBrokenStatus':{
                    operator: 'AND',
                    constraints: [
                        {
                            value: 0,
                            matchMode: 'equals',
                        },
                    ]
                }            
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'createdAt',
                sortOrder: -1,
                multiSortMeta: undefined,
            },
        },
    }
    return (<TawreedTable  {...tawreed} />);
}