import { Dialog } from "primereact/dialog"
import { tawreedTranslationService } from "../../../../../../common/translations";
import React, { FC, useState } from "react";
import { TawreedFormField, TawreedInputFile } from "../../../../../../common";
import { FileService, TawreedFile } from "../../../../../upload";
import { Button } from "primereact/button";
import OtpInput from "react-otp-input";
import { OrderList } from "primereact/orderlist";
import { PrimeIcons } from "primereact/api";

export interface OrderDownloadFilesDialogProps {
    visible: boolean;
    hide: () => void;
    accept: () => void;
    data: Array<string>
    title: string;
}

export const OrderDownloadFilesDialog: FC<OrderDownloadFilesDialogProps> = ({ visible, hide, accept, data, title }) => {



    const fileService: FileService = React.useMemo(() => new FileService(), []);
    const [loading, setLoading] = useState<boolean>(false)

    const OrderDocumentDownloadDialogFooter = () => {
        return (<div>
            <Button disabled={loading} label={tawreedTranslationService.translate('lbl_okay')} onClick={() => { hide() }}  /*disabled={dialogState.loading}*/ />
        </div>)
            ;
    }
    const downloadOrderFile = async (fileId: string, name?: string) => {
        try {
            setLoading(true);
            await fileService.downloadS3(fileId, name);

        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }
    const itemTemplate = (el: string) => {
        return (
            // <div onClick={() => { setSelectedItem(item) }} className={`flex flex-wrap p-2 align-items-center gap-3 ${selectedItem && selectedItem.storeProductRawMatchId === item.storeProductRawMatchId ? "selectedBonus" : ""}`}>
            <div className={`flex flex-wrap p-2 align-items-center gap-3`}>
                <div className="flex-1 flex flex-column gap-2 xl:mr-8">
                    <span className="font-bold">{title}-{el}</span>
                </div>
                <div>
                    <span className="font-bold text-900"> <Button disabled={loading} icon={PrimeIcons.DOWNLOAD} onClick={() => downloadOrderFile(el, el)} className='p-button-primary'></Button>
                    </span>
                </div>
            </div>
        );
    };
    return (
        <Dialog style={{ maxWidth: '512px', minWidth: '55%' }} header={tawreedTranslationService.translate("lbl_files")}
            visible={visible} footer={<OrderDocumentDownloadDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <form className="formgrid grid" >
                <React.Fragment>
                    <OrderList className='col-12' listStyle={{ minHeight: 'auto' }} dataKey="id" value={data ?? []} onChange={(e) => { }} itemTemplate={itemTemplate} header={tawreedTranslationService.translate("lbl_files")} ></OrderList>
                </React.Fragment>
            </form>
        </Dialog>
    )
}
