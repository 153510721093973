
import { JObject } from "../../../../common/data/models"
import { CategorySetting } from "../models";
import { SettingCategoryDto } from "../../domain";
import { TawreedFileMapper } from "../../../upload/data";


export const CategoryMappers = {
    /**
     * Create model from json-object
     * @param json
     * @returns {CategorySetting}
     */
    fromJson: (json?: JObject): CategorySetting => {
        return {
            categoryId: json?.categoryId,
            categoryAr: json?.categoryAr,
            categoryEn: json?.categoryEn,
            businessTypes: json?.businessTypes,
            image: json?.image
        }
    },

    /**
     *
     */
    toJson: (model: CategorySetting): JObject => {
        return { ...model };
    },



    /**
     *
     */
    toDto: (model: CategorySetting): SettingCategoryDto => {
        return {
            categoryId: model.categoryId!,
            categoryAr: model.categoryAr!,
            categoryEn: model.categoryEn!,
            image: TawreedFileMapper.fromJson({
                fileName: `CATEGORY_IMAGE_${model.categoryId}`,
                value: model.image,
            }),
            businessTypes: model.businessTypes!,
        };
    },
}

