import React from "react";
import { ColumnBodyOptions } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { AutoCompleteItemTemplateType } from "primereact/autocomplete";
import { TawreedAutoComplete } from "../../form";
import { InputNumber } from "primereact/inputnumber";
import { ObjectUtils } from "../../../../helpers/object";
import { tawreedTranslationService } from "../../../translations";
import { toastService } from "../../../../modules/notifications/domain";


export type TawreedTableEditorEventParams = { field: string, newRowData: any, newValue: any, completed: boolean, };
export type TawreedTableEditorCallback = (e: TawreedTableEditorEventParams) => void;

export type TawreedTableTextEditorType =
    ({ mode: 'autocomplete', initialSuggestions?: any[], onSearch: (q: string) => Promise<Array<any>>, initial: (rowData: any) => any, fieldName: string, onSelect: (newData: any, selected: any) => any, itemTemplate?: AutoCompleteItemTemplateType });


const TawreedTableEditorText = (disabled: boolean) => {
    return (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {
        if (disabled) {
            return rowData[options.field];
        }
        return <InputText value={rowData[options.field]}
            onKeyPress={e => {
                if (e.key === 'Enter') {
                    const nValue = (e.target as HTMLInputElement).value;
                    const nRowData = { ...rowData, [options.field]: nValue };

                    callback({ field: options.field, newValue: nValue, newRowData: nRowData, completed: true });
                }
            }}
            onChange={e => {
                const nValue = e.target.value;
                const nRowData = { ...rowData, [options.field]: nValue };
                callback({ field: options.field, newValue: nValue, newRowData: nRowData, completed: false });
            }} />;
    };
};

const TawreedTableEditorNumeric = (type: 'int' | 'decimal', max?: number | string, min?: number | string, disabled?: boolean, width?: string, scale?: number) => {
    return (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {
        if (disabled) {
            return ObjectUtils.toFixed(rowData[options.field],scale);
        }

        const maximum = max ? (typeof max === 'number' ? max : Number.parseInt(rowData[max])) : undefined;
        const minimum = min ? (typeof min === 'number' ? min : Number.parseInt(rowData[min])) : undefined;

        return <InputNumber
            value={rowData[options.field]}
            inputStyle={{ width: width ? width : '100px' }}
            maxFractionDigits={type === 'int' ? 0 : scale}
            minFractionDigits={type === 'int' ? 0 : scale}
            // max={maximum}
            // min={minimum}
            onValueChange={e => {
                const value = e.value;
                // required && !value
                if (value == null || value === undefined) {
                    toastService.show({ detail: tawreedTranslationService.translate('msg_field_is_required'), severity: 'error' });
                    return;
                } else if (maximum !== undefined && value > maximum) {
                    toastService.show({ detail: tawreedTranslationService.translate('msg_field_max_allowed_exceeded', maximum.toString()), severity: 'error' });
                    return;
                } else if (minimum !== undefined && value < minimum) {
                    toastService.show({ detail: tawreedTranslationService.translate('msg_field_min_allowed_exceeded', minimum.toString()), severity: 'error' });
                    return;
                } else {
                    const nRowData = { ...rowData, [options.field]: value };
                    callback({ field: options.field, newValue: value, newRowData: nRowData, completed: true });
                }
            }} />;
    };
};

const TawreedTableEditorDropdown = (initialSuggestions: any[], onSearch: (q: string) => Promise<Array<any>>, initial: (rowData: any) => any, fieldName: string, onSelect: (newData: any, selected: any) => any, itemTemplate?: AutoCompleteItemTemplateType, disabled?: boolean) => {
    return (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {
        if (disabled) {
            return rowData[options.field];
        }
        return <TawreedAutoComplete
            render="standalone"
            field={fieldName}
            itemTemplate={itemTemplate}
            value={initial(rowData)}
            onSearch={(e) => onSearch(e && e.length ? e : initial(rowData))}
            onSelect={async (e) => {
                const v = onSelect(rowData, e.value) || rowData;
                callback({ field: options.field, newValue: v, newRowData: { ...rowData, [options.field]: v }, completed: true });
            }} />
    }
};

export const TawreedTableEditor = {
    text: TawreedTableEditorText,
    numeric: TawreedTableEditorNumeric,
    dropdown: TawreedTableEditorDropdown,
}

