import { JObject } from "../../../../../common/data/models";
import { Bonus } from "../models";
import { BonusDto } from "../../domain";

export const BonusMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {Bonus}
     */
    fromJson: (json?: JObject): Bonus => {
        return {
            bonusId: json?.bonusId,
            priceSchemeId: json?.priceSchemeId,
            quantity: json?.quantity,
            bonus: json?.bonus,
            bonusType: json?.bonusType,
            discountType: json?.discountType,
            bonusOperation: json?.bonusOperation,
            classificationId: json?.classificationId,
            sellingPrice: json?.sellingPrice        }
    },

    /**
     *
     */
    toJson: (model: Bonus): JObject => {
        return { ...model };
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {Bonus}
     */
    fromDto: (dto: BonusDto): Bonus => {
        return {
            bonusId: dto.bonusId!,
            priceSchemeId: dto.priceSchemeId!,
            quantity: dto.quantity,
            bonus: dto.bonus,
            bonusType: dto.bonusType,
            discountType: dto.discountType,
            bonusOperation: dto.bonusOperation,
            classificationId: dto.classificationId
        }
    },

    /**
     *
     */
    toDto: (model: Bonus): BonusDto => {
        return {
            bonusId: model.bonusId,
            priceSchemeId: model.priceSchemeId,
            quantity: model.quantity,
            bonus: model.bonus,
            bonusType: model.bonusType,
            discountType: model.discountType,
            uniqueId: model.bonusId.toString(),
            bonusOperation: model.bonusOperation,
            classificationId: model.classificationId,
            sellingPrice: model.sellingPrice
        };
    },
}

