import React from 'react';
import { ObjectUtils } from '../../../../helpers/object';
import { GlobalContext } from '../../../../context';

import { TawreedMultiSelect, TawreedMultiSelectProps } from '../../../../common/components/form';
import { TawreedConstants, TawreedMenuItem } from '../../../../modules/constants';

export type TawreedMenuMultiSelectProps = TawreedMultiSelectProps & { menu: keyof TawreedConstants, filterMenu?: (e: TawreedMenuItem) => boolean };

export const BusniessTypeMultiSelect: React.FC<TawreedMenuMultiSelectProps> = (props) => {

    const { constants: { constants } } = React.useContext(GlobalContext);
    let items: any = [];
    if (props.menu && constants && constants[props.menu]) {
        items = ObjectUtils.asTawreedMenu(constants[props.menu]) ?? [];

        items = items.map((el: any) => {
            return {
                businessName: el.label,
                businessTypeId: el.id,
            }
        })
    }
    console.log(items);

    return (<TawreedMultiSelect {...props} options={items} optionLabel="businessName" />)
}

