import React from "react";
//import { PartnerType } from "../../../data";
//import { PartnerDto, PartnersService } from "../../../domain";
//import { GlobalContext } from "../../../../../context";
//import { PartnerAutocompleteItemTemplate } from "./partner-autocomplete.item-template";
import { DropdownChangeParams, DropdownFilterParams } from "primereact/dropdown";
import { orderService } from "../../../../../domain";
import { DeliveryPartnerDto } from "../../../../../domain/dtos/delivery-partner";
import { TawreedControlRenderModeWithoutNested, TawreedControlRules, TawreedDropdown } from "../../../../../../../../common";
import { AuthUtils } from "../../../../../../../auth/domain";

export type DeliveryAutoCompleteComponentProps = {
    //
    active?: boolean;
    salesOrderId: number;

    // dropdown
    dropdownName: string;
    dropdownValue?: any;
    dropdownRender: TawreedControlRenderModeWithoutNested;
    dropdownDisabled?: boolean;
    dropdownRules?: TawreedControlRules;
    dropdownOptionLabel?: string;
    dropdownOptionValue?: string;
    dropdownOnChange?(e: DropdownChangeParams): void;
    dropDownPreSelected(e: number): void;
};

export const DeliveryDropdownComponent: React.FC<DeliveryAutoCompleteComponentProps> = (props) => {

    // di
    //const context = React.useContext(GlobalContext);

    // state
    const [loading, setLoading] = React.useState<boolean>(false);
    const [result, setResult] = React.useState<DeliveryPartnerDto[]>();

    React.useEffect(
        () => {
            setLoading(true);
            orderService
                .getDeliveryPartners(props.salesOrderId)
                .then(res => {
                    if (res) {
                        setResult(res);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.dropdownValue]);

    // actions
    // const onSearch = (q: string | undefined): void => {
    //     if (!loading) {
    //         setLoading(true);
    //         orderService
    //             .getDeliveryPartners(props.partnerType, q ?? '', props.active)
    //             .then(res => {
    //                 if (res.data) {
    //                     const result = [];
    //                     if (props.preSuggestions) {
    //                         result.push(...props.preSuggestions);
    //                     }
    //                     if (res.data.content) {
    //                         result.push(...res.data.content);
    //                     }
    //                     if (result.length === 1)
    //                         props.dropDownPreSelected(result[0].partnerId!)
    //                     setResult({ ...res.data, content: result });
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     }
    // };

    // const onBlur = () => {
    //     if (!loading && (!result || !result.content)) {
    //         onSearch(undefined);
    //     }
    // };

    // const onFilter = (e: DropdownFilterParams) => onSearch(e.filter);
    const itemTemplate = (rowData: DeliveryPartnerDto) => {
        return (
            <div className="">
            <div className="flex">
                <span className="mr-auto">{AuthUtils.current().language ==="en" ? rowData.titleEn : rowData.titleAr}</span>
                
            </div>
        </div>
        );
    }

    return (
        <TawreedDropdown name={props.dropdownName}
            render={props.dropdownRender}
            disabled={props.dropdownDisabled}
            rules={props.dropdownRules}
            optionLabel={props.dropdownOptionLabel}
            optionValue={props.dropdownOptionValue}
            scrollHeight={'25em'}
            onChange={props.dropdownOnChange}
            value={props.dropdownValue}
            options={result ? result : []}
            itemTemplate={itemTemplate}
            //itemTemplate={e => <PartnerAutocompleteItemTemplate rowData={e} partnersMenu={context?.constants?.constants?.partnerTypes} />}
            //onBlur={onBlur}
            //onFilter={onFilter}
             />
    );
}
