import { JObject } from "../../../../../common/data/models"
import { DateUtils } from "../../../../../helpers/date";
import { OrderDto } from "../../domain";
import { Order, OrderNote } from "../models"
import { OrderItemMapper } from "./order-item";
import { PartnerMapper } from "../../../../partners/data";
import { CarrierMapper } from "./carrier";
import { OrderHistoryMapper } from "./order-history";
import { TawreedMenuItemMapper } from "../../../../constants";
import { PriceListItemMapper } from "./price-list";
import { PartnerDto } from "../../../../partners/domain";
import { OrderNoteMapper } from "./order-note-mapper";

export const OrderMapper = {
    /**
     * Create cart model from json object
     * @param json
     * @returns {Order}
     */
    fromJson: (json: JObject, customer: PartnerDto, store: PartnerDto): Order => {
        return {
            cancelReason: json && json.cancelReason ? TawreedMenuItemMapper.fromJson(json?.cancelReason) : undefined,
            cartId: json?.cartId,
            commentsList: json?.commentsList,
            commissionAmount: json?.commissionAmount,
            commissionPercent: json?.commissionPercent,
            commissionReportURI: json?.commissionReportURI,
            confirmMessage: json?.confirmMessage,
            customer: PartnerMapper.fromDto(customer),
            deliveryDate: json?.deliveryDate,
            editMessage: json?.editMessage,
            feedbackId: json?.feedbackId,
            invoiceDate: json?.invoiceDate,
            invoiceNo: json?.invoiceNo,
            items: Array.from<JObject>(json?.items ?? []).map(e => OrderItemMapper.fromJson(e)) ?? [],
            itemsCount: json?.itemsCount,
            itemsQuantity: json?.itemsQuantity,
            langCode: json?.langCode,
            orderDate: DateUtils.fromISO(json?.orderDate),
            orderId: json?.orderId,
            orderReportURI: json?.orderReportURI,
            shipmentOrderReportURI: json?.shipmentOrderReportURI,
            orderItemsReportURI: json?.orderItemsReportURI,
            orderTotal: json?.orderTotal,
            orderTotalStore: json?.orderTotalStore,
            paymentMethodId: json?.paymentMethodId,
            returnReasonId: json?.returnReasonId,
            shipmentDate: json?.shipmentDate,
            shipmentMethodId: json?.shipmentMethodId,
            shipmentNo: json?.shipmentNo,
            shippingTotal: json?.shippingTotal,
            showCancel: json?.showCancel,
            showCommissionPrint: json?.showCommissionPrint,
            showConfirm: json?.showConfirm,
            showDelete: json?.showDelete,
            showDeliver: json?.showDeliver,
            showEdit: json?.showEdit,
            showNotifyCustomer: json?.showNotifyCustomer,
            showPrint: json?.showPrint,
            showReturn: json?.showReturn,
            showShip: json?.showShip,
            showShipment: json?.showShipment,
            showReadyForDelivery: json?.showReadyForDelivery,
            editShipment: json?.editShipment,
            showVerify: json?.showVerify,
            siteId: json?.siteId,
            statusId: json?.statusId,
            store: PartnerMapper.fromDto(store),
            subTotal: json?.subTotal,
            taxPercent: json?.taxPercent,
            total: json?.total,
            totalDiscount: json?.totalDiscount,
            carrier: PartnerMapper.fromJson({
                partnerId: json?.carrierId,
                partnerName: json?.carrierName,
            }),
            updatedAt: DateUtils.fromISO(json?.updatedAt),
            salesOrderId: json?.salesOrderId,
            historyList: json?.historyList ? Array.from(json?.historyList).map((e: any) => OrderHistoryMapper.fromJson(e)) : [],
            grossTotal: json?.grossTotal,
            totalTax: json?.totalTax,
            paymentModeId: json?.paymentModeId,
            payThroughUs: json?.payThroughUs,
            priceListItem: PriceListItemMapper.fromJson({ id: json?.priceListId, name: json?.priceListName }),
            salesOfferId: json?.salesOfferId,
            salesOfferTitle: json?.salesOfferTitle,
            requiredOtp: json?.requiredOtp,
            fiels: json?.documents?.map((el: JObject) => ({
                id: el.documentId, title: el.title, fileId: el.fileId, files: el.files,
                createdAt: el.createdAt, type: el.type, partnerName: el.partnerName, userName: el.firstName + ' ' + el.lastName
            })) ?? [],
            notes: json?.notes?.map((el: JObject) => OrderNoteMapper.fromJson(el))
        }
    },
    fromJson2: (json: JObject, customer: PartnerDto, store: PartnerDto, carrier: PartnerDto): Order => {
        return {
            cancelReason: json && json.cancelReason ? TawreedMenuItemMapper.fromJson(json?.cancelReason) : undefined,
            cartId: json?.cartId,
            commentsList: json?.commentsList,
            commissionAmount: json?.commissionAmount,
            commissionPercent: json?.commissionPercent,
            commissionReportURI: json?.commissionReportURI,
            confirmMessage: json?.confirmMessage,
            customer: PartnerMapper.fromDto(customer),
            deliveryDate: json?.deliveryDate,
            editMessage: json?.editMessage,
            feedbackId: json?.feedbackId,
            invoiceDate: json?.invoiceDate,
            invoiceNo: json?.invoiceNo,
            items: Array.from<JObject>(json?.items ?? []).map(e => OrderItemMapper.fromJson(e)) ?? [],
            itemsCount: json?.itemsCount,
            itemsQuantity: json?.itemsQuantity,
            langCode: json?.langCode,
            orderDate: DateUtils.fromISO(json?.orderDate),
            orderId: json?.orderId,
            orderReportURI: json?.orderReportURI,
            shipmentOrderReportURI: json?.shipmentOrderReportURI,
            orderItemsReportURI: json?.orderItemsReportURI,
            orderTotal: json?.orderTotal,
            orderTotalStore: json?.orderTotalStore,
            paymentMethodId: json?.paymentMethodId,
            returnReasonId: json?.returnReasonId,
            shipmentDate: json?.shipmentDate,
            shipmentMethodId: json?.shipmentMethodId,
            shipmentNo: json?.shipmentNo,
            shippingTotal: json?.shippingTotal,
            showCancel: json?.showCancel,
            showCommissionPrint: json?.showCommissionPrint,
            showConfirm: json?.showConfirm,
            showDelete: json?.showDelete,
            showDeliver: json?.showDeliver,
            showEdit: json?.showEdit,
            showNotifyCustomer: json?.showNotifyCustomer,
            showPrint: json?.showPrint,
            showReturn: json?.showReturn,
            showShip: json?.showShip,
            showShipment: json?.showShipment,
            showReadyForDelivery: json?.showReadyForDelivery,
            editShipment: json?.editShipment,
            showVerify: json?.showVerify,
            siteId: json?.siteId,
            statusId: json?.statusId,
            store: PartnerMapper.fromDto(store),
            subTotal: json?.subTotal,
            taxPercent: json?.taxPercent,
            total: json?.total,
            totalDiscount: json?.totalDiscount,
            carrier: PartnerMapper.fromDto(carrier),
            updatedAt: DateUtils.fromISO(json?.updatedAt),
            salesOrderId: json?.salesOrderId,
            historyList: json?.historyList ? Array.from(json?.historyList).map((e: any) => OrderHistoryMapper.fromJson(e)) : [],
            grossTotal: json?.grossTotal,
            totalTax: json?.totalTax,
            paymentModeId: json?.paymentModeId,
            payThroughUs: json?.payThroughUs,
            priceListItem: PriceListItemMapper.fromJson({ id: json?.priceListId, name: json?.priceListName }),
            salesOfferId: json?.salesOfferId,
            salesOfferTitle: json?.salesOfferTitle,
            requiredOtp: json?.requiredOtp,
            fiels: json?.documents?.map((el: JObject) => ({
                id: el.documentId, title: el.title, fileId: el.fileId, files: el.files,
                createdAt: el.createdAt, type: el.type, partnerName: el.partnerName, userName: el.firstName + ' ' + el.lastName
            })) ?? [],
            notes: json?.notes?.map((el: JObject) => OrderNoteMapper.fromJson(el))
        }
    },

    fromPublicJson: (json: JObject): Order => {
        return {
            cancelReason: json && json.cancelReason ? TawreedMenuItemMapper.fromJson(json?.cancelReason) : undefined,
            cartId: json?.cartId,
            commentsList: json?.commentsList,
            commissionAmount: json?.commissionAmount,
            commissionPercent: json?.commissionPercent,
            commissionReportURI: json?.commissionReportURI,
            confirmMessage: json?.confirmMessage,
            customer: PartnerMapper.fromJson({
                partnerId: json?.customerId,
                partnerName: json?.customer,
            }),
            deliveryDate: json?.deliveryDate,
            editMessage: json?.editMessage,
            feedbackId: json?.feedbackId,
            invoiceDate: json?.invoiceDate,
            invoiceNo: json?.invoiceNo,
            items: Array.from<JObject>(json?.items ?? []).map(e => OrderItemMapper.fromJson(e)) ?? [],
            itemsCount: json?.itemsCount,
            itemsQuantity: json?.itemsQuantity,
            langCode: json?.langCode,
            orderDate: DateUtils.fromISO(json?.orderDate),
            orderId: json?.orderId,
            orderReportURI: json?.orderReportURI,
            shipmentOrderReportURI: json?.shipmentOrderReportURI,
            orderItemsReportURI: json?.orderItemsReportURI,
            orderTotal: json?.orderTotal,
            orderTotalStore: json?.orderTotalStore,
            paymentMethodId: json?.paymentMethodId,
            paymentModeName: json?.paymentModeName,
            returnReasonId: json?.returnReasonId,
            shipmentDate: json?.shipmentDate,
            shipmentMethodId: json?.shipmentMethodId,
            shipmentNo: json?.shipmentNo,
            shippingTotal: json?.shippingTotal,
            showCancel: json?.showCancel,
            showCommissionPrint: json?.showCommissionPrint,
            showConfirm: json?.showConfirm,
            showDelete: json?.showDelete,
            showDeliver: json?.showDeliver,
            showEdit: json?.showEdit,
            showNotifyCustomer: json?.showNotifyCustomer,
            showPrint: json?.showPrint,
            showReturn: json?.showReturn,
            showShip: json?.showShip,
            showShipment: json?.showShipment,
            showReadyForDelivery: json?.showReadyForDelivery,
            editShipment: json?.editShipment,
            showVerify: json?.showVerify,
            siteId: json?.siteId,
            statusId: json?.statusId,
            status: json?.status,
            store: PartnerMapper.fromJson({
                partnerId: json?.storeId,
                partnerName: json?.store,
            }),
            subTotal: json?.subTotal,
            taxPercent: json?.taxPercent,
            total: json?.total,
            totalDiscount: json?.totalDiscount,
            carrier: PartnerMapper.fromJson({
                partnerId: json?.carrierId,
                partnerName: json?.carrierName,
            }),
            updatedAt: DateUtils.fromISO(json?.updatedAt),
            salesOrderId: json?.salesOrderId,
            historyList: json?.historyList ? Array.from(json?.historyList).map((e: any) => OrderHistoryMapper.fromJson(e)) : [],
            grossTotal: json?.grossTotal,
            totalTax: json?.totalTax,
            paymentModeId: json?.paymentModeId,
            payThroughUs: json?.payThroughUs,
            priceListItem: PriceListItemMapper.fromJson({ id: json?.priceListId, name: json?.priceListName }),
            salesOfferId: json?.salesOfferId,
            salesOfferTitle: json?.salesOfferTitle,
            requiredOtp: json?.requiredOtp,
            fiels: json?.documents?.map((el: JObject) => ({
                id: el.documentId, title: el.title, fileId: el.fileId, files: el.files,
                createdAt: el.createdAt, type: el.type, partnerName: el.partnerName, userName: el.firstName + ' ' + el.lastName
            })) ?? [],
            notes: json?.notes?.map((el: JObject) => OrderNoteMapper.fromJson(el))
        }
    },

    /**
     *
     */
    toJson: (order: Order): JObject => {
        return {
            ...order,
            items: order.items.map(e => OrderItemMapper.toJson(e)),
            customer: PartnerMapper.toJson(order.customer),
            store: PartnerMapper.toJson(order.store),
            carrier: PartnerMapper.toJson(order.carrier),
            historyList: null,
            paymentModeId: order.paymentModeId,
            priceListItemId: order.priceListItem.id,
        };
    },

    /**
     * Create order model from json object
     * @param dto
     * @returns {Order}
     */
    fromDto: (dto: OrderDto): Partial<Order> => {
        return {
            cancelReason: dto.cancelReason,
            cartId: dto.cartId,
            commentsList: dto.commentsList,
            commissionAmount: dto.commissionAmount,
            commissionPercent: dto.commissionPercent,
            commissionReportURI: dto.commissionReportURI,
            confirmMessage: dto.confirmMessage,
            customer: dto.customer ? PartnerMapper.fromDto(dto.customer) : undefined,
            deliveryDate: dto.deliveryDate,
            editMessage: dto.editMessage,
            feedbackId: dto.feedbackId,
            invoiceDate: dto.invoiceDate,
            invoiceNo: dto.invoiceNo,
            items: Array.of(...dto.items?.map(e => OrderItemMapper.fromDto(e)) ?? [], ...dto.cancelledItems?.map(e => OrderItemMapper.fromDto(e)) ?? []),
            itemsCount: dto.itemsCount,
            itemsQuantity: dto.itemsQuantity,
            langCode: dto.langCode,
            orderDate: dto.orderDate,
            orderId: dto.orderId,
            orderReportURI: dto.orderReportURI,
            orderTotal: dto.orderTotal,
            orderTotalStore: dto.orderTotalStore,
            paymentMethodId: dto.paymentMethodId,
            returnReasonId: dto.returnReasonId,
            shipmentDate: dto.shipmentDate,
            shipmentMethodId: dto.shipmentMethodId,
            shipmentNo: dto.shipmentNo,
            shippingTotal: dto.shippingTotal,
            showCancel: dto.showCancel,
            showCommissionPrint: dto.showCommissionPrint,
            showConfirm: dto.showConfirm,
            showDelete: dto.showDelete,
            showDeliver: dto.showDeliver,
            showEdit: dto.showEdit,
            showReadyForDelivery: dto.showReadyForDelivery,
            showNotifyCustomer: dto.showNotifyCustomer,
            showPrint: dto.showPrint,
            showReturn: dto.showReturn,
            showShip: dto.showShip,
            showShipment: dto.showShipment,
            editShipment: dto.editShipment,
            showVerify: dto.showVerify,
            siteId: dto.siteId,
            statusId: dto.statusId,
            store: dto.store ? PartnerMapper.fromDto(dto.store) : undefined,
            subTotal: dto.subTotal,
            taxPercent: dto.taxPercent,
            total: dto.total,
            totalDiscount: dto.totalDiscount,
            carrier: dto.carrier ? PartnerMapper.fromDto(dto.carrier) : undefined,
            updatedAt: dto.updatedAt,
            salesOrderId: dto.salesOrderId,
            historyList: dto.historyList,
            grossTotal: dto.grossTotal,
            totalTax: dto.totalTax,
            priceListItem: dto.priceListItem,
            payThroughUs: dto.payThroughUs,
        }
    },

    /**
     *
     */
    toDto: (order?: Order): OrderDto => {
        return {
            cancelReason: order?.cancelReason,
            cartId: order?.cartId,
            commentsList: order?.commentsList,
            commissionAmount: order?.commissionAmount,
            commissionPercent: order?.commissionPercent,
            commissionReportURI: order?.commissionReportURI,
            confirmMessage: order?.confirmMessage,
            customer: order?.customer ? PartnerMapper.toDto(order?.customer) : undefined,
            deliveryDate: order?.deliveryDate,
            editMessage: order?.editMessage,
            feedbackId: order?.feedbackId,
            invoiceDate: order?.invoiceDate,
            invoiceNo: order?.invoiceNo,
            items: order?.items.map(e => OrderItemMapper.toDto(e)) ?? [],
            cancelledItems: order?.items.map(e => OrderItemMapper.toDto(e)) ?? [],
            itemsCount: order?.itemsCount,
            itemsQuantity: order?.itemsQuantity,
            langCode: order?.langCode,
            orderDate: order?.orderDate,
            orderId: order?.orderId,
            orderReportURI: order?.orderReportURI,
            shipmentOrderReportURI: order?.shipmentOrderReportURI,
            orderItemsReportURI: order?.orderItemsReportURI,
            orderTotal: order?.orderTotal,
            orderTotalStore: order?.orderTotalStore,
            paymentMethodId: order?.paymentMethodId,
            paymentModeName: order?.paymentModeName,
            returnReasonId: order?.returnReasonId,
            shipmentDate: order?.shipmentDate,
            shipmentMethodId: order?.shipmentMethodId,
            shipmentNo: order?.shipmentNo,
            shippingTotal: order?.shippingTotal,
            showCancel: order?.showCancel,
            showCommissionPrint: order?.showCommissionPrint,
            showConfirm: order?.showConfirm,
            showDelete: order?.showDelete,
            showDeliver: order?.showDeliver,
            showReadyForDelivery: order?.showReadyForDelivery,
            showEdit: order?.showEdit,
            showNotifyCustomer: order?.showNotifyCustomer,
            showPrint: order?.showPrint,
            showReturn: order?.showReturn,
            showShip: order?.showShip,
            showShipment: order?.showShipment,
            editShipment: order?.editShipment,
            showVerify: order?.showVerify,
            siteId: order?.siteId,
            statusId: order?.statusId,
            status: order?.status,
            store: order?.store ? PartnerMapper.toDto(order?.store) : undefined,
            subTotal: order?.subTotal,
            taxPercent: order?.taxPercent,
            total: order?.total,
            totalDiscount: order?.totalDiscount,
            carrier: order?.carrier ? PartnerMapper.toDto(order?.carrier) : undefined,
            updatedAt: order?.updatedAt,
            salesOrderId: order?.salesOrderId,
            historyList: order?.historyList ?? [],
            grossTotal: order?.grossTotal,
            totalTax: order?.totalTax,
            priceListItem: order?.priceListItem,
            paymentModeId: order?.paymentModeId,
            payThroughUs: order?.payThroughUs,
            salesOfferId: order?.salesOfferId,
            salesOfferTitle: order?.salesOfferTitle,
            requiredOtp: order?.requiredOtp,
            fiels: order?.fiels ?? [],
            notes: order?.notes?.map((el: OrderNote) => OrderNoteMapper.toDto(el))
        }
    },
}
