import React from "react";
import { TawreedTable, TawreedTableProps } from "../../../../../common/components/table";
import { OrderItemDto } from "../../domain";
import { CssUtils } from "../../../../../helpers/css";

export type PublicOrderFormItemsProps = {
    // controls
    className: string;
    disabled: boolean;
    lang: string;
    items?: Array<OrderItemDto>;
};

export const PublicOrderFormItems: React.FC<PublicOrderFormItemsProps> = (props) => {

    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'basic',
            data: props.items ?? [],
        },
        name: 'tbl.sales.orders.order.items',
        dataKey: 'orderItemId',
        columns:
            [
                {
                    dataType: 'text',
                    field: "productName",
                    header: "lbl_sales_orders_o_info_items_product_name",
                    style: CssUtils.width('25%'),
                },
                {
                    dataType: 'numeric',
                    field: "quantityOrdered",
                    header: "lbl_sales_orders_o_info_items_quantity_ordered",
                    style: CssUtils.width('8%'),
                },
                {
                    dataType: 'numeric',
                    field: "quantity",
                    header: "lbl_sales_orders_o_info_items_quantity",
                    style: CssUtils.width('8%'),
                },
                {
                    field: "retailPrice",
                    header: "lbl_sales_cart_store_cart_items_retail_price_unit",
                    //...TawreedTableFormatters.decimal("retailPrice", user?.scale),
                    dataType: 'numeric',
                    style: CssUtils.align('left',  CssUtils.width('10%')),
                    className: CssUtils.className.field.amount,
                },
                {
                    field: "discountValue",
                    header: "lbl_base_discount",
                    body: (rowData: any) => rowData["discountValue"] + ' ' + (rowData["discountType"] === 'percentage' ? "%" : rowData["discountType"] === "fixed" ? (props.lang ==="en" ? "J.D": "د.أ") : "%+"),
                    style: CssUtils.width('10%'),
                    className: CssUtils.className.field.amount,
                },
                {
                    dataType: 'numeric',
                    field: "discountBonus",
                    header: "lbl_additional_discount_unit",
                    style: CssUtils.width('10%'),
                },
                {
                    dataType: 'numeric',
                    field: "bonus",
                    header: "lbl_store_products_bonus",
                    style: CssUtils.width('8%'),
                },
                {
                    field: "tax",
                    header: "lbl_salesordersitem_totaltax",
                    body: (rowData: any) => rowData["storeProductTax"] + " %",
                    style: CssUtils.width('5%'),
                    className: CssUtils.className.field.amount,
                },
                {
                    field: "itemTotal",
                    header: "lbl_sales_orders_o_info_items_item_total_unit",
                    //...TawreedTableFormatters.decimal("itemTotal", user?.scale),
                    style: CssUtils.width('10%'),
                    className: CssUtils.className.field.amount,
                },
            ]
    };

    return (<div className={props.className}><TawreedTable {...tawreed} /></div>);
}
