import { PaginatedRequestParams, PaginatedResponse } from "../../../../../common/pagination";
import { ProductsRepository } from "../../data";
import { ProductDto, ProductMatchDto } from "../dtos";

export class ProductsService {

    private readonly repository: ProductsRepository;

    constructor() {
        this.repository = new ProductsRepository();
    }


    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<ProductDto>>;
    public search(api: 'similar', query: string): Promise<PaginatedResponse<ProductDto>>;

    public search(arg: PaginatedRequestParams | 'similar', q?: string): Promise<PaginatedResponse<ProductDto>> {
        if (arg === 'similar') {
            return this.repository.search(arg, q ?? '');
        }
        return this.repository.search(arg);
    }

    public createOrUpdate(form: ProductDto): Promise<ProductDto> {
        return this.repository.createOrUpdate(form);
    }

    public getDetails(id: number): Promise<ProductDto> {
        return this.repository.getDetails(id);
    }

    public delete(id: number): Promise<boolean> {
        return this.repository.delete(id);
    }

    public deleteAll(ids: number[]): Promise<boolean> {
        return this.repository.deleteAll(ids);
    }
    public changeCategory(ids: number[], categoryId: number): Promise<boolean> {
        return this.repository.changeCategory(ids, categoryId);
    }

    public verify(id: number, categoryId: number): Promise<boolean> {
        return this.repository.verify(id, categoryId);
    }
    public link(productId: number, linkTo: number): Promise<boolean> {
        return this.repository.link(productId, linkTo);
    }
    public addImage(productId: number, uid: string): Promise<boolean> {
        return this.repository.addImage(productId, uid);
    }

    public getMatches(productId: number): Promise<ProductMatchDto[]> {
        return this.repository.getMatches(productId);
    }

}
