import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../http";
import { WrongOrder } from "../../domain";
import { WrongorderMapper } from "../mappers";

export class WrongOrderRepository {

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<WrongOrder>> {

        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = { ...buildFilterMetaToObject(request.keyword, { ...request.filters }) };
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.WRONG_ORDERS_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => WrongorderMapper.fromJson(e)) ?? [] },
                };
            });
    }


    /**
     * Get partner details by id
     * @param id
     */
    public async getDetails(id: number): Promise<WrongOrder> {
        return tawreedHttpService.post<number, JObject>(endpoints.WRONG_ORDERS_GET_DETAILS, id, undefined, undefined, 'error').then(res => WrongorderMapper.fromJson(res.data!));
    }

    public releaseAmount(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, boolean>(endpoints.PAYMENT_ORDERS_RELEASE_AMOUNT, id,undefined,undefined,'all').then(res => {
            return res.status === 200
        });
    }
}