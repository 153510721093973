
import { CategoryRepository } from "../../data";
import { SettingCategoryDto } from "../dtos";

export class CategoryService {

    private readonly repository: CategoryRepository;

    constructor() {
        this.repository = new CategoryRepository();
    }
    public getAll(): Promise<Array<SettingCategoryDto>> {
        return this.repository.getAll();
    }
    public getDetails(id: number): Promise<SettingCategoryDto> {
        return this.repository.getDetails(id);
    }
    public addOrUpdata(data: SettingCategoryDto): Promise<SettingCategoryDto> {
        return this.repository.addOrUpdate(data);
    }
    public delete(data: number): Promise<any> {
        return this.repository.delete(data);
    }

}
