import React from "react";
import { PrimeIcons } from "primereact/api";
import { useHistory } from "react-router-dom";

import { TawreedAction, TawreedActionName } from "../../../../../common/actions";
import { TawreedTable, TawreedTableProps } from "../../../../../common/components/table";
import { CssUtils } from "../../../../../helpers/css";
import { Routes } from "../../../../../router";
import { TopUpPartner, TopUpPartnerService } from "../../../domain";
import { TawreedTableFilters } from "../../../../../common/components/table/filters";
import { GlobalContext } from "../../../../../context";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";


export const TopUpPartnerTable: React.FC = () => {
    const history = useHistory();
    const service = React.useMemo(() => new TopUpPartnerService(), []);
    const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext);

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.TopUpPartnersNew);
            resolve();
        });
    }

    const onDetails = (sender?: TopUpPartner): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sender && sender.topUpPartnerId) {
                history.push(Routes.TopUpPartnersDetails.replace(':id', sender.topUpPartnerId.toString()));
                resolve();
            } else {
                reject();
            }
        });
    }

    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        dataKey: 'topUpPartnerId',
        name: 'tbl.topup.partners',
        title: 'lbl_topup_partners',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            {
                field: "partnerName",
                header: "lbl_partners_partner_name",
                sortable: true,
                sortField: 'partnerName',
                filter: true,
                filterField: 'partnerName',
                style: CssUtils.width('30%'),
            },
            {
                dataType: 'numeric',
                field: "businessType",
                header: "lbl_partners_business_type",
                sortable: true,
                sortField: 'businessType',
                filter: true,
                filterField: 'businessType',
                ...TawreedTableFilters.dropdown.dropdown("businessType", constants?.businessTypesTopUp ?? [], undefined, undefined, 'equals'),
                style: CssUtils.width('20%'),
            },
            {
                field: "minimumTopUp",
                header: "lbl_topup_partners_minimumtopup",
                sortable: true,
                filter: true,
                ...TawreedTableFormatters.decimal('minimumTopUp', user?.scale, undefined, CssUtils.width('20%')),
            },
            {
                field: "maximumTopUp",
                header: "lbl_topup_partners_maximumtopup",
                sortable: true,
                filter: true,
                ...TawreedTableFormatters.decimal('maximumTopUp' ,user?.scale, undefined, CssUtils.width('20%')),
            },
            {
                dataType: 'boolean',
                field: "active",
                header: "lbl_partners_active",
                sortable: true,
                sortField: 'active',
                filter: true,
                filterField: 'active',
                ...TawreedTableFilters.boolean('active', 'active', 'inactive'),
                style: CssUtils.width('10%'),
            },
        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.TopUpPartnersCreate, 'stateless', 'lbl_new', PrimeIcons.PLUS, onNew),
            ],
        },
        row: {
            onClick: new TawreedAction(TawreedActionName.TopUpPartnersDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onDetails, 'p-button-secondary', false),
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'partnerName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'businessType': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                'minimumTopUp': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'active': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ],
                },
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'partnerName',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
        },
    }
    return (<TawreedTable  {...tawreed} />);
}