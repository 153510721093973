import React from 'react'
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedFormField, TawreedInputFile } from '../../../../../common/components/form';
import { TawreedFile } from '../../../domain';


export type TawreedFilesFormOnUploadEventHandler = (e: File) => Promise<void>;
export type TawreedFilesFormOnCancelEventHandler = () => void;

export type TawreedFilesFormProps =
    {
        /**
         * 
         */
        visible: boolean;

        /**
         * 
         */
        title: string;

        /**
         * 
         */
        onUpload: TawreedFilesFormOnUploadEventHandler;

        onCancel: TawreedFilesFormOnCancelEventHandler;
    };

export const TawreedFilesForm: React.FC<TawreedFilesFormProps> = (props) => {

    /**
     * 
     */
    const [value, setValue] = React.useState<File>();
    const [uploading, setUploading] = React.useState<boolean>(false);


    const onUpload = () => {
        if (value) {
            setUploading(true);
            props.onUpload(value).finally(() => setUploading(false));
        }
    }


    const TawreedFilesFormDialogFooter = () => {
        return (
            <div>
                <Button label={tawreedTranslationService.translate('lbl_cancel')} icon={PrimeIcons.TIMES} className="p-button-text" disabled={uploading} onClick={props.onCancel} />
                <Button label={tawreedTranslationService.translate('lbl_upload')} icon={PrimeIcons.CHECK} disabled={!value} loading={uploading} onClick={onUpload} />
            </div>);
    }


    const onHide = () => {
    };

    const onChange = (e: TawreedFile | undefined) => setValue(e && e.value instanceof File ? e.value : undefined);

    return (
        <Dialog
            visible={props.visible} onHide={onHide}
            draggable={false} position="top" closable={false} closeOnEscape={false} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            header={tawreedTranslationService.translate(props.title)} footer={<TawreedFilesFormDialogFooter />}>
            <form className="formgrid grid">
                <TawreedFormField title="lbl_files_file" className="field col-12">
                    <TawreedInputFile render='standalone' onChange={onChange} />
                </TawreedFormField>
            </form>
        </Dialog>
    );
}
