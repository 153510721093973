
import { JObject } from "../../../../common/data/models";
import { CommissionRepository } from "../../data";
import { CommissionDto } from "../dtos";

export class CommissionService {

    private readonly repository: CommissionRepository;

    constructor() {
        this.repository = new CommissionRepository();
    }

    public getDetails(priceListId: number, partnerId: number): Promise<CommissionDto> {
        return this.repository.getByStoreProductIdAndPartnerId(priceListId, partnerId);
    }
    public getDetailsByPaymentMode(partnerId: number): Promise<CommissionDto[]> {
        return this.repository.getByStoreProductId(partnerId);
    }
    public createOrUpdate(dto: CommissionDto): Promise<CommissionDto> {
        return this.repository.createOrUpdate(dto);
    }
    public checkPrice(dto: CommissionDto): Promise<JObject> {
        return this.repository.checkPrice(dto);
    }
    public validateComissionOrderMin(dto: CommissionDto): Promise<JObject> {
        return this.repository.validateComissionOrderMin(dto);
    }
    public deleteCommissionById(id: number): Promise<boolean> {

        return this.repository.deleteCommissionById(id);
    }
}
