import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect, useContext } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedFormField, TawreedInputNumber } from "../../../../../common/components/form";
import { CssUtils } from '../../../../../helpers/css';
import { AuthUtils } from '../../../../auth/domain';
import { buildCategoriesTawreedTableFormatter } from '../../../products/presentaion/components/categories-table-formatter';
import { TawreedTableFilters } from '../../../../../common/components/table/filters';
import { TawreedAction, TawreedActionName } from '../../../../../common/actions';
import { PrimeIcons } from 'primereact/api';
import { TawreedTable, TawreedTableProps } from '../../../../../common';
import { Product } from '../../../products/data';
import { Routes } from '../../../../../router';
import { ProductsService } from '../../../products/domain';
import { Link, useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../../../context';
import { JObject } from '../../../../../common/data/models';


export interface ProductDialogProps {
    visible: boolean;

    hide: () => void;
    isDiscount: boolean;

    accept: (data: JObject) => void;
    isVerfied?: boolean;
    productName?:string;

}

export const ProductDialog: FC<ProductDialogProps> = ({ visible, hide, accept, isDiscount,isVerfied,productName }) => {


    const [dialogState, setDialogState] = useState<{ data?: JObject }>({
        data: undefined,

    });





    const BonusDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={false} />

        </div>);
    }
    const history = useHistory();
    const { constants: { constants } } = useContext(GlobalContext);
    const service: ProductsService = new ProductsService();
    const [reload, setReload] = useState(false);

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.ProductsNew);
            resolve();
        })
    }

    const onForm = (sender?: Product): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.ProductsDetails.replace(':id', sender!.productId.toString()));
            resolve();
        })
    }

    const onDelete = (sender?: Product | Array<Product>): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!sender) {
                reject('No item selected');
                return;
            }
            if (Array.isArray(sender)) {
                setReload(false);
                return service.deleteAll(sender.map(e => e.productId)).then(() => setReload(true)).catch(err => console.error(err));
            } else {
                setReload(false);
                return service.delete(sender.productId).then(() => setReload(true)).catch(err => console.error(err));
            }
        })
    };

    const tawreed: TawreedTableProps = {
        reload: reload,
        header: true,
        pagination: true,
        dataKey: 'productId',
        name: '',
        title: 'lbl_products',
        keyword: productName,
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.search(q),
        },
        columns: [

            {
                field: "internationalCode",
                header: "lbl_product_international_code",
                sortable: true,
                filter: true,
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return(
                    <Link to={Routes.ProductsDetails.replace(':id', options.productId!.toString())} target='_blank' >{options.internationalCode}</Link>
                )}
            },
            {
                field:"displayName",
                header: "lbl_product_name",
                sortable: true,
                filter: true,
                style: CssUtils.width('40%', CssUtils.color('var(--danger-color)')),
            },
            {
                field: "categoryId",
                header: "lbl_product_category",
                sortable: true,
                filter: true,
                style: CssUtils.width('20%'),
                ...buildCategoriesTawreedTableFormatter('categoryId', constants?.productCategories ?? []),
            },
            {
                field: "test",
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return (
                        <React.Fragment>
                            <Button onClick={() => {
                                setDialogState({ data: options })
                                accept(options);

                            }}>{tawreedTranslationService.translate("lbl_select")}</Button>
                        </React.Fragment>)
                }
            },

        ],
        row: {
            //onClick: new TawreedAction(TawreedActionName.ProductsDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onForm, 'p-button-secondary', false),
        },
        filter: {
            filterDisplay: 'menu',
            initial: isVerfied ? {
                'isVerified':{
                    operator: 'AND',
                    constraints: [
                        {
                            value: true,
                            matchMode: 'equals',
                        },
                    ]
                },
                'displayName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'internationalCode': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },

                'categoryId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        }
                    ]
                },

            }: {
                'displayName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'internationalCode': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },

                'categoryId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        }
                    ]
                },

            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'displayName',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },

    }

    return (
        
        <Dialog maximizable resizable style={{ minWidth: '60%' }} header={''} visible={visible} footer={<BonusDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <TawreedTable  {...tawreed} />
        </Dialog>
    )
}
