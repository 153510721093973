
import { BannerRepository } from "../../data";
import { SettingBannerDto } from "../dtos";

export class BannerService {

    private readonly repository: BannerRepository;

    constructor() {
        this.repository = new BannerRepository();
    }
    public getAll(): Promise<Array<SettingBannerDto>> {
        return this.repository.getAll();
    }
    public getDetails(id: number): Promise<SettingBannerDto> {
        return this.repository.getDetails(id);
    }
    public addOrUpdate(data: SettingBannerDto): Promise<SettingBannerDto> {
        return this.repository.addOrUpdate(data);
    }
    public delete(data: number): Promise<any> {
        return this.repository.delete(data);
    }

}
