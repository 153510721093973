
import { JObject } from "../../../../common/data/models"
import { BannerSetting } from "../models";
import { SettingBannerDto } from "../../domain";
import { TawreedFileMapper } from "../../../upload/data";


export const BannerMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {BannerSetting}
     */
    fromJson: (json?: JObject): BannerSetting => {
        return {
            bannerId: json?.bannerId,
            businessType: json?.businessType,
            image: json?.image
        }
    },

    /**
     *
     */
    toJson: (model: BannerSetting): JObject => {
        return { ...model };
    },



    /**
     *
     */
    toDto: (model: BannerSetting): SettingBannerDto => {
        return {
            bannerId: model.bannerId!,
            businessType: model.businessType!,
            image: TawreedFileMapper.fromJson({
                fileName: `CATEGORY_IMAGE_${model.bannerId}`,
                value: model.image,
            }),
        };
    },
}

