
import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { SettingBannerDto } from "../../domain";
import { BannerMapper } from "../mappers"
import { JObject } from "../../../../common/data/models";
import { TawreedFileMapper } from "../../../upload/data";

export class BannerRepository {


    public async getAll(): Promise<Array<SettingBannerDto>> {

        return tawreedHttpService.post<null, Array<JObject>>(endpoints.SETTINGS_BANNER, null, undefined, undefined, 'error')
            .then(async (res) => {
                const result: Array<SettingBannerDto> = [];
                res.data?.forEach(el => {
                    result.push(BannerMapper.toDto(BannerMapper.fromJson(el)));
                })
                return result;
            });
    }
    public async addOrUpdate(data: SettingBannerDto): Promise<SettingBannerDto> {

        return tawreedHttpService.post<JObject, JObject>(endpoints.SETTINGS_BANNER_ADD_UPDATE, { ...data, image: '' }).then(async (res) => {
            await this.createOrUploadBannerImage({ ...data, bannerId: res.data?.bannerId! });
            const result = await this.getDetails(res.data?.bannerId);

            return result;
        })
    }
    public async getDetails(data: number): Promise<SettingBannerDto> {

        return tawreedHttpService.post<number, JObject>(endpoints.SETTINGS_BANNER_DETAILS, data, undefined, undefined, 'error')
            .then(async (res) => {
                return BannerMapper.toDto(BannerMapper.fromJson(res.data));
            });
    }

    public async createOrUploadBannerImage(data: SettingBannerDto) {
        console.log(data);
        if (data && data.bannerId && data.image && data.image.value instanceof File) {
            const uu = endpoints.UPLOAD_BANNER.replace(':bannerId', data.bannerId.toString());
            const cc = TawreedFileMapper.toFormData(data.image);
            return tawreedHttpService.upload(uu, cc);
        }
    }
    public async delete(data: number): Promise<any> {

        return tawreedHttpService.post<number, JObject>(endpoints.SETTINGS_BANNER_DELETE, data, undefined, undefined, 'error')
            .then(async (res) => {
                return res;
            });
    }

}
