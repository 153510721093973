import React, { FC } from 'react'

import { CategoryTable } from '../components/category-table';

import { CategoryForm } from '../components/category-form';



/**
 * Tawreed Customers Page
 * @returns
 */
export const CategoryPageTable: FC = () => (<CategoryTable />);

/**
 * Tawreed  Customer Form Page
 * @returns
 */
export const CategoryPageForm: FC = () => (<CategoryForm />);

