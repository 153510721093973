import { Dialog } from "primereact/dialog";
import React, { FC, useEffect, useState } from "react";
import { tawreedTranslationService } from "../../../../common/translations";
import { Button } from "primereact/button";
import { TawreedFormField, TawreedInputText } from "../../../../common";
import { PartnerAutoCompleteComponent } from "./autocomplete";
import { PartnerType } from "../../data";

export interface StoreDeliveryDialogProps {
    visible: boolean;

    hide: () => void;
    store: number,

    accept: (data: { deliveyId: number, deliveryName: string, value: string }) => void;

}
export const StoreDeliveryDialog: FC<StoreDeliveryDialogProps> = ({ visible, hide, accept, store }) => {
    const [dialogState, setDialogState] = useState<{
        deliveyId: number | undefined, deliveryName: string | undefined, value: string | undefined,
        loading: boolean
    }>({
        deliveyId: undefined,
        loading: false,
        deliveryName: undefined,
        value: undefined,
    });

    useEffect(() => {
        setDialogState({
            ...dialogState,
            deliveyId: undefined,
            loading: false,
            deliveryName: undefined,
            value: undefined,
        });
    }, [visible])

    const MappingDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={dialogState.loading} />
            <Button onClick={() =>
                accept({
                    deliveyId: dialogState.deliveyId!, deliveryName: dialogState.deliveryName!,
                    value: dialogState.value!
                })

            } label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" disabled={!dialogState.deliveyId || !dialogState.deliveryName || !dialogState.value}
                loading={dialogState.loading} />
        </div>);
    }

    return (
        <Dialog style={{ minHeight: '60%' }} header={tawreedTranslationService.translate('lbl_store_delivery_mapping_add')} visible={visible} footer={<MappingDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <form className="formgrid grid" >
                <React.Fragment>
                    <TawreedFormField name="deliveryId" title="lbl_store_delivery_mapping_delivery_name" className="field col-12">
                        <PartnerAutoCompleteComponent
                            partnerType={PartnerType.Delivery}
                            autoCompleteName="deliveryId"
                            autoCompleteField="partnerName"
                            autoCompleteRender="standalone"
                            autoCompleteOnSelect={(e) => setDialogState({ ...dialogState, deliveyId: e.value.partnerId, deliveryName: e.value.partnerName })} />
                    </TawreedFormField>
                    <TawreedFormField name="id" className="field col-12 md:col-6"
                        title="lbl_store_delivery_mapping_value">
                        <TawreedInputText name="id" render="standalone" onChange={(e)=>{setDialogState({ ...dialogState,value: e.target.value})}} value={dialogState.value}/>
                    </TawreedFormField>
                </React.Fragment>
                {/* <React.Fragment>
                    <TawreedFormField name="product" title="lbl_store_products_product_id" className="field col-12 md:col-12">
                        <React.Fragment>
                            <StoreProductSellerAutoCompleteComponent
                                //storeId={.store?.partnerId}
                                partnerId={store}
                                autoCompleteName="product"
                                autoCompleteField="storeProductName"
                                autoCompleteRules={{ required: "msg_field_is_required" }}
                                autoCompleteDisabled={store ? false : true}
                                autoCompleteRender="standalone"
                                autoCompleteOnSelect={e => {
                                    const p = e.value as StoreProductDto;
                                    if (p) {
                                        setDialogState({
                                            ...dialogState,
                                            tax: p.tax,
                                            price: p.price,
                                            storeProductId: p.storeProductId,
                                            storeProductName: AuthUtils.current().language ==="en" ? p.titleEn : p.titleAr
                                        });
                                    } else {
                                        setDialogState({
                                            ...dialogState,
                                            tax: undefined,
                                            price: undefined,
                                            storeProductId: undefined,
                                        });
                                    }
                                }} />
                        </React.Fragment>
                    </TawreedFormField>
                    <TawreedFormField name="price" title="lbl_sellingPrice_befor_per_unit" className="field col-4 lg:col-4 md:col-6 mt-2">
                        <TawreedInputNumber mode="decimal" suffix=" JOD" value={dialogState?.price} name="price" render="standalone" onChange={(e) => {
                            setDialogState({ ...dialogState, price: e.value ? e.value : 0 });
                        }} />
                    </TawreedFormField>
                    <TawreedFormField name="tax" title="lbl_store_products_tax" className="field col-4 md:col-4">
                        <TawreedInputNumber suffix={" %"} value={dialogState?.tax} mode={"decimal"} name="tax" 
                        rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                            setDialogState({ ...dialogState, tax: e.value ? e.value : 0 });}}/>
                    </TawreedFormField>
                    <TawreedFormField name="quantity" title="lbl_store_products_qty" className="field col-4 lg:col-4 md:col-6">
                        <TawreedInputNumber value={dialogState?.quantity} name="quantity" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                            setDialogState({ ...dialogState, quantity: e.value ? e.value : 0 });
                        }} />
                    </TawreedFormField>
                    <TawreedFormField className="field col-4 lg:col-4 md:col-4" name="discountType" title="lbl_price_scheme_discountType">
                        <DiscountTypesDropDown value={dialogState?.discountType} name="discountType" render="standalone" rules={{ required: 'msg_field_is_required' }} onChange={(e) => {
                            setDialogState({ ...dialogState, discountType: e.value })
                        }} />
                    </TawreedFormField>
                    <TawreedFormField name="discountValue" title={"lbl_price_scheme_discountValue"} className="field col-4 lg:col-4 md:col-4">
                        <TawreedInputNumber mode={'decimal'} value={dialogState?.discountValue} name="discountValue" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                            setDialogState({ ...dialogState, discountValue: e.value ? e.value : 0 });
                        }} />
                    </TawreedFormField>

                     <React.Fragment>
                            <TawreedFormField name="sellingPrice" title="lbl_sellingPrice_befor_after_unit" className="field col-4 lg:col-4 md:col-6 mt-2">
                                <TawreedInputNumber mode="decimal" disabled suffix=" JOD" value={sellingPrice} name="sellingPrice" render="standalone" readOnly />
                            </TawreedFormField>
                        </React.Fragment>
                        
                <React.Fragment>
                            <TawreedFormField name="grossTotal" title="lbl_sub_total" className="field col-4 lg:col-4 md:col-6 mt-2">
                                <TawreedInputNumber mode="decimal" disabled suffix=" JOD" value={subTotal} name="grossTotal" render="standalone" readOnly />
                            </TawreedFormField>
                        </React.Fragment>
                </React.Fragment> */}
            </form>
        </Dialog>
    )
}