import React from "react";
import { TawreedTable, TawreedTableProps } from "../../../../../common/components/table";
import { CssUtils } from "../../../../../helpers/css";
import { WalletTransactionService } from "../../../domain";
import { TawreedTableFilters } from "../../../../../common/components/table/filters";
import { Tag } from "primereact/tag";
import { tawreedTranslationService } from "../../../../../common/translations";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { WalletTransactionDto } from "../../../domain"
import { Routes } from "../../../../../router";
import { useHistory } from "react-router-dom"
import { TawreedAction, TawreedActionName } from "../../../../../common/actions";
import { PrimeIcons } from "primereact/api";
import { GlobalContext } from "../../../../../context";
export const WalletTransactionTable: React.FC = () => {
    const history = useHistory();
    const service = React.useMemo(() => new WalletTransactionService(), []);
    const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext);

    const onDetails = (sender?: WalletTransactionDto): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sender && sender.walletTransactionId) {
                history.push(Routes.WalletTransactionDetails.replace(':id', sender.walletTransactionId.toString()));
                resolve();
            } else {
                reject();
            }
        });
    }


    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        dataKey: 'txnTopUpId',
        name: 'tbl.topup.transactions',
        title: 'lbl_topup_transactions',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            {
                field: "walletTransactionId",
                header: "lbl_wallet_transaction_wallet_transaction_id",
                sortable: true,
                style: CssUtils.width('10%'),
            },
            {
                field: "walletTransactionReference",
                header: "lbl_wallet_transaction_wallet_transaction_reference",
                filter: true,
                filterField: "walletTransactionReference",
                style: CssUtils.width('10%'),
            },
            {
                field: "walletId",
                header: "lbl_wallet_transaction_wallet_id",
                filter: true,
                style: CssUtils.width('10%'),
            },
            {
                field: "amount",
                header: "lbl_wallet_transaction_amount",
                sortField: 'amount',
                sortable: true,
                ...TawreedTableFormatters.decimal('amount', user?.scale, CssUtils.width('10%')),
                className: CssUtils.className.field.amount,
            },
            {
                field: "transactionTime",
                header: "lbl_wallet_transaction_transaction_time",
                filter: true,
                ...TawreedTableFilters.date.range('transactionTime', 'date'),
                style: CssUtils.width('10%'),
            },
            {
                field: "channel",
                header: "lbl_wallet_transaction_channel",
                filter: true,
                style: CssUtils.width('10%'),
            },
            {
                field: "debitor",
                header: "lbl_wallet_transaction_debitor",
                style: CssUtils.width('10%'),
            },
            {
                field: "description",
                header: "lbl_wallet_transaction_description",
                style: CssUtils.width('20%'),
            },

            {
                field: "status",
                header: "lbl_wallet_transaction_status",
                sortable: true,
                filter: true,
                style: CssUtils.width('15%'),
                ...TawreedTableFilters.dropdown.dropdown("status", constants && constants.transactionStatuses ? constants.transactionStatuses : [], 'value', 'label', 'in'),
            },
        ],

        row: {
            onClick: new TawreedAction(TawreedActionName.WalletTransactionDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onDetails, 'p-button-secondary', false),
        },

        filter: {
            filterDisplay: 'menu',
            initial: {
                'walletTransactionReference': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'walletId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'channel': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'active': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ],
                },
                'transactionTime': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'dateIs',
                        }
                    ],
                },
                'status': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'walletTransactionId',
                sortOrder: -1,
                multiSortMeta: undefined,
            },

        },
        selection: {
            mode: 'checkbox',
        },
    }
    return (<TawreedTable  {...tawreed} />);
}
