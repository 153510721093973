import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { PartnerMapper } from "../../../partners/data";
import { BrokenOrder } from "../../domain";

export const BrokenOrderMapper = {

    fromJson(obj: JObject): BrokenOrder {
        return {
            financialBrokenOrderId: obj.financialBrokenOrderId,
            sellerPartner: PartnerMapper.toDto(PartnerMapper.fromJson({
                partnerId: obj.storeId,
                partnerName: obj.store,
            })),
            buyerPartner: PartnerMapper.toDto(PartnerMapper.fromJson({
                partnerId: obj.customerId,
                partnerName: obj.customer,
            })),
            reason: obj.reason,
            amount: obj.amount,
            financialBrokenStatus: obj.financialBrokenStatus,
            orderId: obj.orderId,
            createdAt: DateUtils.fromISO(obj.createdAt),
            updatedAt: DateUtils.fromISO(obj.updatedAt),
        };
    },
}