import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { BannerService, SettingBannerDto } from '../../domain';
import { TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputFile } from '../../../../common';
import { Routes } from '../../../../router';
import { TawreedAction, TawreedActionName } from '../../../../common/actions';
import { PrimeIcons } from 'primereact/api';
import { Panel } from 'primereact/panel';
import { tawreedTranslationService } from '../../../../common/translations';
import { PartnerBusinessTypeDropdown } from '../../../partners/presentaion/components/partner-business-type-dropdown';


export const BannerForm: React.FC = (props) => {


    const { id } = useParams<{ id: string | undefined }>();
    const service: BannerService = React.useMemo(() => new BannerService(), []);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<SettingBannerDto>();
    const history = useHistory();

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {

                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {

            if (mounted) {
                setMode('Create');
            }
        }
        return () => {
            mounted = false;
        };
    }, [id, service]);
    const onCreateOrSave = (data: SettingBannerDto, cb: TawreedFormSubmitCallback<SettingBannerDto>): Promise<void> => {

        setLoading(true);
        console.log(data);
        return service.addOrUpdate(data)
            .then(res => {
                setLoading(false);
                console.log(res);
                if (!data.bannerId && res.bannerId) {
                    history.replace(Routes.SettingsBannerDetials.replace(':id', res.bannerId.toString()));
                }
                cb(res);
            })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    }
    const onDelete = () => {
        setLoading(true);
        return service.delete(data?.bannerId!).then(res => {
            setLoading(false);
            if (res) {
                history.replace(Routes.SettingsBanner);
            }
        }).catch(err => {
            setLoading(false);
            throw err;
        });
    }

    const onSubmit: TawreedFormSubmitProps<SettingBannerDto> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<SettingBannerDto>(TawreedActionName.BannerNew, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave),
    };



    return (
        <React.Fragment>
            <TawreedForm title="lbl_banner"
                dataKey="bannerId"
                data={data}
                submit={onSubmit}
                loading={loading} mode={mode}
                useBack=".." useReset actions={data?.bannerId ? [new TawreedAction(TawreedActionName.BannerDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete')] : []} className="grid form-grid">

                <Panel header={tawreedTranslationService.translate('lbl_banner_id')} className="col-12">

                    <div className="grid">

                        <TawreedFormField className="field col-12 md:col-12" name="businessType" title="lbl_partners_business_type">
                            <PartnerBusinessTypeDropdown partnerType={2} name="businessType" render="form" rules={{ required: 'msg_field_is_required' }} />
                        </TawreedFormField>

                        <div className="col-12">
                            <div className="grid">
                                <TawreedFormField name="image" className="field col-12 md:col-3" title="lbl_Banner_image">
                                    <TawreedInputFile render="form" name="image" accept="image/*" />
                                </TawreedFormField>
                            </div>
                        </div>
                    </div>
                </Panel>
            </TawreedForm>

        </React.Fragment >
    )
}
