import React from 'react'
import { TawreedFormField, TawreedInputText, TawreedMenuDropdown } from '../../../../common'
import { TawreedReportGenerator } from './report-generator'
import { PartnerAutoCompleteComponent } from '../../../partners'

/**
 *
 * @constructor
 */
export const ReportTrialBalance: React.FC = () => {

    return (
        <TawreedReportGenerator name="trial-balance" title="lbl_reports_trial_balance">
            <TawreedFormField name="accountTitle" title="lbl_reports_trialbalance_accounttitle" className="field col-12 md:col-6">
                <TawreedInputText render="form" name="accountTitle" />
            </TawreedFormField>
            <TawreedFormField name="partnerName" title="lbl_reports_financial_statements_partner" className="field col-12 md:col-6">
                <PartnerAutoCompleteComponent partnerType={undefined} autoCompleteName="partner" autoCompleteField="partnerName" autoCompleteRender="form" />
            </TawreedFormField>
            <TawreedFormField name="accountType" title="lbl_reports_trialbalance_accounttype" className="field col-12 md:col-6">
                <TawreedMenuDropdown render="form" name="accountType" menu="financeAccountsTypes" />
            </TawreedFormField>
        </TawreedReportGenerator>
    )
}
