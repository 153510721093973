import React from "react"
import { Panel } from "primereact/panel"
import { useParams } from "react-router-dom"

import { TawreedCalendar, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputText, TawreedMenuDropdown } from "../../../../../common/components"
import { tawreedTranslationService } from "../../../../../common/translations"
import { PartnerType } from "../../../../partners/data"
import { PartnerBusinessTypeDropdown } from "../../../../partners/presentaion/components/partner-business-type-dropdown"
import { TopUpTransactionService, TopUpTransaction } from "../../../domain"
import { GlobalContext } from "../../../../../context"

export const TopUpTransactionForm: React.FC = () => {

    const { id } = useParams<{ id: string | undefined }>();
    const { auth: { user } } = React.useContext(GlobalContext);
    const service: TopUpTransactionService = React.useMemo(() => new TopUpTransactionService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<TopUpTransaction>();

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {
            if (mounted) {
                setMode('Create');
                setData(undefined);
            }
        }
        return () => {
            mounted = false;
        };
    }, [id, service]);


    const onSubmit: TawreedFormSubmitProps<TopUpTransaction> = { showButton: false, resetOnSubmit: false };

    return (
        <TawreedForm title="lbl_topup_transactions"
            dataKey="txnTopUpId" data={data}
            submit={onSubmit}
            loading={loading} mode={mode}
            useBack=".." className="grid form-grid">
            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                    <TawreedFormField name="txnTopUpId" className="field col-12 md:col-6" title="lbl_topup_txn_txntopupid">
                        <TawreedInputText name="txnTopUpId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="createdAt" className="field col-12 md:col-6" title="lbl_topup_txn_createdat">
                        <TawreedCalendar name="createdAt" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="topUpPartner.partnerName" className="field col-12 md:col-6" title="lbl_topup_txn_topuppartner">
                        <TawreedInputText name="topUpPartner.partnerName" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="partner.partnerName" className="field col-12 md:col-6" title="lbl_topup_txn_partner">
                        <TawreedInputText name="partner.partnerName" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="topUpPartner.businessType" className="field col-12 md:col-6" title="lbl_topup_txn_topuptype">
                        <PartnerBusinessTypeDropdown partnerType={PartnerType.TopUp} name="topUpPartner.businessType" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="transactionValue" className="field col-12 md:col-6" title="lbl_topup_txn_txnvalue">
                        <TawreedInputNumber mode="decimal" name="transactionValue" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="transactionStatus" className="field col-12 md:col-6" title="lbl_topup_txn_txnstatus">
                        <TawreedMenuDropdown name="transactionStatus" menu="transactionStatuses" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="transactionNetValue" className="field col-12 md:col-6" title="lbl_topup_txn_txnnetvalue">
                        <TawreedInputNumber mode="decimal" name="transactionNetValue" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="transactionCashBackValue" className="field col-12 md:col-6" title="lbl_topup_txn_txncbvalue">
                        <TawreedInputNumber mode="decimal" name="transactionCashBackValue" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="transactionFeesTotal" className="field col-12 md:col-6" title="lbl_topup_txn_txnfeestotal">
                        <TawreedInputNumber mode="decimal" name="transactionFeesTotal" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                    {
                        user &&
                        (user.roleCode === 'ADMIN' || user.roleCode === 'TOPUP_ADMIN' || user.roleCode === 'FINANCE') &&
                        <React.Fragment>
                            <TawreedFormField name="transactionFeesPartner" className="field col-12 md:col-6" title="lbl_topup_txn_txnfeespartner">
                                <TawreedInputNumber mode="decimal" name="transactionFeesPartner" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                            </TawreedFormField>

                            <TawreedFormField name="transactionFeesPlatform" className="field col-12 md:col-6" title="lbl_topup_txn_txnfeesplatform">
                                <TawreedInputNumber mode="decimal" name="transactionFeesPlatform" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                            </TawreedFormField>

                            <TawreedFormField name="transactionLog" className="field col-12 md:col-6" title="lbl_topup_txn_txnlog">
                                <TawreedInputText name="transactionLog" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                            </TawreedFormField>
                        </React.Fragment>
                    }
                    <TawreedFormField name="topUpReferenceId" className="field col-12 md:col-6" title="lbl_topup_txn_referenceid">
                        <TawreedInputText name="topUpReferenceId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                </div>
            </Panel>
        </TawreedForm>
    )
}