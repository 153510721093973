import { Dialog } from "primereact/dialog";
import React, { FC, useState, useEffect, useMemo } from 'react'
import { Button } from 'primereact/button';
import { tawreedTranslationService } from "../../../../../common/translations";
import { TawreedTable, TawreedTableProps } from "../../../../../common";
import { StoreProductsService } from "../../domain";
import { CssUtils } from "../../../../../helpers/css";
import { Tag } from "primereact/tag";
import { TawreedTableFilters } from '../../../../../common/components/table/filters';
import { GlobalContext } from "../../../../../context";

export interface StockHistoryDialogProps {
    visible: boolean;
    storeProductId?: number;

    hide: () => void;
}

    export const StockHistoryDialog: FC<StockHistoryDialogProps> = ({ visible, hide, storeProductId }) => {
    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);
    
    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);
    const StockHistoryDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" />
        </div>);
    }

    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.searchStock(q),
        },
        dataKey: 'stockId',
        name: 'tbl.stock',
        title: 'lbl_stock_history',
        columns: [
            {
                field: "stockId",
                header: "lbl_stock_stock_id",
                sortable: true,
                filter: true,
                style: CssUtils.width('5%'),
            },
            {
                field: "movementType",
                header: "lbl_stock_movement_type",
                sortable: true,
                sortField: "movementType",
                filter: true,
                style: CssUtils.width('15%'),
                ...TawreedTableFilters.dropdown.dropdown('movementType', constants?.quantityType ?? [], 'value', 'label', 'in', (label: string) => <Tag rounded severity={'success'} style={{ width: '6rem' }} value={label} />),
            },
            {
                field: "movementSource",
                header: "lbl_stock_movement_source",
                sortable: true,
                sortField: "movementSource",
                filter: true,
                style: CssUtils.width('15%'),
               ...TawreedTableFilters.dropdown.dropdown('movementSource', constants?.quantitySource ?? [], 'value', 'label', 'in', (label: string) => <Tag rounded severity={'success'} style={{ width: '6rem' }} value={label} />),
            },
            {
                dataType:'numeric',
                field: "quantity",
                header: "lbl_stock_quantity",
                sortable: true,
                sortField: "qyantity",
                filter: true,
                filterField: 'qyantity',
                style: CssUtils.width('10%'),
            },
            {                
                field: "createdAt",
                header: "lbl_broken_orders_createdat",
                sortable: true,
                filter: true,
                style: CssUtils.width('15%'),
                ...TawreedTableFilters.date.range("createdAt", 'datetime'),
            },
            {
                dataType:'numeric',
                field: "stockBalance",
                header: "lbl_stock_stock_balance",
                sortable: true,
                sortField: "stockBalance",
                filter: true,
                filterField: 'stockBalance',
                style: CssUtils.width('10%'),
            },
            {
                field: "referenceId",
                header: "lbl_stock_reference_id",
                sortable: true,
                sortField: "referenceId",
                filter: true,
                filterField: 'referenceId',
                style: CssUtils.width('15%'),
            }
        ],
        filter: {
            filterDisplay: 'menu',
            initial: {
                'storeProductId':{
                    operator: 'AND',
                    constraints: [{ value: storeProductId, matchMode: 'equals' }],
                },
                'stockId': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'qyantity': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'stockBalance': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'referenceId': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'movementType': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'in',
                        },
                    ]
                },
                'movementSource':{
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'in',
                        },
                    ]
                },
                'createdAt': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'dateIs',
                        },
                    ]
                },
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: "stockId",
                sortOrder: -1,
                multiSortMeta: undefined,
            },

        }
    }


    return (
        <Dialog style={{minWidth: '80%'}} header={''} visible={visible} footer={<StockHistoryDialogFooter />} onHide={hide} draggable={false} position="top" closable={true} closeOnEscape={true}>
           <TawreedTable  {...tawreed} />
        </Dialog>
    )
}