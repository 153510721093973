import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import React, { useEffect, useMemo, useState } from "react";
import classNames from 'classnames';
import './commission-table.scss';
import { ColumnBodyOptions } from "primereact/column";
import { GlobalContext } from "../../../../context";
import { TawreedTable, TawreedTableProps } from "../../../../common";
import { TawreedTableEditorCallback } from "../../../../common/components/table/editors";
import { CssUtils } from "../../../../helpers/css";
import { tawreedTranslationService } from "../../../../common/translations";
import { CommissionDto, CommissionService } from "../../domain";
import { CommissionDialog } from "./commission-dialog";


export const CommissionTable: React.FC<any> = (props) => {

    const [state, setState] = useState<boolean>(false);
    const { constants: { constants } } = React.useContext(GlobalContext);
    const [commissionData, setCommissionData] = useState<CommissionDto[]>([]);

    const service: CommissionService = useMemo(() => new CommissionService(), []);

    const hide = () => {
        setState(false);
    }

    const accept = (data: { type: string, value: number, paymentModeId: number }) => {
        if(commissionData.filter(x=>x.paymentModeId === data.paymentModeId).length>0)
        {
            var newList = commissionData.filter(x=>x.paymentModeId !== data.paymentModeId);
            var item =commissionData.filter(x=>x.paymentModeId === data.paymentModeId).at(0);
            newList.push({...data, commissionId: item?.commissionId});
            setCommissionData([...newList]);
            props.changeComission({ ...data, partnerId: props.partnerId, commissionId: item?.commissionId}, false);
        }
        else{
            props.changeComission({ ...data, partnerId: props.partnerId}, false);
            setCommissionData([...commissionData,{...data}]);
        }
        hide();
    }

    const deleteBodyButton = (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {

        return <Button
            style={{ width: "60%", height: "35px" }}
            icon={PrimeIcons.TRASH}
            className="p-button-danger"
            loading={props.loading}
            onClick={() => {
                deleteById(rowData.paymentModeId, rowData.commissionId);
            }}
        >
        </Button>
    }
    const deleteById = async (paymentModeId: number, id?: number | undefined) => {
        try {
            if (id) {
                await service.deleteCommissionById(id);
                
                var newList = commissionData.filter(x=>x.commissionId !== id);
                setCommissionData([...newList]);
            }
            else {
                var newList = commissionData.filter(x=>x.paymentModeId !== paymentModeId);
                setCommissionData([...newList]);
            }

            props.deleteComission(paymentModeId, id);
        } catch (err) {
            console.log(err);
        }
    }
    
    React.useEffect(() => {
        if(props.partnerId>0){

            service.getDetailsByPaymentMode(props.partnerId).then((res)=>{
                setCommissionData(res);
                res.forEach((item) =>{
                    props.changeComission({ ...item}, false);
                })
            })
        }
    }, [service, props.reload]);

    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'basic',
            data: commissionData ?? [],
        },
        name: 'lbl_payment_mode',
        dataKey: '',
        columns:
         [ {
                    dataType: 'numeric',
                    field: "type",
                    header: "lbl_commission_type",
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'numeric',
                    field: "value",
                    header: "lbl_commission_value",
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'string',
                    field: "paymentModeId",
                    header: "lbl_payment_mode",
                    body:(rowData: any) => {
                        if (rowData['paymentModeId']) {
                            return constants?.paymentModes.filter(x=>x.id===rowData['paymentModeId']).at(0)?.label;
                        }
                    },
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'numeric',
                    field: "delete",
                    body: deleteBodyButton,
                    style: CssUtils.width('2%'),
                }
            ],
        header: {
            left: {
                search: false,
                custom: (
                    <span>
                        {tawreedTranslationService.translate(props.title)}
                    </span>
                )
            },
            right: {
                clear: false,
                custom: (
                    !props.disabeld &&
                    <div className="formgroup-inline">
                        <i className={classNames(PrimeIcons.PLUS, 'bouns_table_plus')} onClick={() => setState(true)}></i>
                    </div>
                )
            }
        }
    };

    return (
        <React.Fragment>
            <div className="col-12 md:col-12"><TawreedTable {...tawreed} /></div>
            <CommissionDialog
                visible={state}
                hide={hide}
                partnerId={props.parentId}
                accept={accept}
            ></CommissionDialog>
        </React.Fragment>
    );

}
