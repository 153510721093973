import React, { useEffect, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { useHistory } from "react-router-dom";

import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { TawreedTable, TawreedTableProps } from "../../../../common/components/table";
import { CssUtils } from "../../../../helpers/css";
import { Routes } from "../../../../router";
import { TawreedTableFilters } from "../../../../common/components/table/filters";
import { GlobalContext } from "../../../../context";
import { TawreedTableFormatters } from "../../../../common/components/table/formatters";
import { WrongOrder, WrongorderService } from "../../domain";

export interface ReportType {
    type: string
}

export const WrongOrderTable: React.FC<ReportType> = (props) => {
    const history = useHistory();
    const service = React.useMemo(() => new WrongorderService(), []);
    const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext);
    const [reload, setReload] = useState(false);
    const [title,setTitle] = useState('');


    useEffect(()=>{
        switch (props.type) {
            case "DELIVERED":
                setTitle("lbl_payment_delivered");
                break;
            case "SHIPPED":
                setTitle("lbl_payment_shipped");
                break;
            case "PLACED":
                setTitle("lbl_payment_placed");
                break;
        }
    },[props.type]);

    const onRelease = (orderId: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            setReload(true);
                service.releaseAmount(orderId)
                    .then((result:boolean) => {
                        if(result){
                            setReload(false);
                            resolve();
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
        });
    };
    const onDetails = (sender?: WrongOrder): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sender && sender.orderId) {
                history.push(Routes.WrongOrderDetails.replace(':id', sender.orderId.toString()));
                resolve();
            } else {
                reject();
            }
        });
    }
    let initialFilter = {};
    switch (props.type) {
        case "DELIVERED":
            initialFilter = {
                'payThroughUs':{
                    operator: 'AND',
                    constraints: [
                        {
                            value: true,
                            matchMode: 'equals',
                        },
                    ]
                },
                 'paymentOrderStatus': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: 1,
                            matchMode: 'equals',
                        },
                    ]
                },  
                'statusId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: 4,
                            matchMode: 'equals',
                        },
                    ]
                },
            };
            break;
        case "SHIPPED":
            initialFilter = {
                'payThroughUs':{
                    operator: 'AND',
                    constraints: [
                        {
                            value: true,
                            matchMode: 'equals',
                        },
                    ]
                },
                'paymentOrderStatus': {
                   operator: 'AND',
                   constraints: [
                       {
                           value: 2,
                           matchMode: 'equals',
                       },
                   ]
               },  
               'statusId': {
                   operator: 'AND',
                   constraints: [
                       {
                           value: 3,
                           matchMode: 'equals',
                       },
                   ]
               },
           };
            break;
        case "PLACED":
            initialFilter = {
                'payThroughUs':{
                    operator: 'AND',
                    constraints: [
                        {
                            value: true,
                            matchMode: 'equals',
                        },
                    ]
                },
                'orderPaymentOrderId': {
                   operator: 'AND',
                   constraints: [
                       {
                           value: "",
                           matchMode: 'isNull',
                       },
                   ]
               },  
               'statusId': {
                   operator: 'AND',
                   constraints: [
                       {
                           value: 1,
                           matchMode: 'equals',
                       },
                   ]
               },
           };
            break;
    }
    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        dataKey: 'orderId',
        name: 'tbl.wrong.orders',
        title: title,
        reload: reload,
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            {
                field: "orderId",
                header: "lbl_payment_orders_orderId",
                sortable: true,
                filter: true,
                style: CssUtils.width('15%'),
            },
            {                
                field: "createdAt",
                header: "lbl_payment_orders_createdat",
                sortable: true,
                filter: true,
                style: CssUtils.width('15%'),
                ...TawreedTableFilters.date.range("createdAt", 'datetime'),
            },
            {
                field: "sellerPartner.partnerName",
                header: "lbl_payment_orders_seller_partner",
                sortable: true,
                sortField: 'store',
                filter: true,
                filterField: 'store',
                style: CssUtils.width('20%'),
            },
            {
                field: "buyerPartner.partnerName",
                header: "lbl_payment_orders_buyer_partner",
                sortable: true,
                sortField: 'customer',
                filter: true,
                filterField: 'customer',
                style: CssUtils.width('20%'),
            },
            {
                field: "orderTotal",
                header: "lbl_payment_orders_amount",
                sortable: true,
                filter: true,
                ...TawreedTableFormatters.decimal('orderTotal',  user?.scale,undefined, CssUtils.width('15%')),
            },
            // {
            //     field: "actions",
            //     // header: "lbl_add_to_cart",
            //     body: (options: any) => {
            //         return <Button
            //             type="button"
            //             style={{ width: "100%", height: "35px" }}
            //             icon={PrimeIcons.CHECK}
            //             className="p-button-success"
            //             onClick={() => {
            //                 onRelease(options.orderId);
            //                 }} />
            //     },
            //     style: CssUtils.width('12%'),
            // }

        ],
        row: {
            onClick: new TawreedAction(TawreedActionName.PaymentTransactionsDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onDetails, 'p-button-secondary', false),
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                ...initialFilter,
                'orderId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                'createdAt': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'dateIs',
                        },
                    ]
                },
                'store': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'customer': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'orderTotal': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },                 
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'orderId',
                sortOrder: -1,
                multiSortMeta: undefined,
            },
        },
    }
    return (
     <TawreedTable  {...tawreed} />
    );
}