import { PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { WrongOrderRepository } from "../../data";
import { WrongOrder } from "../dtos";

export class WrongorderService {

    private readonly repository;

    constructor() {
        this.repository = new WrongOrderRepository();
    }

    public search(req: PaginatedRequestParams): Promise<PaginatedResponse<WrongOrder>> {
        return this.repository.search(req);
    }


    /**
     * Get partner details by id
     * @param id
     */
    public getDetails(id: number): Promise<WrongOrder> {
        return this.repository.getDetails(id);
    }

    public releaseAmount(id: number): Promise<boolean> {
        return this.repository.releaseAmount(id);
    }

    // public deactivateAll(ids: number[]): Promise<boolean> {
    //     return this.repository.deactivateAll(ids);
    // }
}