import { PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { TopUpTransactionRepository } from "../../data";
import { TopUpTransaction } from "../dtos";

export class TopUpTransactionService {

    private readonly repository;

    constructor() {
        this.repository = new TopUpTransactionRepository();
    }

    public search(req: PaginatedRequestParams): Promise<PaginatedResponse<TopUpTransaction>> {
        return this.repository.search(req);
    }

    /**
     * Get partner details by id
     * @param id
     */
    public getDetails(id: number): Promise<TopUpTransaction> {
        return this.repository.getDetails(id);
    }
}