import React from "react";
import { WrongOrderTable } from "../components";
import { WrongOrderForm } from "../components/order-form";

export const DeliveredOrderPage: React.FC = () => <WrongOrderTable type="DELIVERED" />;

export const ShippedOrderPage: React.FC = () => <WrongOrderTable type="SHIPPED" />;


export const PlacedOrderPage: React.FC = () => <WrongOrderTable type="PLACED" />;

export const WrongOrderDetailsPage: React.FC = () => <WrongOrderForm />;
