import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../http";
import { WalletTransactionDto } from "../../domain";
import { } from "../mappers";
import { WalletTransactionMapper } from "../mappers/walletTransaction";

export class WalletTransactionRepo {

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<WalletTransactionDto>> {

        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = { ...buildFilterMetaToObject(request.keyword, { ...request.filters }) };
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.WALLET_TRANSACTION_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => WalletTransactionMapper.fromJson(e)) ?? [] },
                };
            });
    }

    public async getDetails(id: number): Promise<WalletTransactionDto> {
        return tawreedHttpService.post<number, JObject>(endpoints.WALLET_TRANSACTION_DETAILS, id, undefined, undefined, 'error').then(res => WalletTransactionMapper.fromJson(res.data!));
    }


}
