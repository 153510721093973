import React, { FC, useState } from "react";
import { TawreedTable, TawreedTableProps } from "../../../../common";
import { tawreedTranslationService } from "../../../../common/translations";
import { classNames } from "primereact/utils";
import { PrimeIcons } from "primereact/api";
import { CssUtils } from "../../../../helpers/css";
import { StoreDeliveryDialog } from "./store-delivery-dialog";
import { ColumnBodyOptions } from "primereact/column";
import { TawreedTableEditorCallback } from "../../../../common/components/table/editors";
import { Button } from "primereact/button";


export const StoreDeliveryMappingTable: FC<any> = (props) =>{
    const [visible, setVisible] = useState<boolean>(false);

    const hide = () =>{
        setVisible(false);
    }

    const accept = (data:{ deliveyId: number, deliveryName: string, value: string})=>{
        props.addMappingItem(data);
        hide();
    }
    const deleteBodyButton = (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {

        return <Button
            style={{ width: "60%", height: "35px" }}
            icon={PrimeIcons.TRASH}
            className="p-button-danger"
            loading={props.loading}
            onClick={() => {
                props.deleteMappingItem(rowData.deliveryId, rowData.storeDeliveryMappingId);
            }}
        >
        </Button>
    }
    const columns =[{
        dataType: 'text',
        field: "deliveryName",
        header: "lbl_store_delivery_mapping_delivery_name",
        style: CssUtils.width('25%'),
    },
        {
            dataType: 'text',
            field: "value",
            header: "lbl_store_delivery_mapping_value",
            style: CssUtils.width('10%'),
        }];
    const tawreed: TawreedTableProps={
        ds: {
            mode: 'basic',
            data: props.data ? props.data : [],
        },
        name: 'lbl_store_delivery_mapping',
        dataKey: '',
        columns: props.disabeld ? columns : [...columns,{
            dataType: 'numeric',
            field: "delete",
            body: deleteBodyButton,
            style: CssUtils.width('15%'),
        }],
        header: {
            left: {
                search: false,
                custom: (<span>
                        {tawreedTranslationService.translate(props.title)}
                    </span>
                )
            },
            right: props.disabeld ? {clear: false,custom:undefined} :{
                clear: false,
                custom: (
                    <div className="formgroup-inline">
                        <i className={classNames(PrimeIcons.PLUS, 'bouns_table_plus')} 
                        onClick={() => setVisible(true)}></i>
                    </div>
                )
            }
        }
    };
    return (
        <React.Fragment>
            <div className="col-12 md:col-12"><TawreedTable {...tawreed} /></div>
            <StoreDeliveryDialog
            visible={visible}
            hide={hide}
            store={1}
            accept={accept}
            />
        </React.Fragment>
    );
}