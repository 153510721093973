import { JObject } from "../../../../common/data/models"
import { UserDto, UserPermissions } from "../../domain";
import { DateUtils } from "../../../../helpers/date";
import { APPLICATION_LANGUAGE } from "../../../../constants/actions";
import { IRoute, Routes, routes } from "../../../../router";
import { MenuItem } from "primereact/menuitem/menuitem";
import { getMenu } from "../../../../menu";

function getUserRoutes(rr: Array<Routes>): Array<IRoute> {
    return routes.filter(route => !route.needsAuth || (rr && rr.includes(route.path)));
}

function getMatchedMenu(rr: Array<Routes>, source?: Array<MenuItem>): Array<MenuItem> {
    const result = new Array<MenuItem>();
    if (!source) {
        source = getMenu();
    }
    source.forEach(e => {
        if (e.to && rr && rr.length && rr.includes(e.to)) {
            result.push(e);
        } else if (e.items) {
            const items = getMatchedMenu(rr, e.items);
            if (items && items.length) {
                result.push({ ...e, items: items });
            }
        }
    });
    return result;
}


export const UserMapper = {

    /**
     * Create UserDto from Json object
     * @param json
     * @param permissions
     * @returns {UserDto}
     */
    fromJson: (json: JObject | undefined, permissions: UserPermissions): UserDto => {

        const aa = permissions.actions;
        const rr = getUserRoutes(permissions.routes);
        const mm = getMatchedMenu(permissions.routes);

        return {
            userId: json?.userId,
            partnerId: json?.partnerId,
            firstName: json?.firstName,
            lastName: json?.lastName,
            email: json?.email,
            locked: json?.locked,
            mobileNo: json?.mobileNo,
            sysAdmin: json?.sysAdmin,
            partnerAdmin: json?.partnerAdmin,
            prefLangCode: json?.prefLangCode ?? APPLICATION_LANGUAGE,
            startDate: json && json.startDate ? DateUtils.fromISO(json.startDate) : undefined,
            endDate: json && json.endDate ? DateUtils.fromISO(json.endDate) : undefined,
            partnerName: json?.partnerName,
            partnerActive: json?.partnerActive,
            partnerType: json?.partnerType,
            siteId: json?.siteId,
            statusId: json?.statusId,
            pictureContentId: json?.pictureContentId,
            phoneCode: json?.phoneCode,
            siteAdmin: json?.siteAdmin,
            roleCode: json?.roleCode,
            fullName: json?.fullName,
            verified: json?.verified,
            statusMessage: json?.statusMessage,
            scale: json?.scale,
            //
            routes: rr,
            menu: mm,
            actions: aa,
        };
    },

    /**
     *
     */
    toJson: (user: Partial<UserDto>): JObject => {
        return { prefLangCode: user.prefLangCode }
    },
}
