import { JObject } from "../../../../common/data/models";
import { PartnerMapper, PartnerType } from "../../../partners/data";
import { PaymentPartner } from "../../domain";

export const PaymentPartnerMapper = {

    fromJson(obj: JObject): PaymentPartner {
        return {
            paymentPartnerId: obj.paymentPartnerId,
            cashbackPercentage: obj.cashbackPercentage,
            otpLength: obj.otpLength,
            paymentPartnerTitleAr: obj.paymentPartnerTitleAr,
            paymentPartnerTitleEn: obj.paymentPartnerTitleEn,
            paymentPartnerUniqueId: obj.paymentPartnerUniqueId,
            active: obj.active,
            infoUrl: obj.infoUrl,
            transferCost: obj.transferCost, 
            transferSelling: obj.transferSelling, 
            paySellerOnDeliver: obj.paySellerOnDeliver,
            transferOnDeliver: obj.transferOnDeliver,
            ...PartnerMapper.toDto(PartnerMapper.fromJson(obj)),
            //balanceId: obj.balanceId,
        };
    },

    toJson(obj: PaymentPartner): JObject {
        return {
            paymentPartnerId: obj.paymentPartnerId,
            paymentPartnerUniqueId: obj.paymentPartnerUniqueId,
            paymentPartnerTitleAr: obj.paymentPartnerTitleAr,
            paymentPartnerTitleEn: obj.paymentPartnerTitleEn,
            transferCost: obj.transferCost,
            cashbackPercentage: obj.cashbackPercentage,
            otpLength: obj.otpLength,
            infoUrl: obj.infoUrl,
            transferSelling: obj.transferSelling, 
            paySellerOnDeliver: obj.paySellerOnDeliver,
            transferOnDeliver: obj.transferOnDeliver,
            ...PartnerMapper.toJson(PartnerMapper.fromDto({ ...obj, partnerType: PartnerType.Payment })),
        };
    }
}
