import React from 'react'
import { TawreedMenuMultiSelectProps } from '../../../../../common/components/form/controls/multiselect/multiselect-menu';
import { PartnerDto, PartnersService } from '../../../domain';
import { PartnerType } from '../../../data';
import { TawreedMultiSelect, TawreedMultiSelectProps } from '../../../../../common';


export const StoreMultiSelect: React.FC<TawreedMultiSelectProps> = (props) => {

    // di
    const service = React.useMemo(() => new PartnersService(), []);

    // state
    const [loading, setLoading] = React.useState(false)
    const [stores, setStores] = React.useState<PartnerDto[]>([]);


    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            setLoading(true);
            service.search(PartnerType.Store,'',true)
                .then(res => {
                    if (mounted) {
                        if (res && res.data && res.data.content) {
                            setStores(res.data.content);
                        }
                    }
                })
                .finally(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        }

        return () => { mounted = false };
    }, [service]);

    return (<TawreedMultiSelect {...props} disabled={loading || props.disabled} options={stores} optionLabel="partnerName" filter />)
}
