import React from "react";
import { DeliveryPartnerTable,DeliveryPartnerForm } from "../components/partners";

/**
 * This page shows all payment partners in Tawreed platform
 * @returns
 */
export const DeliveryPartnerPage: React.FC = () => <DeliveryPartnerTable />;


/**
 * This page shows details about one payment partner or create new
 * @returns
 */
export const DeliveryPartneFormPage: React.FC = () => <DeliveryPartnerForm />;


