import React, { useEffect, useState } from 'react';
import { PrimeIcons } from 'primereact/api';
import { Panel } from 'primereact/panel';
import { useHistory, useParams } from 'react-router-dom';
import { TawreedAction, TawreedActionName } from '../../../../../common/actions';
import { TawreedFormSubmitAction, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitCallback, TawreedInputSwitch, TawreedInputText, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputCustom, TawreedInputFile } from '../../../../../common/components/form';
import { tawreedTranslationService } from '../../../../../common/translations';
import { Routes } from '../../../../../router';
import { ProductDto, ProductsService } from '../../domain';
import { CategoriesDropdown } from './categories-dropdown';
import { ProductDynamicField } from './product-dynamic-field';
import { GlobalContext } from '../../../../../context';

export const ProductForm: React.FC = () => {
    const { id } = useParams<{ id: string | undefined }>();
    // di
    const { constants: { constants } } = React.useContext(GlobalContext);
    const service: ProductsService = React.useMemo(() => new ProductsService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<ProductDto>({});
    const [displayAtt, setDisplayAtt] = useState([
        { title: 'BRAND', value: '' },
        { title: 'productName', value: '' },
        { title: 'DRG_CONCENTRATE', value: "" },
        { title: 'DRG_FILLING', value: "" },
        { title: 'DOSAGE', value: "" }])
    const [displayname, setDisplayname] = useState("")
    const [productName, setProductName] = useState("")
    const [change, setChange] = useState(false);
    const buildDisInit = (res: ProductDto) => {
        const elements = [];
        const fields = constants?.productCategoriesFields?.find(e => e.categoryId === res.categoryId)?.fields ?? [];
        elements.push({ title: 'BRAND', value: res?.fields?.find(el => el.fieldId === fields.find(el1 => el1.fieldName === 'BRAND')?.fieldId)?.value ?? "" })
        elements.push({ title: 'productName', value: res?.productName ?? "" })
        elements.push({ title: 'DRG_CONCENTRATE', value: res?.fields?.find(el => el.fieldId === fields.find(el1 => el1.fieldName === 'DRG_CONCENTRATE')?.fieldId)?.value ?? "" })
        elements.push({ title: 'DRG_FILLING', value: res?.fields?.find(el => el.fieldId === fields.find(el1 => el1.fieldName === 'DRG_FILLING')?.fieldId)?.value ?? "" })
        elements.push({ title: 'DOSAGE', value: res?.fields?.find(el => el.fieldId === fields.find(el1 => el1.fieldName === 'DOSAGE')?.fieldId)?.value ?? "" })
        setDisplayAtt([...elements]);
    }
    const buildDisplayName = () => {
        const concatenatedValues = displayAtt.reduce((accumulator, currentValue) => {
            return accumulator + ((accumulator && currentValue.value) ? " " : "") + currentValue.value.trim();
        }, "");
        setDisplayname(concatenatedValues);
    }
    const changeDisplayArr = (element: { title: string, value: string }) => {
        const elements = displayAtt.map(el => {
            if (el.title === element.title) {
                if (!change)
                    setChange(true);
                return { title: el.title, value: element.value };
            }
            else
                return el;
        })
        setDisplayAtt([...elements]);
        if (element.title === 'productName')
            setProductName(element.value);
    }

    useEffect(() => {
        if (change)
            buildDisplayName();
    }, [displayAtt, change])

    const history = useHistory();

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {

                    if (mounted) {
                        setLoading(false);
                        setData(res);
                        buildDisInit(res);
                        setDisplayname(res.displayName ?? "");
                        setProductName(res.productName ?? "");
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {

            if (mounted) {
                setMode('Create');
                setData({ active: true });
                buildDisplayName();
            }
        }
        return () => {
            mounted = false;
        };
    }, [id, service]);

    const onCreateOrSave = (data: ProductDto, cb: TawreedFormSubmitCallback<ProductDto>): Promise<void> => {
        setLoading(true);
        return service.createOrUpdate({ ...data, displayName: displayname, productName: productName })
            .then(res => {
                setLoading(false);
                if (!data.productId && res.productId) {
                    history.replace(Routes.ProductsDetails.replace(':id', res.productId.toString()));
                }
                cb(res);
            })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    }

    const onDelete = () => {
        setLoading(true);
        return service.delete(data.productId!).then(res => {
            setLoading(false);
            if (res) {
                history.replace(Routes.ProductsSearch);
            }
        }).catch(err => {
            setLoading(false);
            throw err;
        });
    }
    const createNew = () => {
        return new Promise<void>((res, rej) => {
            history.push(Routes.ProductsNew)
            res()
        })
    }


    const onSubmit: TawreedFormSubmitProps<ProductDto> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<ProductDto>(TawreedActionName.ProductsSubmit, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave),
    };

    const actions = [
        new TawreedAction(TawreedActionName.ProductsDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete'),
    ];
    if (id && actions.length === 1) {
        actions.push(new TawreedAction(TawreedActionName.ProductsSubmit, "statefull", "lbl_new", PrimeIcons.PLUS, createNew))
    }

    return (
        <React.Fragment>
            <TawreedForm title="lbl_product"
                dataKey="productId"
                data={data}
                submit={onSubmit}
                loading={loading} mode={mode}
                useBack=".." useReset actions={actions} className="grid form-grid">

                <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                    <div className="grid">

                        <TawreedFormField className="field col-12 md:col-6" name="categoryId" title="lbl_product_category">
                            <CategoriesDropdown name="categoryId" render="form" rules={{ required: 'msg_field_is_required' }} />
                        </TawreedFormField>

                        <TawreedFormField name="active" title="lbl_product_active" className="field-checkbox col-12 md:col-6" flip>
                            <TawreedInputSwitch name="active" render="form" />
                        </TawreedFormField>

                        <TawreedFormField className="field col-12 md:col-6" name="productName" title="lbl_product_name">
                            <TawreedInputText

                                name="productName" value={productName ?? ""} rules={{ 
                                    required: 'msg_field_is_required',
                                    validate: (value) => { 
                                        var trim_value= value.trim();
                                        if(trim_value.length ==0)
                                        {
                                            return tawreedTranslationService.translate('lbl_whiteSapce_field');
                                        }

                                    }
                                }} render="form"
                                onChange={(e) => { changeDisplayArr({ title: "productName", value: e.target.value.trim() }) }}
                            />
                        </TawreedFormField>

                        <TawreedFormField className="field col-12 md:col-6" name="productNameEn" title="lbl_product_name_en">
                            <TawreedInputText name="productNameEn" rules={
                                            { 
                                                required: 'msg_field_is_required',
                                                validate: (value) => { 
                                                    var trim_value= value.trim();
                                                    if(trim_value.length ==0)
                                                    {
                                                        return tawreedTranslationService.translate('lbl_whiteSapce_field');
                                                    }

                                                }
                                            }} render="form" />
                        </TawreedFormField>
                        <TawreedFormField className="field col-12 md:col-6" name="internationalCode" title="lbl_product_international_code">
                            <TawreedInputText name="internationalCode" rules={{ required: 'msg_field_is_required' }} render="form" />
                        </TawreedFormField>

                        <TawreedFormField className="field col-12 md:col-6" name="productTax" title="lbl_product_product_tax">
                            <TawreedInputNumber mode="decimal" name="productTax" rules={{ required: 'msg_field_is_required' }} render="form" />
                        </TawreedFormField>

                        <TawreedFormField className="field col-12 md:col-6" name="price" title="lbl_product_price">
                            <TawreedInputNumber mode="decimal" name="price" rules={{ required: 'msg_field_is_required' }} render="form" />
                        </TawreedFormField>

                        <TawreedFormField className="field col-12 md:col-6" name="keywords" title="lbl_product_keywords">
                            <TawreedInputText name="keywords" render="form" />
                        </TawreedFormField>

                        <TawreedFormField className="field col-12 md:col-6" name="company" title="lbl_company">
                            <TawreedInputText name="company" render="form" />
                        </TawreedFormField>

                        <TawreedFormField className="field col-12 md:col-6" name="companyEn" title="lbl_company_en">
                            <TawreedInputText name="companyEn" render="form" />
                        </TawreedFormField>

                        <TawreedInputCustom render="form" name="categoryId" renderer={(c: any) => {
                            const fields = constants?.productCategoriesFields.find(e => e.categoryId === c)?.fields;
                            return (<React.Fragment>
                                {
                                    fields &&
                                    fields.map((field, index) => <ProductDynamicField key={index} onChange={(e) => {
                                        changeDisplayArr({ title: field.fieldName, value: e.target.value })
                                    }} index={index} field={field} />)
                                }
                            </React.Fragment>)

                        }} />
                        <TawreedFormField hint={tawreedTranslationService.translate('lbl_diaplay_name_formula')} className="field col-12 md:col-12" name="displayName" title="lbl_displayName">
                            <TawreedInputText value={displayname} onChange={(e) => { setDisplayname(e.target.value) }} rules={{ required: 'msg_field_is_required' }} name="displayName" render="standalone" />
                        </TawreedFormField>
                        <div className="col-12">
                            <div className="grid">
                                <TawreedFormField name="image" className="field col-12 md:col-3" title="lbl_product_images">
                                    <TawreedInputFile render="form" name="image" accept="image/*" />
                                </TawreedFormField>
                            </div>
                        </div>

                    </div>
                </Panel>
            </TawreedForm>
        </React.Fragment>
    );
}
