import { PrimeIcons } from 'primereact/api';
import React, { FC, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { TawreedAction, TawreedActionName } from '../../../../../common/actions';
import { TawreedTable, TawreedTableProps } from '../../../../../common/components/table';
import { TawreedTableFilters } from '../../../../../common/components/table/filters';
import { Routes } from '../../../../../router';
import { Product } from '../../data';
import { ProductsService } from '../../domain';
import { CssUtils } from "../../../../../helpers/css";
import { buildCategoriesTawreedTableFormatter } from './categories-table-formatter';
import { AuthUtils } from '../../../../auth/domain';
import { GlobalContext } from '../../../../../context';
import { Button } from 'primereact/button';
import { tawreedTranslationService } from '../../../../../common/translations';
import { VerifyProductDialog } from './verify-product-dialog';
import { Tag } from 'primereact/tag';
import { toastService } from '../../../../notifications/domain';


export const ProductsTable: FC = (_props) => {

    const history = useHistory();
    const { auth: { user }, constants: { constants } } = useContext(GlobalContext);
    const service: ProductsService = new ProductsService();
    const [reload, setReload] = useState(false);
    const [loader, setLoader] = useState(false);
    const [changeCat, setChangeCat] = useState<{ productIds: Array<number>, isOpen: boolean }>({
        productIds: [],

        isOpen: false,
    })
    const ProdcutStatus = [
        {
            value: true,
            label: tawreedTranslationService.translate("lbl_product_verified")
        },
        {
            value: false,
            label: tawreedTranslationService.translate("lbl_product_unverified")
        }];

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.ProductsNew);
            resolve();
        })
    }

    const onForm = (sender?: Product): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.ProductsDetails.replace(':id', sender!.productId.toString()));
            resolve();
        })
    }

    const onDelete = (sender?: Product | Array<Product>): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!sender) {
                reject('No item selected');
                return;
            }
            if (Array.isArray(sender)) {
                setReload(false);
                return service.deleteAll(sender.map(e => e.productId)).then(() => setReload(true)).catch(err => console.error(err));
            } else {
                setReload(false);
                return service.delete(sender.productId).then(() => setReload(true)).catch(err => console.error(err));
            }
        })
    };
    const onChangeCategory = (sender?: Product | Array<Product>): Promise<void> => {

        return new Promise((resolve, reject) => {

            if (Array.isArray(sender)) {
                if (sender.filter(x => !x.isVerified).length > 0) {
                    toastService.show({ detail: tawreedTranslationService.translate('msg_update_unverified_category'), severity: 'error' });
                    return;
                }
                setChangeCat({
                    productIds: sender.map(el => el.productId),
                    isOpen: true,
                })
            }
            else {
                setChangeCat({
                    productIds: [],
                    isOpen: false,
                })
            }
            resolve();

        })
    };
    const changeCategory = (categoryId: number) => {
        setReload(false);
        setLoader(true);
        service.changeCategory(changeCat.productIds, categoryId)
            .then(res => {
                setChangeCat({ isOpen: false, productIds: [] })
                setReload(true);
                setLoader(false);

            })
            .catch(err => {
                setChangeCat({ isOpen: false, productIds: [] })
                setReload(true);
                setLoader(false);

            })
    };

    const tawreed: TawreedTableProps = {
        reload: reload,
        header: true,
        pagination: true,
        dataKey: 'productId',
        name: 'tbl.products',
        title: 'lbl_products',
        outLoader: loader,
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.search(q),
        },
        columns: [
            {
                field: "productId",
                header: "lbl_product_id",
                sortable: true,
                filter: true,
                style: CssUtils.width('10%'),
            },
            {
                field: "internationalCode",
                header: "lbl_product_international_code",
                sortable: true,
                filter: true,
                style: CssUtils.width('10%'),
            },
            {
                field: "displayName",
                header: "lbl_displayName",
                sortable: true,
                filter: true,
                style: CssUtils.width('15%', CssUtils.color('var(--danger-color)')),
                bodyStyle: {'direction':'ltr'}
            },
            {
                field: "categoryId",
                header: "lbl_product_category",
                sortable: true,
                filter: true,
                style: CssUtils.width('10%'),
                ...buildCategoriesTawreedTableFormatter('categoryId', constants?.productCategories ?? []),
            },
            {
                field: "productTax",
                header: "lbl_tax",
                sortable: true,
                filter: true,
                style: CssUtils.width('5%'),
            },
            {
                field: "sellerCount",
                header: "lbl_product_seller_count",
                sortable: true,
                filter: true,
                style: CssUtils.width('5%'),
            },
            {
                field: "active",
                dataType: 'boolean',
                header: "lbl_active",
                ...TawreedTableFilters.boolean("active"),
                style: CssUtils.width('5%'),
            },
            {
                field: "isVerified",
                header: "lbl_product_verified",
                filter: true,
                sortable: true,
                filterField: "isVerified",
                sortField: "isVerified",
                ...TawreedTableFilters.dropdown.dropdown("isVerified", ProdcutStatus ?? [], 'value', 'label', 'equals', (label: string, value: any) => <Tag rounded severity={value ? "primary" : "warning"} style={{ width: '6rem' }} value={label} />),
                style: CssUtils.width('5%'),
            },
            {
                field: "actions",
                body: (options: any) => {
                    return <React.Fragment>
                        <Button
                            type="button"
                            style={{ width: "40%", height: "35px" }}
                            icon={PrimeIcons.EYE}
                            className="p-button-success p-button-outlined"
                            tooltip={tawreedTranslationService.translate('lbl_info')}
                            onClick={() => {
                                if (options.isVerified)
                                    history.push(Routes.ProductsDetails.replace(':id', options!.productId.toString()));
                                else
                                    history.push(Routes.UnverifiedProductsDetails.replace(':id', options!.productId.toString()));
                            }} />
                            {
                                !(user?.roleCode ==='FINANCE') &&  <Button
                                type="button"
                                style={{ width: "40%", height: "35px" }}
                                icon={PrimeIcons.CHEVRON_RIGHT}
                                className="p-button-secondary p-button-outlined ml-1"
                                tooltip={tawreedTranslationService.translate('lbl_store_products')}
                                onClick={() => {
                                    history.push(`${Routes.SellerStoreProductSearch.replace(':id', options!.productId.toString())}?title=${options.displayName} - ${options.price}`);
                                }} />
                            }
                       
                    </React.Fragment>
                },
                style: CssUtils.width('20%'),
            }
        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.ProductsCreate, 'stateless', 'lbl_new', PrimeIcons.PLUS, onNew),
                new TawreedAction(TawreedActionName.ProductsDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete'),
                new TawreedAction(TawreedActionName.ProductsCreate, 'statefull', (e) => tawreedTranslationService.translate('lbl_cahnge_category') + " (" + (e?.length ?? 0) + ")", PrimeIcons.SAVE, onChangeCategory, 'p-button-primary'),

            ],
        },
        row: {
            // onClick: new TawreedAction(TawreedActionName.ProductsDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onForm, 'p-button-secondary', false),
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'isVerified': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]

                },
                'productId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'productNameEn': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'displayName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'internationalCode': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'active': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        }
                    ]
                },
                'categoryId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        }
                    ]
                },
                'sellerCount': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        }
                    ]
                },
                'productTax': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        }
                    ]
                }

            },
            pre: [
                {
                    name: 'lbl_products_active',
                    meta: {
                        'active': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: true,
                                    matchMode: 'equals',
                                }
                            ]
                        },
                    },
                },
                {
                    name: 'lbl_products_not_active',
                    meta: {
                        'active': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: false,
                                    matchMode: 'equals',
                                }
                            ]
                        },
                    },
                }
            ]
        },
        sort: {
            sortMode: 'multiple',
            initial: {
                sortField: 'displayName',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
            
        },
    }

    return (
        <>
            <TawreedTable  {...tawreed} />
            {changeCat.isOpen && <VerifyProductDialog visible={changeCat.isOpen} hide={() => setChangeCat({
                isOpen: false,
                productIds: [],
            })} accept={changeCategory} ></VerifyProductDialog>}
        </>
    );
}
