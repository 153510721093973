import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { PartnerMapper } from "../../../partners/data";
import { PaymentTransaction } from "../../domain";
import { PaymentCommunicationMapper } from "./communication";

export const PaymentTransactionMapper = {

    fromJson(obj: JObject): PaymentTransaction {
        return {
            paymentTransactionId: obj.paymentTransactionId,
            sellerPartner: PartnerMapper.toDto(PartnerMapper.fromJson({
                partnerId: obj.sellerId,
                partnerName: obj.sellerName,
            })),
            buyerPartner: PartnerMapper.toDto(PartnerMapper.fromJson({
                partnerId: obj.buyerId,
                partnerName: obj.buyerName,
            })),
            paymentPartner: PartnerMapper.toDto(PartnerMapper.fromJson({
                partnerId: obj.paymentPartnerId,
                partnerName: obj.paymentPartnerName,
            })),
            amount: obj.amount,
            mawridUniqueReference: obj.mawridUniqueReference,
            otpTrials: obj.otpTrials,
            partnerUniqueReference: obj.partnerUniqueReference,
            paymentOrderId: obj.paymentOrderId,
            siteId: obj.siteId,
            state: obj.state,
            status: obj.status,
            type: obj.type,
            createdAt: DateUtils.fromISO(obj.createdAt),
            updatedAt: DateUtils.fromISO(obj.updatedAt),
            paymentCommunications: obj.paymentCommunications?.map((el:JObject)=>{
                return PaymentCommunicationMapper.fromJson(el);
            })
        };
    },
}