import React from "react";
import { DashboardItemValue, OrdersChartDto } from "../../domain";
import { tawreedTranslationService } from "../../../../common/translations";
import { Chart } from "primereact/chart";
import { Skeleton } from "primereact/skeleton";
import { Card } from "primereact/card";
import { AuthUtils } from "../../../auth/domain";


declare type DashboardOrdersChartProps = {

    /**
     *
     */
    className: string;

    /**
     *
     */
    loading: boolean;

    /**
     * counts
     */
    counts: OrdersChartDto | undefined;

    calculator: (value: DashboardItemValue) => string;
}

export const DashboardOrdersChart: React.FC<DashboardOrdersChartProps> = (props) => {

    const chartData = {
        labels: props.counts?.data.map(e => tawreedTranslationService.translate(e.label)),
        datasets: [
            {
                data: props.counts?.data.map(e => props.calculator(e.value)),
                backgroundColor: props.counts?.data.map(e => e.colors.bgColor),
                hoverBackgroundColor: props.counts?.data.map(e => e.colors.hoverBgColor),
                weight: .5,
            },
        ]
    };

    const lightOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: props.counts?.total.value ? tawreedTranslationService.translate(props.counts?.total.label, props.calculator(props.counts?.total.value)) : '',
            },
            legend: {
                position: 'left',
                align: 'center',
                labels: {
                    font: {
                        family: AuthUtils.current().language === 'ar' ? '--arabic-font-family' : 'var(--font-family)'
                    },
                    color: '#495057'
                }
            },
        }
    };

    return (
        <Card className={"card " + props.className} header={tawreedTranslationService.translate('lbl_dashboard_orders_chart_title')}>
            {
                (props.loading || !props.counts) ? <Skeleton /> : <Chart id="tawreed_dashboard_orders_chart" key="tawreed_dashboard_orders_chart" type="doughnut" height="21rem" data={chartData} options={lightOptions} style={{ width: '100%' }} />
            }
        </Card>
    )
}
