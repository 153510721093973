import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { PartnerMapper } from "../../../partners/data";
import { WrongOrder } from "../../domain";
import { PaymentTransactionMapper } from "./transaction";

export const WrongorderMapper = {

    fromJson(obj: JObject): WrongOrder {
        
        return {
            sellerPartner: PartnerMapper.toDto(PartnerMapper.fromJson({
                partnerId: obj.storeId,
                partnerName: obj.store,
            })),
            buyerPartner: PartnerMapper.toDto(PartnerMapper.fromJson({
                partnerId: obj.customerId,
                partnerName: obj.customer,
            })),
            orderId : obj.orderId,
            paymentOrderAmount: obj.paymentOrderAmount!,
            paymentOrderId: obj.paymentOrderId!,
            paymentOrderStatus: obj.paymentOrderStatus!,
            createdAt: DateUtils.fromISO(obj.createdAt),
            updatedAt: DateUtils.fromISO(obj.updatedAt),
            total: obj.total,
            transactions: obj.transactions?.map((el:JObject)=>{
                return PaymentTransactionMapper.fromJson(el);
            }),
            blockAmountTrials: obj.blockAmountTrials!,
            cartId: obj.cartId!,
            orderPaymentOrderId: obj.orderPaymentOrderId,
            orderTotal: obj.orderTotal
        };
    },
}