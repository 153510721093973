import { PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { BrokenOrderRepository } from "../../data";
import { BrokenOrder, PaymentTransaction } from "../dtos";

export class BrokenOrderService {

    private readonly repository;

    constructor() {
        this.repository = new BrokenOrderRepository();
    }

    public search(req: PaginatedRequestParams): Promise<PaginatedResponse<BrokenOrder>> {
        return this.repository.search(req);
    }

    public resolve(id: number): Promise<boolean> {
        return this.repository.resolve(id);
    }

    // /**
    //  * Get partner details by id
    //  * @param id
    //  */
    // public getDetails(id: number): Promise<PaymentTransaction> {
    //     return this.repository.getDetails(id);
    // }
}