import { TawreedMenu } from "../modules/constants";

export const ObjectUtils = {
    

    getNested: (obj: any, nested: string): any => {
        if (!obj || !nested) {
            return undefined;
        }
        try {
            nested.split('.').forEach(e => obj = obj[e]);
        } catch {
        }
        return obj;
    },

    toFixed(obj: number | string | undefined, scale: number | undefined, alt?: number): string {
        const formatter = Intl.NumberFormat(undefined, { maximumFractionDigits: scale ?? 3, minimumFractionDigits: scale ?? 3 });
        if (obj === undefined || obj === null) {
            if (alt !== undefined) {
                return formatter.format(alt);
            }
            return '';
        }
        if (typeof obj === 'string') {
            obj = Number.parseFloat(obj);
        }
        return formatter.format(obj);
    },
    toLocalString(obj: number | string | undefined, scale: number | undefined, alt?: number): string {
        if (obj === undefined || obj === null) {
            if (alt !== undefined) {
                return alt.toLocaleString();
            }
            return '';
        }
        return (typeof obj === 'string' ? Number.parseFloat(obj) : obj).toLocaleString(undefined, { maximumFractionDigits: scale ?? 3, minimumFractionDigits: scale ?? 3 });
    },
    toDiscount(discountType: string, lang = "en") {
        if (discountType === 'fixed')
            return lang === "en" ? "JOD " : "د.أ "
        if (discountType === 'percentage')
            return "% "
        if (discountType === 'bonus')
            return "%+ "

    },


    toCurrency(obj: number | string | undefined, scale: number | undefined, alt?: number): string {
        if (obj === undefined || obj === null) {
            if (alt !== undefined) {
                return alt.toFixed(scale);
            }
            return '';
        }
        if (typeof obj === 'string') {
            obj = Number.parseFloat(obj);
        }
        return Intl.NumberFormat(undefined, { maximumFractionDigits: scale ?? 3, minimumFractionDigits: scale ?? 3 }).format(obj);
    },

    fromMenu(value?: number, menu?: TawreedMenu): string | undefined {
        if (!value) {
            return '';
        }
        else if (!menu) {
            return value.toString();
        }
        return (menu.find(e => e.value === value) || { label: value.toString() }).label;
    },

    clone: (obj: any, nested: string, value: any): any => {
        if (!obj || !nested) {
            return undefined;
        }
        let res = obj;
        try {
            for (let i = 0; i < nested.length; i++) {
                if (i === nested.length - 1) {
                    res = value;
                } else {
                    res = res[nested[i]];
                }
            }
        } catch {
        }
        return obj;
    },

    asTawreedMenu(obj: any): TawreedMenu | undefined {
        if (Array.isArray(obj)) {
            return obj;
        }
        return undefined
    },

    random(length: number): string {
        const letters = 'ABCDEFGHIJKLMOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let res = '';

        for (let i = 0; i < length; i++) {
            res += letters[Math.floor(Math.random() * letters.length)];
        }
        return res;
    },

    MAX_INT: 2147483647,
}
