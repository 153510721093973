import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { setFocus, TawreedForm, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps } from "../../../../../common/components/form";
import { CustomerCartDto, CartItemDto, CartService, CustomerStoreCartDto, CustomerStoreCartItemDto } from "../../domain";
import { CartFormProducts } from "./cart-form-products";
import {CarFormSalesOffer} from "./cart-form-sales-offer";
import { CartFormCheckoutDialogMessage } from "./cart-form-checkout-dialog-message";
import { CartFormStoreCart } from "./old-cart-form-store-cart";
import { CartFormCustomer } from "./cart-form-customer";
import { PartnerDto } from "../../../../partners/domain";
import { Routes } from "../../../../../router";
import { StoreProduct } from "../../../../catalog/store-products/data";
import { TawreedActionName } from "../../../../../common/actions";
import { PrimeIcons } from "primereact/api";
import { startTawreedDialog } from "../../../../../common/dialog";
import { GlobalContext } from "../../../../../context";
import { tawreedTranslationService } from "../../../../../common/translations";
import { CartFormStatusMessage } from "./cart-form-status-message";
import {TabView, TabPanel} from "primereact/tabview";
import { Panel } from "primereact/panel";
import './cart-form.scss';
import { salesOfferDto,CartSalesOfferDto } from "../../domain";
import { AuthUtils } from "../../../../auth/domain";

export const NewCartForm: React.FC = () => {
    const { id } = useParams<{ id: string | undefined }>();
    // di
    const { auth: { user } } = React.useContext(GlobalContext);
    const service: CartService = React.useMemo(() => new CartService(), []);

    // states
    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [form, setForm] = React.useState<CartItemDto>();
    const [cart, setCart] = React.useState<CustomerCartDto>();
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [panelToggel, setPanelToggel] = React.useState<boolean>(true)


    const history = useHistory();
    let priceListId = new URLSearchParams(history.location.search).get("priceListId");
    let salesOfferId = new URLSearchParams(history.location.search).get("salesOfferId");

    const getCart = (loader: boolean, customerId: string | undefined, getCustomer: boolean, part?: Partial<CartItemDto>): Promise<CartItemDto> => {
        if (loader) {
            setLoading(true);
        }
        if (customerId) {
            return service.getDetails(Number.parseInt(customerId), getCustomer, priceListId,salesOfferId)
                .then(res => {
                    const partner = res.customer ?? form?.customer;
                    const nextForm = { ...form, ...part, customer: partner };
                    res.customer = partner;
                    setCart(res);
                    setForm(nextForm);
                    return nextForm;
                })
                .finally(() => {
                    if (loader) {
                        setLoading(false);
                    }
                });
        }
        return Promise.reject('ID_IS_UNDEFINED');
    }

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                getCart(true, id, true).then();
            }
        } else {
            if (mounted) {
                setMode('Create');
                setCart({});
                setForm({});
            }
        }
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, service]);

    const onSubmitExecute = async (data: CartItemDto, cb: TawreedFormSubmitCallback<CartItemDto>): Promise<void> => {
        // setLoading(true);
        //
        console.log(data);
        // cause in customer role customer section is hidden, so controls won't be registered
        
        if (!data.customer && cart && cart.customer) {
            data.customer = cart.customer;
        }
        if(data.salesOfferId != null){

            return service.addOfferItem(data)
            .then(async (res) => {
                if (res && res === data.customer!.partnerId) {
                    const next = await getCart(false,id ,false);
                    //setForm(next);
                    //setForm({});
                    setForm({...next,salesOfferId:undefined, storeProduct:undefined});
                    cb(next);
                    // setLoading(false);
                    setFocus('storeProduct');
                    return;
                }
                cb(new Error('UN_SUCCESSFUL_ADD_ITEM_PROCESS'));
            })
            .catch((err) => {
                // setLoading(false);
                cb(err);
            });
        }
        else{

            return service.addItem(data)
            .then(async (res) => {
                if (res && res === data.customer!.partnerId) {
                    const next = await getCart(false, id, false);
                    setForm({...next,salesOfferId:undefined, storeProduct:undefined});
                    //setForm({});
                    cb(next);
                    // setLoading(false);
                    setFocus('storeProduct');
                    return;
                }
                cb(new Error('UN_SUCCESSFUL_ADD_ITEM_PROCESS'));
            })
            .catch((err) => {
                // setLoading(false);
                cb(err);
            });
        }
    }
    const onSubmit: TawreedFormSubmitProps<CartItemDto| CartSalesOfferDto> = {
        showButton: false,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<CartItemDto|CartSalesOfferDto>(TawreedActionName.CartsUpdate, 'lbl_save', PrimeIcons.SAVE, onSubmitExecute)
    };

    const onCustomerSelect = (customer: PartnerDto) => {
        if (customer.partnerId) {
            history.replace(Routes.SalesCartsDetails.replace(':id', customer.partnerId.toString()));
        }
    }

    const onStoreProductSelect = (product: StoreProduct) => {
        return setForm({ ...form, storeProduct: product, priceListId: product.priceListId });
    }
    const onPackageSelect = (packageInfo: salesOfferDto) => {
        return setForm({ ...form, priceListId: packageInfo.priceListId,quantity:1,salesOfferId:packageInfo.salesOfferId });
    }


    const onCheckoutAll = (data?: any): void => {
        if (data.cartId) {
            let selectedCart = cart?.carts?.find(e => e.cartId === data.cartId)
            let msg = data.checkoutMode === 1 ? tawreedTranslationService.translate('lbl_checkout_confirm_meesage_1') : tawreedTranslationService.translate('lbl_checkout_confirm_meesage');
            const invalid = cart?.carts ? Array.from(cart.carts).filter(e => (!e.showPlaceOrder && e.cartId === data.cartId)) : undefined;
            console.log(selectedCart);
            startTawreedDialog({
                message: invalid && invalid.length ? <CartFormCheckoutDialogMessage invalid={invalid} /> : msg,
                accept: () => {
                    setLoading(true);
                    if (selectedCart) {
                        return service.checkoutAll({ carts: [selectedCart] }, data)
                            .then(() => {
                                setLoading(false);
                                history.goBack();
                            })
                            .catch(() => setLoading(false));
                    }
                }
            })
        }

    }

    const onDelete = (cart: CustomerStoreCartDto): Promise<void> => {
        setLoading(true);
        return service.deleteCart(cart)
            .then(async () => {
                await getCart(false, id, false);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }

    const onDeleteItem = (item: CustomerStoreCartItemDto): Promise<void> => {
        console.log(item);
        setLoading(true);
        return service.deleteItem(item)
            .then(async () => {
                await getCart(false, id, false);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const onSave = (item: CustomerStoreCartItemDto): Promise<boolean> => {
        setLoading(true);
        return service.updateItem(item)
            .then(async (res) => {
                await getCart(false, id, false);
                setLoading(false);
                return true;
            })
            .catch(() => {
                setLoading(false);
                return false;
            });
    }

    const changeQuantity = (quantity: number, bonusType: string) => {
        setForm({ ...form, quantity: quantity, bonusType: bonusType });
    }
    return (
        <TawreedForm
            className="grid form-grid cart-form"
            title={tawreedTranslationService.translate('lbl_sales_cart', cart?.customer?.partnerName ? ` - ${cart.customer.partnerName}` : '')} loading={loading} mode={mode}
            dataKey="cartId" data={form}
            submit={onSubmit}
            useBack={user && user.roleCode !== 'CUSTOMER_ADMIN' ? '..' : undefined}>
            {
                cart &&
                !cart.valid &&
                cart.message &&
                cart.itemsUpdated &&
                <CartFormStatusMessage message={cart.message} itemsUpdated={cart.itemsUpdated} />
            }
            {
                (user && user.roleCode !== 'CUSTOMER_ADMIN') && <CartFormCustomer initial={cart?.customer} onSelect={(e) => onCustomerSelect(e)} disabled={!!(loading || id || cart?.orderTotals)} />
            }
            {
                cart &&
                cart.customer &&
                cart.customer.zoneId &&
                salesOfferId==null &&
                priceListId==null &&
                <Panel className="col-12" header={tawreedTranslationService.translate('lbl_sales_cart_products')} >
                <CartFormProducts customerId={Number.parseInt(id!)} changeQuantity={changeQuantity} priceListId={priceListId} actor={cart.customer} zoneId={cart.customer.zoneId} onSelect={onStoreProductSelect} disabled={loading || !form || !form.customer} className="col-12" />
                </Panel>
                // <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}  className="col-12">
                //     <TabPanel header={tawreedTranslationService.translate('lbl_sales_cart_products')} >
                //         <CartFormProducts customerId={Number.parseInt(id!)} changeQuantity={changeQuantity} priceListId={priceListId} actor={cart.customer} zoneId={cart.customer.zoneId} onSelect={onStoreProductSelect} disabled={loading || !form || !form.customer} className="col-12" />
                //     </TabPanel>
                //     <TabPanel header={tawreedTranslationService.translate('lbl_sales_offer_header')} >
                //         <CarFormSalesOffer actor={cart.customer} zoneId={cart.customer.zoneId} onSelect={onPackageSelect} disabled={loading || !form || !form.customer} className="col-12"/>
                //     </TabPanel>
                // </TabView>
            }
            {
                cart &&
                cart.customer &&
                cart.customer.zoneId &&
                salesOfferId==null &&
                priceListId != null &&
                AuthUtils.current().authorized(TawreedActionName.CartsUpdate) &&
                <Panel toggleable={true} collapsed={panelToggel} onToggle={(e) => setPanelToggel(e.value)} header={tawreedTranslationService.translate('lbl_sales_cart_products')} className={'cart-products col-12'}>
                    <CartFormProducts customerId={cart.customer.partnerId!} changeQuantity={changeQuantity} priceListId={priceListId} actor={cart.customer} zoneId={cart.customer.zoneId} onSelect={onStoreProductSelect} disabled={loading || !form || !form.customer} className="col-12" />
                </Panel>
            }
            {cart && cart.carts && priceListId && cart.carts.map((cartI, i) => <CartFormStoreCart salesOfferId={salesOfferId} priceListId={+priceListId!} priceListName={cart.priceListName || ""} key={i + ''} className="col-12" cart={cartI} onDelete={onDelete} onDeleteItem={onDeleteItem} onSave={onSave} disabled={user?.roleCode === 'STORE_SALES_MAN' && user?.userId !== cartI.createdBy ? true : loading} onCheckout={onCheckoutAll} />)}
            {/* {cart && !!(cart.total) && priceListId&& <CartFormSummary className="col-12" cart={cart} onCheckout={onCheckoutAll} disabled={loading || !cart.showPlaceAllOrders} />} */}
        </TawreedForm>
    );
}
