import React from "react";
import { PrimeIcons } from "primereact/api";
import { TawreedFormField } from "../../../../common/components/form";
import { tawreedTranslationService } from "../../../../common/translations";
import { PartnerAutoCompleteComponent } from "../../../partners";
import { StoreZonesService } from "../../domain";
import { PartnerType } from "../../../partners/data";
import { ZonesMultiSelect } from "../../../zones";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { PartnerDto } from "../../../partners/domain/dtos";
import { Zone } from "../../../zones/domain";

export interface StoreZoneFormProps {


    visible: boolean;

    /**
     * 
     */
    onHide: (ok: boolean) => void;
}

export interface StoreZoneFormState {

    store: PartnerDto;
    zones: Array<Zone>;
}

export const StoreZoneForm: React.FC<StoreZoneFormProps> = (props) => {
    // di
    const service: StoreZonesService = React.useMemo(() => new StoreZonesService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<StoreZoneFormState>>({});

    const onCreateOrSave = (): void => {
        if (data && data.store && data.zones && data.zones.length) {
            setLoading(true);
            service.createOrUpdate({ store: data.store, zones: data.zones })
                .then(res => {
                    setLoading(false);
                    if (res) {
                        onHide(false);
                    }
                })
                .catch(err => {
                    setLoading(false);
                });
        }
    }

    const canSubmit = data && data.store && data.zones && data.zones.length;

    const DialogFooter = () => {
        return (
            <div>
                <Button label={tawreedTranslationService.translate('lbl_close')} icon={PrimeIcons.TIMES} onClick={() => onHide(false)} className="p-button-text" />
                <Button label={tawreedTranslationService.translate('lbl_okay')}
                    icon={PrimeIcons.CHECK}
                    loading={loading}
                    onClick={() => onCreateOrSave()}
                    disabled={!canSubmit || loading} />
            </div>
        );
    }


    const onHide = (res: boolean) => {
        if (props.onHide) {
            props.onHide(res);
        }
    }

    return (
        <Dialog visible={props.visible} onHide={() => onHide(false)} onShow={() => { setData({ store: undefined, zones: undefined }) }}
            header={tawreedTranslationService.translate('lbl_store_zones')} footer={<DialogFooter />}
            position="top" breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '33vw' }} draggable={false} closable={false} focusOnShow closeOnEscape={false}>

            <div className="form-grid grid">
                <TawreedFormField name="store" title="lbl_store_zones_store" className="field col-12">
                    <PartnerAutoCompleteComponent partnerType={PartnerType.Store} autoCompleteRules={{ required: 'msg_field_is_required' }} autoCompleteName="store" autoCompleteField="partnerName" autoCompleteRender="standalone" autoCompleteValue={data.store} autoCompleteOnChange={e => setData({ ...data, store: e.target.value })} />
                </TawreedFormField>

                <TawreedFormField name="zone" className="field col-12" title="lbl_zones_name">
                    <ZonesMultiSelect name="zone" render="standalone" value={data.zones} onChange={e => setData({ ...data, zones: e.value })} />
                </TawreedFormField>
            </div>
        </Dialog>
    );
}
