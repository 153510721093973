import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../http";
import { BrokenOrder } from "../../domain";
import { BrokenOrderMapper } from "../mappers";

export class BrokenOrderRepository {

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<BrokenOrder>> {

        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = { ...buildFilterMetaToObject(request.keyword, { ...request.filters }) };
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.BROKEN_ORDERS_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => BrokenOrderMapper.fromJson(e)) ?? [] },
                };
            });
    }
    
    public resolve(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, boolean>(endpoints.BROKEN_ORDERS_RESOLVE, id,undefined,undefined,'all').then(res => {
            return res.status === 200
        });
    }

    // /**
    //  * Get partner details by id
    //  * @param id
    //  */
    // public async getDetails(id: number): Promise<PaymentTransaction> {
    //     return tawreedHttpService.post<number, JObject>(endpoints.PAYMENT_TRANSACTIONS_GET_DETAILS, id, undefined, undefined, 'error').then(res => PaymentTransactionMapper.fromJson(res.data!));
    // }
}