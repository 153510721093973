import React, { FC } from 'react'

import { BannerTable } from '../components/banner-table';

import { BannerForm } from '../components/banner-form';



/**
 * Tawreed Customers Page
 * @returns
 */
export const BannerPageTable: FC = () => (<BannerTable />);

/**
 * Tawreed  Customer Form Page
 * @returns
 */
export const BannerPageForm: FC = () => (<BannerForm />);

