import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';
import { FileUpload, FileUploadBeforeUploadParams, FileUploadErrorParams, FileUploadHandlerParam, FileUploadSelectParams, FileUploadUploadParams } from 'primereact/fileupload';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedResponseStatusCode } from '../../../../../http';
import { PartnerAutoCompleteComponent } from "../../../../partners";
import { PartnerType } from "../../../../partners/data";
import { TawreedDropdown, TawreedFormField, TawreedInputText } from "../../../../../common/components/form";
import { AuthUtils } from "../../../../auth/domain";
import { toastService } from "../../../../notifications/domain";
import { ImportHelperService } from '../../domain';
import { TawreedTranslatableDataService } from '../../../../../common/data/services/tawreed-data-service';


export interface ImportTaskDialog {
    visible: boolean;

    hide: () => void;

    accept: string;

    uploadUrl: string;

    isStore: boolean;

    partnerId: number;
}
export interface IMapping {
    itemId?: string,
    itemName?: string,
    itemPrice?: string,
    itemTax?: string,
    itemDiscountValue?: string,
    itemExpireValue?: string,
    itemExpireFormat?: string,
    itemOffer?: string,
    itemOfferSeparator?: string,
    itemBonus?: string,
    itemBonusSeparator?: string;
    quantity?: string;
    minimumOrderQuantity?: string;
    maximumOrderQuantity?: string;


}

export const ImportTaskDialog: FC<ImportTaskDialog> = ({ visible, hide, accept, uploadUrl, isStore, partnerId }) => {

    const file = useRef<FileUpload>(null);
    const service: ImportHelperService = useMemo(() => new ImportHelperService(), []);


    const [dialogState, setDialogState] = useState<{
        header: boolean, storeId: number | undefined, fileName: string | undefined, loading: boolean, searchEngineType?: string, similarityMode?: string,
        mapping: IMapping, storeImportSettingsId: number | undefined
    }>({
        storeId: isStore ? partnerId : undefined,
        header: true,
        fileName: undefined,
        loading: false,
        similarityMode: undefined,
        searchEngineType: undefined,
        storeImportSettingsId: undefined,
        mapping: {
            itemId: undefined,
            itemName: undefined,
            itemPrice: undefined,
            itemTax: undefined,
            itemDiscountValue: undefined,
            itemExpireValue: undefined,
            itemExpireFormat: undefined,
            itemOffer: undefined,
            itemOfferSeparator: undefined,
            itemBonus: undefined,
            itemBonusSeparator: undefined,
            quantity: undefined,
            minimumOrderQuantity: undefined,
            maximumOrderQuantity: undefined,

        }
    });

    function onBeforeSend(e: FileUploadBeforeUploadParams): void {
        if (!dialogState.storeId) {
            throw new Error('storeId');
        }
        if (dialogState.storeImportSettingsId) {
            e.formData.set('storeImportSettingsId', dialogState.storeImportSettingsId?.toString());
        }
        else {
            e.formData.set('storeImportSettingsId', '');
        }
        e.formData.set('storeId', dialogState.storeId.toString());
        e.formData.set('mapping', JSON.stringify([{
            name: "itemId",
            type: "string",
            index: dialogState.mapping.itemId
        },
        {
            name: "itemName",
            type: "string",
            index: dialogState.mapping.itemName
        },
        {
            name: "itemPrice",
            type: "string",
            index: dialogState.mapping.itemPrice
        },
        {
            name: "itemTax",
            type: "string",
            index: dialogState.mapping.itemTax
        },
        {
            name: "itemDiscount",
            type: "string",
            index: dialogState.mapping.itemDiscountValue
        },
        {
            name: "itemExpireDate",
            type: "date",
            index: dialogState.mapping.itemExpireValue,
            format: dialogState.mapping.itemExpireFormat
        },
        {
            name: "itemOffersBonus",
            type: "string",
            index: dialogState.mapping.itemBonus,
            separator: dialogState.mapping.itemBonusSeparator
        },
        {
            name: "itemOffersDiscount",
            type: "string",
            index: dialogState.mapping.itemOffer,
            separator: dialogState.mapping.itemOfferSeparator
        },
        {
            name: "quantity",
            type: "string",
            index: dialogState.mapping.quantity,
        },
        {
            name: "minimumOrderQuantity",
            type: "string",
            index: dialogState.mapping.minimumOrderQuantity,
        },
        {
            name: "maximumOrderQuantity",
            type: "string",
            index: dialogState.mapping.maximumOrderQuantity,
        }
        ]));
        e.formData.set('importConfig', JSON.stringify({
            header: true,
            similarityMode: dialogState.similarityMode,
            searchEngineType: dialogState.searchEngineType,
            checkStore: true
        }));
        // e.formData.set('categoryId', dialogState.categoryId.toString());
        e.xhr.setRequestHeader('Authorization', 'Bearer ' + AuthUtils.current().getToken().token);
        e.xhr.responseType = 'blob';
        e.xhr.timeout = 5 * 60 * 1000;
        setDialogState({ ...dialogState, loading: true });
    }

    function onUpload(_e: FileUploadHandlerParam): void {
    }

    function onSelect(e: FileUploadSelectParams): void {
        setDialogState({ ...dialogState, fileName: e.files && e.files.length ? e.files[0].name : undefined });
    }

    function onUploaded(e: FileUploadUploadParams): void {
        let response: any;
        try {
            response = JSON.parse(e.xhr.response || '{}');
            if (Object.keys(response).length && response.status !== TawreedResponseStatusCode.Success) {
                const error = response.error || 'msg_unknown_error';
                toastService.show({ severity: 'error', summary: tawreedTranslationService.translate('lbl_failed'), detail: tawreedTranslationService.translate(error) });
                setDialogState({
                    storeImportSettingsId: undefined, fileName: undefined, storeId: undefined, loading: false, header: true, searchEngineType: undefined, similarityMode: undefined,
                    mapping: {
                        itemId: undefined,
                        itemName: undefined,
                        itemPrice: undefined,
                        itemTax: undefined,
                        itemDiscountValue: undefined,
                        itemExpireValue: undefined,
                        itemExpireFormat: undefined,
                        itemOffer: undefined,
                        itemOfferSeparator: undefined,
                        itemBonus: undefined,
                        itemBonusSeparator: undefined,
                        quantity: undefined,
                        minimumOrderQuantity: undefined,
                        maximumOrderQuantity: undefined,
                    }
                });
                return;
            }
        } catch (error) {

            response = e.xhr.response;

            toastService.show({ severity: 'success', summary: tawreedTranslationService.translate('lbl_success'), detail: tawreedTranslationService.translate('msg_upload_completed_successfully') });
            setDialogState({
                storeImportSettingsId: undefined, fileName: undefined, storeId: undefined, loading: false, header: true, searchEngineType: undefined, similarityMode: undefined, mapping: {
                    itemId: undefined,
                    itemName: undefined,
                    itemPrice: undefined,
                    itemTax: undefined,
                    itemDiscountValue: undefined,
                    itemExpireValue: undefined,
                    itemExpireFormat: undefined,
                    itemOffer: undefined,
                    itemOfferSeparator: undefined,
                    itemBonus: undefined,
                    itemBonusSeparator: undefined,
                    quantity: undefined,
                    minimumOrderQuantity: undefined,
                    maximumOrderQuantity: undefined,
                }
            });
            setTimeout(() => hide(), 500);
            // const href = URL.createObjectURL(new Blob([response]));
            // create "a" HTLM element with href to file & click
            // const link = document.createElement('a');
            // link.href = href;
            // link.setAttribute('download', 'Rejected.csv'); //or any other extension
            // document.body.appendChild(link);
            // link.click();

            // // clean up "a" element & remove ObjectURL
            // document.body.removeChild(link);
            // URL.revokeObjectURL(response);
        }
    }

    function onError(e: FileUploadErrorParams): void {
        const error = ((typeof (e.xhr.response) === 'string' ? JSON.parse(e.xhr.response) : e.xhr.response) || { error: 'msg_unknown_error' }).error;
        toastService.show({ severity: 'error', summary: tawreedTranslationService.translate('lbl_failed'), detail: tawreedTranslationService.translate(error) });
        setDialogState({ ...dialogState, loading: false });
    }

    const ImportProductsDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={dialogState.loading} />
            <Button label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" onClick={() => file.current?.upload()} disabled={!dialogState || !dialogState.storeId || !file.current?.state.files || !file.current?.state.files.length || !dialogState.searchEngineType || !dialogState.similarityMode || dialogState.loading || !dialogState.mapping.itemName}
                loading={dialogState.loading} />
        </div>);
    }


    const getSimilirtyOptions = () => {
        return [
            { label: "LEVENSHTEIN", value: "LEVENSHTEIN" },
            { label: "EDIT", value: "EDIT" },
            { label: "JACCARD", value: "JACCARD" },
            { label: "JARO_WINKLER", value: "JARO_WINKLER" },
            { label: "TRIGRAM", value: "TRIGRAM" }
        ]
    }
    const getSearchEngineOptions = () => {
        let arr = [
            { label: "ELASTIC_SEARCH", value: "ELASTIC_SEARCH" },
            { label: "DATABASE", value: "DATABASE" },
            { label: "MEMORY", value: "MEMORY" }]
        if (dialogState.similarityMode === "LEVENSHTEIN" || dialogState.similarityMode === "JACCARD" || dialogState.similarityMode === "JARO_WINKLER") {
            return [
                { label: "ELASTIC_SEARCH", value: "ELASTIC_SEARCH" },
                { label: "DATABASE", value: "DATABASE" },
                { label: "MEMORY", value: "MEMORY" }
            ]
        }
        else if (dialogState.similarityMode === "EDIT") {
            return [
                { label: "ELASTIC_SEARCH", value: "ELASTIC_SEARCH" },
                { label: "MEMORY", value: "MEMORY" }
            ]
        }
        else if (dialogState.similarityMode === "TRIGRAM") {
            return [
                { label: "DATABASE", value: "DATABASE" },

            ]
        }
    }
    const [prevValue, setPrevValue] = useState('');

    const validateInput = (event: any, validatePattern: boolean) => {
        const target = event.target;

        // validatePattern is the result of the regex against the whole input string
        if (validatePattern) {
            if (target.value.length > 0) {
                setPrevValue(target.value);
            }

            // key was OK so do nothing
            return;
        }

        // key made the whole input not valid so block this key
        //  Compare current value with previous value
        if (target.value.length > 0) {
            // Set previous valid value
            target.value = prevValue;
        }
    };

    useEffect(() => {
        if (dialogState.storeId) {
            const data = service.getStoreImportSettings(dialogState.storeId).then((res) => {

                const mapping = res.mapping;
                if (mapping.length > 0) {
                    setDialogState({
                        ...dialogState,
                        header: res.importConfig?.header || true,
                        searchEngineType: res.importConfig?.searchEngineType,
                        similarityMode: res.importConfig?.similarityMode,
                        storeImportSettingsId: res.storeImportSettingsId,
                        mapping: {
                            itemId: mapping.filter(x => x.name == "itemId").at(0)?.index,
                            itemName: mapping.filter(x => x.name == "itemName").at(0)?.index,
                            itemPrice: mapping.filter(x => x.name == "itemPrice").at(0)?.index,
                            itemTax: mapping.filter(x => x.name == "itemTax").at(0)?.index,
                            itemDiscountValue: mapping.filter(x => x.name == "itemDiscount").at(0)?.index,
                            itemExpireValue: mapping.filter(x => x.name == "itemExpireDate").at(0)?.index,
                            itemExpireFormat: mapping.filter(x => x.name == "itemExpireDate").at(0)?.format,
                            itemOffer: mapping.filter(x => x.name == "itemOffersDiscount").at(0)?.index,
                            itemOfferSeparator: mapping.filter(x => x.name == "itemOffersDiscount").at(0)?.separator,
                            itemBonus: mapping.filter(x => x.name == "itemOffersBonus").at(0)?.index,
                            itemBonusSeparator: mapping.filter(x => x.name == "itemOffersBonus").at(0)?.separator,
                            quantity: mapping.filter(x => x.name == "quantity").at(0)?.index,
                            minimumOrderQuantity: mapping.filter(x => x.name == "minimumOrderQuantity").at(0)?.index,
                            maximumOrderQuantity: mapping.filter(x => x.name == "maximumOrderQuantity").at(0)?.index,



                        }
                    });
                }
                else {
                    setDialogState({
                        ...dialogState, storeImportSettingsId: undefined, header: true, searchEngineType: undefined, similarityMode: undefined, mapping: {
                            itemId: undefined,
                            itemName: undefined,
                            itemPrice: undefined,
                            itemTax: undefined,
                            itemDiscountValue: undefined,
                            itemExpireValue: undefined,
                            itemExpireFormat: undefined,
                            itemOffer: undefined,
                            itemOfferSeparator: undefined,
                            itemBonus: undefined,
                            itemBonusSeparator: undefined,
                            quantity: undefined,
                            minimumOrderQuantity: undefined,
                            maximumOrderQuantity: undefined,
                        }
                    });
                }

            });

        }
    }, [dialogState.storeId]);

    return (
        <Dialog style={{ maxWidth: '1002px' }} header={tawreedTranslationService.translate('lbl_store_products_import')} visible={visible} footer={<ImportProductsDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <form className="formgrid grid">
                {!isStore && <TawreedFormField name="storeId" title="lbl_store_id" className="field col-12">
                    <PartnerAutoCompleteComponent
                        partnerType={PartnerType.Store}
                        autoCompleteName="storeId"
                        autoCompleteField="partnerName"
                        autoCompleteRules={{ required: "msg_field_is_required" }}
                        autoCompleteRender="standalone"
                        autoCompleteOnSelect={(e) => setDialogState({ ...dialogState, storeId: e.value.partnerId })} />
                </TawreedFormField>}

                <TawreedFormField className="field col-12 md:col-12" name="categoryId" title={tawreedTranslationService.translate("lbl_algorithm")}>
                    <TawreedDropdown value={dialogState.similarityMode} onChange={(e) => {
                        setDialogState({ ...dialogState, similarityMode: e.target.value, searchEngineType: undefined })
                    }} render="standalone" options={getSimilirtyOptions()} />
                </TawreedFormField>

                <TawreedFormField className="field col-12 md:col-12" name="categoryId" title={tawreedTranslationService.translate("lbl_SearchEngineOptions")}>
                    <TawreedDropdown disabled={!dialogState.similarityMode} value={dialogState.searchEngineType} onChange={(e) => {
                        setDialogState({ ...dialogState, searchEngineType: e.target.value, })
                    }} render="standalone" options={getSearchEngineOptions()} />
                </TawreedFormField>
                <Panel header={tawreedTranslationService.translate("lbl_mapping")} style={{ width: '100%' }}>

                    <div className="grid">
                        <div className="col-3">
                            <div className="text-center p-3 border-round-sm font-bold">{tawreedTranslationService.translate("lbl_itemId")}</div>
                        </div>
                        <div className="col-3">
                            <TawreedInputText placeholder={tawreedTranslationService.translate("lbl_index")} keyfilter="pnum" value={dialogState.mapping.itemId}
                                onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, itemId: e.target.value } }) }}
                                rules={{ required: 'msg_field_is_required' }} name="itemId" render="standalone" />
                        </div>
                    </div>
                    <Divider />
                    <div className="grid">
                        <div className="col-3">
                            <div className="text-center p-3 border-round-sm font-bold">{tawreedTranslationService.translate("lbl_ItemName")}</div>
                        </div>
                        <div className="col-3">
                            <TawreedInputText placeholder={tawreedTranslationService.translate("lbl_index")} keyfilter="pnum" value={dialogState.mapping.itemName}
                                onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, itemName: e.target.value } }) }}
                                rules={{ required: 'msg_field_is_required' }} name="itemName" render="standalone" />
                        </div>
                    </div>
                    <Divider />
                    <div className="grid">
                        <div className="col-3">
                            <div className="text-center p-3 border-round-sm font-bold">{tawreedTranslationService.translate("lbl_ItemPrice")}</div>
                        </div>
                        <div className="col-3">
                            <TawreedInputText keyfilter="pnum" placeholder={tawreedTranslationService.translate("lbl_index")} value={dialogState.mapping.itemPrice}
                                onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, itemPrice: e.target.value } }) }}
                                rules={{ required: 'msg_field_is_required' }} name="itemPrice" render="standalone" />
                        </div>
                    </div>
                    <Divider />
                    <div className="grid">
                        <div className="col-3">
                            <div className="text-center p-3 border-round-sm font-bold">{tawreedTranslationService.translate("lbl_tax")}</div>
                        </div>
                        <div className="col-3">
                            <TawreedInputText placeholder={tawreedTranslationService.translate("lbl_index")} keyfilter="pnum" value={dialogState.mapping.itemTax}
                                onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, itemTax: e.target.value } }) }}
                                rules={{ required: 'msg_field_is_required' }} name="itemTax" render="standalone" />
                        </div>
                    </div>
                    <Divider />
                    <div className="grid">
                        <div className="col-3">
                            <div className="text-center p-3 border-round-sm font-bold">{tawreedTranslationService.translate("lbl_Discount")}</div>
                        </div>
                        <div className="col-3">
                            <TawreedInputText placeholder={tawreedTranslationService.translate("lbl_index")} keyfilter="pnum" value={dialogState.mapping.itemDiscountValue}
                                onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, itemDiscountValue: e.target.value } }) }}
                                rules={{ required: 'msg_field_is_required' }} name="itemDiscount" render="standalone" />
                        </div>
                    </div>
                    <Divider />
                    <div className="grid">
                        <div className="col-3">
                            <div className="text-center p-3 border-round-sm font-bold">{tawreedTranslationService.translate("lbl_ExpireDate")}</div>
                        </div>
                        <div className="col-3">
                            <TawreedInputText placeholder={tawreedTranslationService.translate("lbl_index")} keyfilter="pnum" value={dialogState.mapping.itemExpireValue} onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, itemExpireValue: e.target.value } }) }} rules={{ required: 'msg_field_is_required' }} name="displayName" render="standalone" />
                        </div>
                        <div className="col-6">
                            <TawreedInputText placeholder={tawreedTranslationService.translate("lbl_format")} value={dialogState.mapping.itemExpireFormat}
                                onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, itemExpireFormat: e.target.value } }) }}
                                rules={{ required: 'msg_field_is_required' }} name="itemExpireDate" render="standalone" />
                        </div>
                    </div>
                    <Divider />
                    <div className="grid">
                        <div className="col-3">
                            <div className="text-center p-3 border-round-sm font-bold">{tawreedTranslationService.translate("lbl_itemBonus")}</div>
                        </div>
                        <div className="col-3">
                            <TawreedInputText placeholder={tawreedTranslationService.translate("lbl_indices")} value={dialogState.mapping.itemBonus} onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, itemBonus: e.target.value } }) }} rules={{ required: 'msg_field_is_required' }} name="displayName" render="standalone" />
                        </div>
                        <div className="col-3">
                            <TawreedInputText placeholder={tawreedTranslationService.translate("lbl_separator")} value={dialogState.mapping.itemBonusSeparator}
                                onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, itemBonusSeparator: e.target.value } }) }}
                                rules={{ required: 'msg_field_is_required' }} name="itemBonus" render="standalone" />
                        </div>
                    </div>
                    <Divider />
                    <div className="grid">
                        <div className="col-3">
                            <div className="text-center p-3 border-round-sm font-bold">{tawreedTranslationService.translate("lbl_itemDiscountOffer")}</div>
                        </div>
                        <div className="col-3">
                            {/* <TawreedInputText placeholder='indices' keyfilter={/^([0-9]){1}((d{1})(([0-9])+))*$/} validateOnly onInput={validateInput} value={dialogState.itemOffer} onChange={(e) => { setDialogState({...dialogState, itemOffer:e.target.value}) }} rules={{ required: 'msg_field_is_required' }} name="displayName" render="standalone" /> */}
                            <TawreedInputText placeholder={tawreedTranslationService.translate("lbl_indices")} value={dialogState.mapping.itemOffer}
                                onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, itemOffer: e.target.value } }) }}
                                rules={{ required: 'msg_field_is_required' }} name="itemDiscountOffer" render="standalone" />
                        </div>
                        <div className="col-3">
                            <TawreedInputText placeholder={tawreedTranslationService.translate("lbl_separator")} value={dialogState.mapping.itemOfferSeparator} onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, itemOfferSeparator: e.target.value } }) }} rules={{ required: 'msg_field_is_required' }} name="displayName" render="standalone" />
                        </div>

                    </div>
                    <Divider />
                    <div className="grid">
                        <div className="col-3">
                            <div className="text-center p-3 border-round-sm font-bold">{tawreedTranslationService.translate("lbl_quantity")}</div>
                        </div>
                        <div className="col-3">
                            <TawreedInputText placeholder={tawreedTranslationService.translate("lbl_index")} keyfilter="pnum" value={dialogState.mapping.quantity}
                                onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, quantity: e.target.value } }) }}
                                rules={{ required: 'msg_field_is_required' }} name="quantity" render="standalone" />
                        </div>
                    </div>
                    <Divider />
                    <div className="grid">
                        <div className="col-3">
                            <div className="text-center p-3 border-round-sm font-bold">{tawreedTranslationService.translate("lbl_min_order_qty")}</div>
                        </div>
                        <div className="col-3">
                            <TawreedInputText placeholder={tawreedTranslationService.translate("lbl_index")} keyfilter="pnum" value={dialogState.mapping.minimumOrderQuantity}
                                onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, minimumOrderQuantity: e.target.value } }) }}
                                rules={{ required: 'msg_field_is_required' }} name="minimumOrderQuantity" render="standalone" />
                        </div>
                    </div>
                    <Divider />
                    <div className="grid">
                        <div className="col-3">
                            <div className="text-center p-3 border-round-sm font-bold">{tawreedTranslationService.translate("lbl_max_order_qty")}</div>
                        </div>
                        <div className="col-3">
                            <TawreedInputText placeholder={tawreedTranslationService.translate("lbl_index")} keyfilter="pnum" value={dialogState.mapping.maximumOrderQuantity}
                                onChange={(e) => { setDialogState({ ...dialogState, mapping: { ...dialogState.mapping, maximumOrderQuantity: e.target.value } }) }}
                                rules={{ required: 'msg_field_is_required' }} name="maximumOrderQuantity" render="standalone" />
                        </div>
                    </div>

                </Panel>

                <div className="field col-12 mt-2">
                    <label htmlFor="file">{tawreedTranslationService.translate('lbl_file')}</label>
                    <FileUpload
                        ref={file}
                        mode="basic"
                        name="file"
                        url={uploadUrl}
                        accept={accept}
                        onUpload={onUploaded} onError={onError}
                        auto={false} uploadHandler={onUpload} onSelect={onSelect}
                        onBeforeSend={onBeforeSend} disabled={!dialogState || !dialogState.storeId || dialogState.loading} />
                </div>
            </form>
        </Dialog>
    )
}
