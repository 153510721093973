import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { PartnerMapper } from "../../../partners/data";
import { TopUpTransaction } from "../../domain";

export const TopUpTransactionMapper = {

    fromJson(obj: JObject): TopUpTransaction {
        return {
            txnTopUpId: obj.txnTopUpId,
            partner: PartnerMapper.toDto(PartnerMapper.fromJson({
                partnerId: obj.partnerId,
                partnerName: obj.partnerName,
            })),
            topUpPartner: PartnerMapper.toDto(PartnerMapper.fromJson({
                partnerId: obj.topUpPartnerId,
                partnerName: obj.topUpPartnerName,
                businessType: obj.topUpType,
            })),
            transactionValue: obj.transactionValue,
            transactionStatus: obj.transactionStatus,
            transactionFeesPartner: obj.transactionFeesPartner,
            transactionFeesPlatform: obj.transactionFeesPlatform,
            transactionFeesTotal: obj.transactionFeesTotal,
            transactionCashBackValue: obj.transactionCashBackValue,
            transactionNetValue: obj.transactionNetValue,
            transactionLog: obj.transactionLog,
            topUpReferenceId: obj.topUpReferenceId,
            payLaterTerms: obj.payLaterTerms,
            createdAt: DateUtils.fromISO(obj.createdAt),
        };
    },
}