
import {JObject} from "../../../../common/data/models"
import {Commission} from "../models";
import {CommissionDto} from "../../domain";

export const CommissionMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {Commission}
     */
    fromJson: (json?: JObject): Commission => {
        return {
          commissionId:json?.commissionId,
          priceListId:json?.priceListId,
          partnerId:json?.partnerId,
          type:json?.type,
          value:json?.value,
          paymentModeId: json?.paymentModeId

        }
    },

    /**
     *
     */
    toJson: (model: Commission): JObject => {
        return {...model};
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {Commission}
     */
    fromDto: (dto: CommissionDto): Commission => {
        return {
            commissionId:dto.commissionId!,
            priceListId: dto.priceListId!,
            partnerId:dto.partnerId!,
            value:dto.value!,
            type:dto.type!,
        }
    },

    /**
     *
     */
    toDto: (model: Commission): CommissionDto => {
        return {
            commissionId:model.commissionId!,
            priceListId: model.priceListId!,
            partnerId:model.partnerId!,
            value:model.value!,
            type:model.type!,
            paymentModeId: model.paymentModeId
        };
    },
}

