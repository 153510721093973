import { JObject } from "../../../../../common/data/models";
import { Stock } from "../models/stock";
import { StockDto } from "../../domain/dtos/stock";
import { DateUtils } from "../../../../../helpers/date";

export const StockMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {Stock}
     */
    fromJson: (json?: JObject): Stock => {
        return {
            stockId: json?.stockId,
            buyerId: json?.buyerId,
            quantity: json?.qyantity,
            movementSource: json?.movementSource,
            movementType: json?.movementType,
            referenceId: json?.referenceId,
            stockBalance: json?.stockBalance,
            storeProductId: json?.storeProductId,
            storeProductName: json?.storeProductName,
            
            createdAt: DateUtils.fromISO(json?.createdAt),
        }
    },

    /**
     *
     */
    toJson: (model: Stock): JObject => {
        return { ...model };
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {Stock}
     */
    fromDto: (dto: StockDto): Stock => {
        return {
            stockId: dto?.stockId,
            buyerId: dto?.buyerId,
            quantity: dto?.quantity,
            movementSource: dto?.movementSource,
            movementType: dto?.movementType,
            referenceId: dto?.referenceId,
            stockBalance: dto?.stockBalance,
            storeProductId: dto?.storeProductId,
            storeProductName: dto?.storeProductName,
        }
    },

    /**
     *
     */
    toDto: (model: Stock): StockDto => {
        return {
            stockId: model?.stockId,
            buyerId: model?.buyerId,
            quantity: model?.quantity,
            movementSource: model?.movementSource,
            movementType: model?.movementType,
            referenceId: model?.referenceId,
            stockBalance: model?.stockBalance,
            storeProductId: model?.storeProductId,
            storeProductName: model?.storeProductName,
            createdAt: model?.createdAt
        };
    },
}

