import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedFormField, TawreedInputNumber, TawreedMenuDropdown } from "../../../../../common/components/form";
import { DiscountTypesDropDown } from './discount-types';
import { IBonusOperations } from '../../../../constants';
import { PriceUtils } from '../../../../../helpers/price';
import { TawreedMenuMultiSelect } from '../../../../../common/components/form/controls/multiselect/multiselect-menu';
import { GlobalContext } from '../../../../../context';


export interface BonusDialogProps {
    visible: boolean;

    hide: () => void;
    isDiscount: boolean;
    singlePrice?: number;

    accept: (data: { bonus: number, quantity: number, discountType?: string, bonusOperation?: string, sellingPrice?: number, classificationId:number[] }) => void;

}

export const BonusDialog: FC<BonusDialogProps> = ({ visible, hide, accept, isDiscount, singlePrice }) => {


    const [dialogState, setDialogState] = useState<{ quantity: number | undefined, bonus: number | undefined, discountType?: string, loading: boolean, bonusOperation?: string;classificationId:number[] }>({
        quantity: undefined,
        bonus: undefined,
        loading: false,
        discountType: undefined,
        classificationId: [],
        bonusOperation: "EQUALS",
    });
    const [sellingPrice, setSellingPrice] = useState<number>(0);
    const [loader, setLoader] = useState<boolean>(false);

    const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext); 

    useEffect(() => {

        setDialogState({
            quantity: undefined,
            bonus: undefined,
            loading: false,
            bonusOperation: "EQUALS",
            classificationId: [],
        })
    }, [visible])
    const priceUtils: PriceUtils =  new PriceUtils();
    useEffect(()=>{
        let time = setTimeout(() => {
            setLoader(true);
            priceUtils.getSellingPrice(singlePrice ?? 0, dialogState.discountType ?? 'fixed', dialogState.bonus ?? 0, user?.scale!)
            .then((res)=>{
            setLoader(false);
            setSellingPrice(res);
        }).catch((res)=>{
            setLoader(false);
        });
        }, 500)

        return () => clearTimeout(time);
    },[dialogState.discountType, dialogState.bonus, singlePrice]);


    // const getSellingPrice = () => {
    //     let discountType = 'fixed';
    //     if (dialogState.discountType === 'percentage')
    //         discountType = 'percentage';
    //     if (dialogState.discountType === 'bonus')
    //         discountType = 'bonus';
    //     let discountValue = 0;
    //     if (dialogState.bonus)
    //         discountValue = dialogState.bonus;
    //     let sellingPrice = singlePrice || 0;
    //     sellingPrice -= (discountType === 'fixed' ? discountValue : (singlePrice! * discountValue / 100));
    //     if (discountType === 'bonus') {
    //         sellingPrice = singlePrice! - (singlePrice! * (discountValue / (discountValue + 100)));
    //     }
    //     if (sellingPrice <= 0 && discountType !== 'bonus')
    //         return 0;
    //     return sellingPrice;

    // }

    const BonusDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={dialogState.loading} />
            <Button onClick={() => accept({ bonus: dialogState.bonus!, quantity: dialogState.quantity!, discountType: dialogState.discountType ?? 'fixed', bonusOperation: dialogState.bonusOperation ?? IBonusOperations.EQUALS, sellingPrice:sellingPrice,classificationId:dialogState.classificationId})} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" disabled={!dialogState || dialogState.loading || !dialogState.bonus || !dialogState.quantity || (isDiscount && sellingPrice <= 0)}
                loading={dialogState.loading} />
        </div>);
    }

    return (
        <Dialog style={{}} header={tawreedTranslationService.translate(!isDiscount ? 'lbl_bonus' : 'lbl_store_products_discount_bonus_title')} visible={visible} footer={<BonusDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <form className="formgrid grid" >
                {!isDiscount &&
                    <React.Fragment>
                        <TawreedFormField name="quantity" title="lbl_store_products_bonus_qty" className="field col-12 md:col-6">
                            <TawreedInputNumber value={dialogState?.quantity} name="quantity" rules={{ required: "msg_field_is_required" }} render="form" onChange={(e) => {
                                setDialogState({ ...dialogState, quantity: e.value ? e.value : 0 });
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="bonus" title={!isDiscount ? "lbl_store_products_bonus" : "lbl_store_products_discount_bonus_val"} className="field col-12 md:col-6">
                            <TawreedInputNumber mode={isDiscount ? 'decimal' : undefined} value={dialogState?.bonus} name="bonus" rules={{ required: "msg_field_is_required" }} render="form" onChange={(e) => {
                                setDialogState({ ...dialogState, bonus: e.value ? e.value : 0 });
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="class" title={"lbl_partners_Classification"} className="field col-12 lg:col-12 md:col-12">
                            <TawreedMenuMultiSelect menu="classifications" render="standalone"
                            value={dialogState?.classificationId} onChange={(e)=>setDialogState({...dialogState,classificationId:e.value})}/>
                    </TawreedFormField>
                    </React.Fragment>
                }
                {isDiscount &&
                    <React.Fragment>
                        <TawreedFormField name="quantity" title="lbl_store_products_bonus_qty" className="field col-3 lg:col-3 md:col-6">
                            <TawreedInputNumber value={dialogState?.quantity} name="quantity" rules={{ required: "msg_field_is_required" }} render="form" onChange={(e) => {
                                setDialogState({ ...dialogState, quantity: e.value ? e.value : 0 });
                            }} />
                        </TawreedFormField>
                        <TawreedFormField className="field col-3 lg:col-3 md:col-6" name="bonusOperation" title="lbl_bonus_operation">
                            <TawreedMenuDropdown menu={"bonusOperations"} value={dialogState?.bonusOperation} name="bonusOperation" render="form" rules={{ required: 'msg_field_is_required' }} onChange={(e) => {
                                setDialogState({ ...dialogState, bonusOperation: e.value })
                            }} />
                        </TawreedFormField>
                        <TawreedFormField className="field col-3 lg:col-3 md:col-6" name="discountType" title="lbl_price_scheme_discountType">
                            <DiscountTypesDropDown value={dialogState?.discountType} name="discountType" render="form" rules={{ required: 'msg_field_is_required' }} onChange={(e) => {
                                setDialogState({ ...dialogState, discountType: e.value })
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="bonus" title={!isDiscount ? "lbl_store_products_bonus" : "lbl_store_products_discount_bonus_val"} className="field col-3 lg:col-3 md:col-6">
                            <TawreedInputNumber mode={isDiscount ? 'decimal' : undefined} value={dialogState?.bonus} name="bonus" rules={{ required: "msg_field_is_required" }} render="form" onChange={(e) => {
                                setDialogState({ ...dialogState, bonus: e.value ? e.value : 0 });
                            }} />
                        </TawreedFormField>

                        {(sellingPrice >= 0) ?
                            <React.Fragment>
                                <TawreedFormField name="sellingPrice" title="lbl_sellingPrice_befor_per_unit" className="field col-6 lg:col-6 md:col-6 mt-2">
                                    <TawreedInputNumber mode="decimal" disabled suffix=" JOD" value={singlePrice} name="sellingPrice" render="standalone" readOnly />
                                </TawreedFormField>
                                <TawreedFormField name="sellingPrice" title="lbl_sellingPrice_befor_after_unit" className="field col-6 lg:col-6 md:col-6 mt-2">
                                <span>
                         {loader && <i className="pi pi-spinner pi-spin ml-2"></i>}
                           
                         <TawreedInputNumber mode="decimal" disabled suffix=" JOD" value={sellingPrice} name="sellingPrice" render="standalone" readOnly />
                                

                            </span>
                                </TawreedFormField>
                            </React.Fragment>
                            : ''
                        }
                        <TawreedFormField name="class" title={"lbl_partners_Classification"} className="field col-12 lg:col-12 md:col-12">
                            <TawreedMenuMultiSelect menu="classifications" render="standalone"
                            value={dialogState?.classificationId} onChange={(e)=>setDialogState({...dialogState,classificationId:e.value})}/>
                    </TawreedFormField>
                    </React.Fragment>
                }
            </form>
        </Dialog>
    )
}
