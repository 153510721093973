import { ColumnBodyOptions } from "primereact/column";
import { TawreedTable, TawreedTableProps } from "../../../../../common";
import { CssUtils } from "../../../../../helpers/css";
import { TawreedTableEditorCallback } from "../../../../../common/components/table/editors";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import React, { useState } from "react";
import { tawreedTranslationService } from "../../../../../common/translations";
import classNames from "classnames";
import { DeliveryCostDialog } from "./delivery-cost-dialog";


export const DeliveryCostTable: React.FC<any> = (props) => {

    const [state, setState] = useState<boolean>(false);

    const deleteBodyButton = (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {

        return <Button
            style={{ width: "60%", height: "35px" }}
            icon={PrimeIcons.TRASH}
            className="p-button-danger"
            loading={props.loading}
            onClick={() => {
                props.deleteDeliveryCost(rowData.stateId, rowData.deliveryCostId);
            }}
        >
        </Button>
    }
    const hide = () => {
        setState(false);
    }
    
    const accept = (data: {  state: string, stateId?: number, deliveryCost?: number, deliverySelling?: number;}) => {
        props.addDeliveryCost({ ...data});
        hide();
    }
    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'basic',
            data: props.data ? props.data : [],
        },
        name: 'lbl_delivery_partner_delivery_costs',
        dataKey: '',
        columns: [

                {
                    field: "state",
                    header: "lbl_delivery_cost_state",
                    style: CssUtils.width('20%'),
                },
                {
                    field: "deliveryCost",
                    header: "lbl_delivery_cost",
                    style: CssUtils.width('20%'),
                },
                {
                    field: "deliverySelling",
                    header: "lbl_delivery_selling",
                },
                {
                    dataType: 'numeric',
                    field: "delete",
                    body: deleteBodyButton,
                    style: CssUtils.width('15%'),
                }
            ],
        header: {
            left: {
                search: false,
                custom: (
                    <span>
                        {tawreedTranslationService.translate(props.title)}
                    </span>
                )
            },
            right: {
                clear: false,
                custom: (
                    <div className="formgroup-inline">
                        <i className={classNames(PrimeIcons.PLUS, 'bouns_table_plus')} onClick={() => setState(true)}></i>
                    </div>
                )
            }
        }
    };

    return (
        <React.Fragment>
            <div className="col-12 md:col-12"><TawreedTable {...tawreed} /></div>
            <DeliveryCostDialog
                visible={state}
                 hide={hide}
                 accept={accept}
            ></DeliveryCostDialog>
        </React.Fragment>
    );

}