import React from 'react';
import { classNames } from 'primereact/utils';

import { Controller, useFormContext } from 'react-hook-form';

import { TawreedControlProps, CONTROL_DEFAULT_CLASSNAME } from '../control';
import { InputFile, TawreedFile } from '../../../../../modules/upload';
import { tawreedTranslationService } from '../../../../translations';


export type TawreedInputFileProps = { value?: TawreedFile, onChange?: (e: TawreedFile | undefined) => void, accept?: string, disabled?: boolean } & TawreedControlProps;

export const TawreedInputFile: React.FC<TawreedInputFileProps> = (props) => {

    const [file, setFile] = React.useState<any>();
    const form = useFormContext();

    if (!form || !form.control || !props.name || props.render === 'standalone') {
        return <InputFile value={file} className={classNames(CONTROL_DEFAULT_CLASSNAME)} id={props.name} onChange={(e) => {

            setFile(e);
            if (props.onChange) {
                props.onChange(e);
            }
        }} />
    }

    if (props.render === 'form-nested') {
        return <Controller name={props.name} rules={props.rules} render={({ field, fieldState }) => {
            return (
                <React.Fragment>
                    <InputFile value={file}
                        disabled={props.disabled}
                        className={classNames(CONTROL_DEFAULT_CLASSNAME, { 'p-invalid': fieldState.error })}
                        id={field.name + '-' + props.nested}
                        name={field.name + '-' + props.nested}
                        accept={props.accept}
                        onChange={e => {
                            field.onChange({ ...field.value, [props.nested]: e });
                            if (props.onChange) {
                                props.onChange(e);
                            }
                        }} />
                    {
                        fieldState.error &&
                        fieldState.error.message &&
                        <small className="p-error">{tawreedTranslationService.translate(fieldState.error.message)}</small>
                    }
                </React.Fragment>
            )

        }} />
    }

    return <Controller name={props.name} rules={props.rules} render={({ field, fieldState }) => {

        return (
            <React.Fragment>
                <InputFile value={field.value}
                    disabled={props.disabled}
                    className={classNames(CONTROL_DEFAULT_CLASSNAME, { 'p-invalid': fieldState.error })}
                    id={field.name}
                    name={field.name}
                    //accept={props.accept}
                    onChange={e => {
                        field.onChange(e);
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }} />
                {
                    fieldState.error &&
                    fieldState.error.message &&
                    <small className="p-error">{tawreedTranslationService.translate(fieldState.error.message)}</small>
                }
            </React.Fragment>
        )
    }} />
}
