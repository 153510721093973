import { PrimeIcons } from "primereact/api";
import { Panel } from "primereact/panel";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { TawreedAction, TawreedActionName } from "../../../../../common/actions";
import { TawreedCalendar, TawreedCheckbox, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputSwitch, TawreedInputText, TawreedInputTextarea } from "../../../../../common/components/form";
import { tawreedTranslationService } from "../../../../../common/translations";
import { PartnerAutoCompleteComponent } from "../../../../partners";
import { PartnerType } from "../../../../partners/data";
import { Routes } from "../../../../../router";
import { ProductAutoCompleteComponent } from "../../../products/presentaion/components";
import { StoreProductDto, StoreProductsService, priceListDto, PriceSchemeService } from "../../domain";
import { PriceListServcie } from "../../domain/services/price-list.service";
import { PriceListForm } from "./price-list-form";
import { toastService } from "../../../../notifications/domain";
import { GlobalContext } from "../../../../../context";
import { ProductDto } from "../../../products/domain";
import { CommissionService } from "../../../../partners/domain";
import { resolve } from "path";
import { ProductDialog } from "./product-dialog";
import { Button } from "primereact/button";
import { AuthUtils } from "../../../../auth/domain";
import { QuantityDialog } from "./quantity-dialog";
import { JObject } from "../../../../../common/data/models";
import { IStockType } from "../../../../constants";
import { PriceUtils } from "../../../../../helpers/price";
import { StockHistoryDialog } from "./stock-history-dialog";

export const StoreProductForm: FC = (_props) => {
    const { id } = useParams<{ id: string | undefined }>();
    const [popUp, setPopUp] = useState(false);

    const defaultRef = useRef(null);
    const creditRef = useRef(null);
    const ref = useRef<{ submit: () => void }>();

    let array: any[] = [];

    // di
    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);

    const priceListService: PriceListServcie = useMemo(() => new PriceListServcie(), []);

    const priceSchemeService: PriceSchemeService = useMemo(() => new PriceSchemeService(), []);
    const commissionService: CommissionService = useMemo(() => new CommissionService(), []);
    const [quantityDialog, setQuantityDialog] = useState<{ isOpen: boolean, quantity?: number, isEnable: boolean }>({ isOpen: false, quantity: undefined, isEnable: false, })
    const [historyQuantityDialog, setHistoryQuantityDialog] = useState<boolean>(false);

    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);


    const [loading, setLoading] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>("Create");
    const [data, setData] = useState<StoreProductDto>({
        active: true,
    });
    const [activeQuantity, setActiveQuantity] = useState({
        quantity: false,
        minqty: false,
        maxqty: false,
    });
    const [priceLists, setPriceLists] = useState<Array<priceListDto>>([]);

    const history = useHistory();


    useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode("Edit");
                setLoading(true);
            }
            priceListService.getAll().then((res) => {
                setPriceLists(res);
            });
            service
                .getDetails(Number.parseInt(id))
                .then((res) => {
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                        setActiveQuantity({ quantity: res?.quantity ? true : false, maxqty: res?.maximumOrderQuantity ? true : false, minqty: res?.minimumOrderQuantity ? true : false })
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                });
        } else {
            if (mounted) {
                setMode("Create");
                priceListService.getAll().then((res) => {
                    setPriceLists(res);
                });
            }
        }
        if (user?.roleCode === 'STORE_ADMIN')
            setData({ ...data, store: { partnerId: user.partnerId } })
        return () => {
            mounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, service, priceListService, reload]);

    const updateQuantity = async (data: JObject) => {
        setLoading(true);
        if (!data.type)
            data.type = IStockType.Inbound
        await service.updateQuantity({ ...data, storeProductId: +id! }).then((res) => {
            setLoading(false);
            setReload(!reload)
        }).catch(err => setLoading(false))

    }
    const priceUtils: PriceUtils =  new PriceUtils();
    const onCreateOrSave = async (data: StoreProductDto, cb: TawreedFormSubmitCallback<StoreProductDto>): Promise<void> => {
        //@ts-ignore
        defaultRef.current.click();
        //@ts-ignore
        creditRef.current.click();
        let ok = false;
        array.forEach((el) => {
            if (el.price && el.price > 0)
                ok = true;
            if (!el.discountType)
                el.discountType = "fixed"
            if (!el.discountValue)
                el.discountValue = 0
        })

        if (!ok) {
            toastService.show({ detail: tawreedTranslationService.translate('lbl_price_list_error'), severity: 'error' });
            return new Promise<void>((resolve, reject) => {
                reject();
            });
        }
        ok = true;
        for await (const item of array) {
            if (item.sellingPrice<=0) {
                toastService.show({ detail: tawreedTranslationService.translate('lbl_selling_price_error'), severity: 'error' });
                    return new Promise<void>((resolve, reject) => {
                        reject();
                    });
            }
        }
        setLoading(true);

        for await (const item of array) {
            if (item.price && item.price > 0) {
                const sellingPrice =await priceUtils.getSellingPrice(item.price ?? 0, item.discountType ?? 'fixed', item.discountValue ?? 0, user?.scale!) 
                
                const result = await commissionService.checkPrice({ partnerId: data.store?.partnerId, priceListId: item.priceListId, value: sellingPrice });
                if (!result)
                    ok = false;
            }
                
        }
        if (!ok) {
            toastService.show({ detail: tawreedTranslationService.translate('lbl_price_list_commission_error'), severity: 'error' });
            return new Promise<void>((resolve, reject) => {
                setLoading(false);
                reject();
            });

        }
        // data.expireDate?.setTime(data.expireDate.getTime() + (3 * 60 * 60 * 1000));
        if (!activeQuantity.maxqty)
            data.maximumOrderQuantity = undefined;
        if (!activeQuantity.minqty)
            data.minimumOrderQuantity = undefined;
        if (!activeQuantity.quantity)
            data.quantity = undefined;

        setActiveQuantity({ quantity: data?.quantity ? true : false, maxqty: data?.maximumOrderQuantity ? true : false, minqty: data?.minimumOrderQuantity ? true : false })

        return service
            .createOrUpdate(data)
            .then(async (res) => {
                for await (const priceSchemeDto of array) {
                    if (priceSchemeDto.price > 0)
                        await priceSchemeService.createOrUpdate({ ...priceSchemeDto, storeProductId: res.storeProductId })
                }
                setLoading(false);
                if (!data.storeProductId && res.storeProductId) {
                    history.replace(Routes.StoreProductsDetails.replace(":id", res.storeProductId.toString()));
                }
                cb(res);
            })
            .catch((err) => {
                setLoading(false);
                cb(err);
            });
    };


    const onSubmit: TawreedFormSubmitProps<StoreProductDto> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<StoreProductDto>(TawreedActionName.StoreProductsSubmit, "lbl_save", PrimeIcons.SAVE, onCreateOrSave),
    };


    const onDelete = () => {
        setLoading(true);
        return service
            .delete(data.storeProductId!)
            .then((res) => {
                setLoading(false);
                if (res) {
                    history.replace(Routes.StoreProductsSearch);
                }
            })
            .catch((err) => {
                setLoading(false);
                throw err;
            });
    };

    const createNew = () => {
        return new Promise<void>((res, rej) => {
            history.push(Routes.StoreProductsNew)
            res()
        })
    }

    const actions = [new TawreedAction(TawreedActionName.StoreProductsDelete, "statefull", "lbl_delete", PrimeIcons.TRASH, onDelete, "p-button-danger", "confirmDelete")];
    if (id && actions.length === 1) {
        actions.push(new TawreedAction(TawreedActionName.StoreProductsSubmit, "statefull", "lbl_new", PrimeIcons.PLUS, createNew))
    }
    return (
        <React.Fragment>


            <TawreedForm customeTitle={data.titleEn ? (AuthUtils.current().language === 'en' ? data.titleEn : data.titleAr) + " - " + constants?.productCategories.find(el => el.id === (data.product?.categoryId ? data.product?.categoryId : data.categoryId))?.label : ''} ref={ref} title="lbl_store_product" dataKey="storeProductId" data={data} useReset submit={onSubmit} loading={loading} mode={mode} useBack=".." actions={actions} className="grid form-grid">
                <Panel header={tawreedTranslationService.translate("lbl_general_information")} className="col-12">
                    <div className="grid">

                        {user?.roleCode !== 'STORE_ADMIN' &&
                            <React.Fragment>
                                <TawreedFormField name="store" title="lbl_store_name" className="field col-12 md:col-6">
                                    <PartnerAutoCompleteComponent
                                        partnerType={PartnerType.Store}
                                        autoCompleteName="store"
                                        autoCompleteField="partnerName"
                                        autoCompleteRules={{ required: "msg_field_is_required" }}
                                        autoCompleteDisabled={data.storeProductId != null && true}
                                        autoCompleteOnSelect={(e) => {
                                            setData({ ...data, store: e.value });
                                        }}
                                        autoCompleteRender="form" />
                                </TawreedFormField>
                                <TawreedFormField name="active" title="lbl_product_active" className="field-checkbox col-12 md:col-6" flip>
                                    <TawreedInputSwitch name="active" render="form" />
                                </TawreedFormField>
                            </React.Fragment>

                        }
                        <React.Fragment>
                            <TawreedFormField name="product" title="lbl_store_products_product_id" className="field col-12 md:col-6">
                                <React.Fragment>
                                    <ProductAutoCompleteComponent
                                        storeId={data.store?.partnerId}
                                        autoCompleteName="product"
                                        autoCompleteRules={{ required: "msg_field_is_required" }}
                                        autoCompleteDisabled={data.store ? (data.storeProductId != null && true) : true}
                                        autoCompleteRender="form"
                                        autoCompleteOnSelect={e => {
                                            const p = e.value as ProductDto;
                                            console.log("ProductDto", p);
                                            if (p) {
                                                setData({
                                                    ...data,
                                                    storeItemId: p.internationalCode,
                                                    product: p,
                                                    titleAr: p.displayName,
                                                    titleEn: p.displayName,
                                                    tax: p.productTax,
                                                    price: p.price,
                                                });
                                            } else {
                                                setData({
                                                    ...data,
                                                    product: undefined,
                                                    titleAr: undefined,
                                                    titleEn: undefined,
                                                    tax: undefined,
                                                    price: undefined,
                                                    storeItemId: undefined,
                                                });
                                            }
                                        }} />
                                    <div className="p-2 font-bold">
                                        {
                                            data.storeProductId == null &&
                                            <NavLink to={"#"} onClick={() => setPopUp(true)}>{tawreedTranslationService.translate("lbl_store_product_browse")}</NavLink>

                                        }

                                    </div>
                                </React.Fragment>
                            </TawreedFormField>
                        </React.Fragment>
                        {user?.roleCode === "STORE_ADMIN" &&
                            <TawreedFormField name="active" title="lbl_product_active" className="field-checkbox col-12 md:col-6" flip>
                                <TawreedInputSwitch name="active" render="form" />
                            </TawreedFormField>}
                        <TawreedFormField name="storeItemId" title="lbl_store_product_item_id" className="field col-12 md:col-6">
                            <React.Fragment>
                                <TawreedInputText placeholder="" name="storeItemId" rules={{ required: "msg_field_is_required" }} render="form" />
                                <p >{tawreedTranslationService.translate("lbl_store_product_store_hint")}</p>

                            </React.Fragment>
                        </TawreedFormField>

                        <TawreedFormField name="expireDate" title="lbl_store_product_expire_date" className="field col-12 md:col-6">
                            <TawreedCalendar minDate={new Date()} render="form" name="expireDate" rules={data.product?.categoryId === 1 ? { required: 'msg_field_is_required' } : {}} />
                        </TawreedFormField>

                        <TawreedFormField name="tax" title="lbl_store_products_tax" className="field col-12 md:col-6">
                            <TawreedInputNumber suffix={" %"} mode={"decimal"} name="tax" rules={{ required: "msg_field_is_required" }} render="form" />
                        </TawreedFormField>

                        <TawreedFormField name="titleAr" title="lbl_store_product_title_ar" className="field col-12 md:col-6">
                            <TawreedInputText name="titleAr" render="form" rules={{
                                required: 'msg_field_is_required',
                                validate: (value) => {
                                    var trim_value = value.trim();
                                    if (trim_value.length == 0) {
                                        return tawreedTranslationService.translate('lbl_whiteSapce_field');
                                    }

                                }
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="titleEn" title="lbl_store_product_title_en" className="field col-12 md:col-6">
                            <TawreedInputText name="titleEn" render="form" rules={{
                                required: 'msg_field_is_required',
                                validate: (value) => {
                                    var trim_value = value.trim();
                                    if (trim_value.length == 0) {
                                        return tawreedTranslationService.translate('lbl_whiteSapce_field');
                                    }

                                }
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="description" title="lbl_store_product_description" className="field col-12 md:col-12">
                            <TawreedInputTextarea name="description" render="form" />
                        </TawreedFormField>
                    </div>
                </Panel>
                <Panel header={tawreedTranslationService.translate("lbl_quantity_trackin")} className="col-12" icons={
                <React.Fragment>
                {id &&     <Button
                            label={tawreedTranslationService.translate("lbl_stock_history")}
                            icon={PrimeIcons.CHART_LINE}
                            type="button" 
                            //loading={loading}
                            className={"p-button-text"}
                            onClick={() => { setHistoryQuantityDialog(true)}} />
                }
                </React.Fragment>
            } >
                    <div className="grid">

                        <TawreedFormField name="quantity" title={"lbl_quantity"} className="field col-12 md:col-9">
                            <TawreedInputNumber disabled={id ? true : (!activeQuantity.quantity)} name="quantity" render="form" onChange={(e) => {
                            }} />
                        </TawreedFormField>
                        {id && AuthUtils.current().authorized(TawreedActionName.StoreProductsSubmit) && <div className="field-checkbox col-12 md:col-3 mt-5">
                            {data.quantity !== null && <Button onClick={() => { setQuantityDialog({ isOpen: true, quantity: data.quantity, isEnable: false }) }} label={tawreedTranslationService.translate("lbl_update")} type="button" className="p-button-sm mr-2" />}

                            <Button onClick={() => {
                                if (data.quantity == null)
                                    setQuantityDialog({ isOpen: true, quantity: data.quantity, isEnable: true })
                                else
                                    updateQuantity({})
                            }
                            } label={tawreedTranslationService.translate(data.quantity == null ? "lbl_enable" : "lbl_disable")} type="button" className={`p-button-sm  ${data.quantity == null ? "" : "p-button-danger"}`} />
                        </div>}
                        {!id && <TawreedFormField name="activeQuantity" title="lbl_product_active" className="field-checkbox col-12 md:col-3 mt-5" flip>
                            <TawreedInputSwitch name="activeQuantity" checked={activeQuantity.quantity} render="standalone" onChange={(e) => { setActiveQuantity({ ...activeQuantity, quantity: e.value }) }} />
                        </TawreedFormField>}
                        <TawreedFormField name="minimumOrderQuantity" title={"lbl_min_order_qty"} className="field col-12 md:col-9">
                            <TawreedInputNumber disabled={!activeQuantity.minqty} name="minimumOrderQuantity" render="form" onChange={(e) => {
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="activeMinQuantity" title="lbl_product_active" className="field-checkbox col-12 md:col-3 mt-5" flip>
                            <TawreedInputSwitch name="activeMinQuantity" render="standalone" checked={activeQuantity.minqty} onChange={(e) => { setActiveQuantity({ ...activeQuantity, minqty: e.value }) }} />
                        </TawreedFormField>
                        <TawreedFormField name="maximumOrderQuantity" title={"lbl_max_order_qty"} className="field col-12 md:col-9">
                            <TawreedInputNumber disabled={!activeQuantity.maxqty} name="maximumOrderQuantity" render="form" onChange={(e) => {
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="activeMaxQuantity" title="lbl_product_active" className="field-checkbox col-12 md:col-3 mt-5" flip>
                            <TawreedInputSwitch name="activeMinQuantity" render="standalone" checked={activeQuantity.maxqty} onChange={(e) => { setActiveQuantity({ ...activeQuantity, maxqty: e.value }) }} />
                        </TawreedFormField>
                    </div>
                </Panel>
            </TawreedForm>
            {data.product && <Panel header={tawreedTranslationService.translate('lbl_price_scheme_price_list_name')} >
                {
                    priceLists.map((el, idx) => {
                        return (

                            <PriceListForm
                                name={el.priceListName!}
                                onSavePriceList={(data) => {
                                    const idx = array.findIndex((el) => el.priceListId === data.priceListId)
                                    if (idx >= 0) {
                                        array[idx] = data;
                                    }
                                    else
                                        array.push(data);
                                }}
                                ref1={idx === 0 ? defaultRef : creditRef}
                                storeProductId={id ? +id : -1}
                                priceListId={el.priceListId}
                                price={data.price}
                                key={idx}
                                loading={loading}
                            ></PriceListForm>

                        )
                    })
                }
            </Panel >}
            {popUp && <ProductDialog visible={popUp} isDiscount={false} hide={() => setPopUp(false)} accept={(selectedData) => {
                setData({
                    ...data,
                    product: selectedData,
                    titleAr: selectedData.productName,
                    titleEn: selectedData.productNameEn,
                    tax: selectedData.productTax,
                    price: selectedData.price,
                    storeItemId: selectedData.internationalCode,
                })
                setPopUp(false);
            }}></ProductDialog>}

            {quantityDialog.isOpen && <QuantityDialog isEnable={quantityDialog.isEnable} accept={(data) => { updateQuantity(data); setQuantityDialog({ isEnable: false, isOpen: false, quantity: undefined }) }} visible={quantityDialog.isOpen} hide={() => setQuantityDialog({ isOpen: false, quantity: undefined, isEnable: false, })}></QuantityDialog>}
            {historyQuantityDialog && <StockHistoryDialog hide={()=> {setHistoryQuantityDialog(false)}}
                storeProductId={id ? Number.parseInt(id): undefined} visible={historyQuantityDialog}
                ></StockHistoryDialog>}
        </React.Fragment >
    );
};
