import React, { useState } from 'react'
import { BannerService, SettingBannerDto } from '../../domain';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../router';
import { BannerSetting } from '../../data';
import { TawreedTable, TawreedTableProps } from '../../../../common';
import { CssUtils } from '../../../../helpers/css';
import { TawreedAction, TawreedActionName } from '../../../../common/actions';
import { PrimeIcons } from 'primereact/api';
import { GlobalContext } from '../../../../context';
import { TawreedTableFilters } from '../../../../common/components/table/filters';



export const BannerTable: React.FC = (props) => {



    const history = useHistory();
    const service: BannerService = React.useMemo(() => new BannerService(), []);

    const { constants: { constants } } = React.useContext(GlobalContext);



    const [data, setData] = useState<Array<SettingBannerDto>>([])

    const [loader, setLoader] = useState(false);

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.SettingsBannerNew);
            resolve();
        })
    }

    React.useEffect(() => {
        setLoader(true)
        service.getAll().then(res => {
            setData(res);
            setLoader(false)
        })
    }, [service])

    const onForm = (sender?: BannerSetting): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.SettingsBannerDetials.replace(':id', sender!.bannerId.toString()));
            resolve();
        })
    }


    const tawreed: TawreedTableProps = {
        pagination: true,
        dataKey: 'bannerId',
        name: 'tbl.banners',
        title: 'lbl_banner',
        outLoader: loader,
        ds: {
            mode: 'basic',
            data: data,
        },
        columns: [
            {
                field: "bannerId",
                header: "lbl_banner_id",
                style: CssUtils.width('22.5%'),
            },
            {
                field: "businessType",
                header: "lbl_partners_business_type",
                ...TawreedTableFilters.dropdown.dropdown("businessType", constants?.businessTypesCustomer ?? [], 'value', 'label', 'in'),
                style: CssUtils.width('22.5%'),
            },
        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.BannerNew, 'stateless', 'lbl_new', PrimeIcons.PLUS, onNew),
                // new TawreedAction(TawreedActionName.ProductsDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete'),
            ],
        },
        row: {
            onClick: new TawreedAction(TawreedActionName.BannerDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onForm, 'p-button-secondary', false),
        },
    }

    return (<TawreedTable  {...tawreed} />);
}
