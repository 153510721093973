import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../http";
import { FileService } from "../../../upload";
import { TopUpPartner } from "../../domain";
import { TopUpPartnerMapper } from "../mappers";

export class TopUpPartnerRepository {

    private filesService = new FileService();

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<TopUpPartner>> {

        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = { ...buildFilterMetaToObject(request.keyword, { ...request.filters }) };

        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.TOPUP_PARTNERS_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => TopUpPartnerMapper.fromJson(e)) ?? [] },
                };
            });
    }

    /**
     * Create or update partner instance
     * @param type
     * @param form
     */
    public async createOrUpdate(form: TopUpPartner): Promise<TopUpPartner> {
        await this.createOrUpdateLogo(form);
        const data = TopUpPartnerMapper.toJson(form);
        return tawreedHttpService.post<JObject, JObject>(endpoints.TOPUP_PARTNERS_CREATE_OR_SAVE, data)
            .then(res => {
                return TopUpPartnerMapper.fromJson(res.data!);
            });
    }

    private async createOrUpdateLogo(form: TopUpPartner): Promise<void> {
        if (form && form.logoContent && form.logoContent.value instanceof File) {
            form.logoContent.value = await this.filesService.upload(form.logoContent);
        }
    }

    /**
     * Delete partner by id
     * @param id
     */
    public async delete(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.TOPUP_PARTNERS_DELETE, id).then(res => {
            return res.status === 200
        });
    }

    public async cleanup(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.TOPUP_PARTNERS_CLEANUP, id).then(res => res.status === 200);
    }


    /**
     * Delete all partners by ids
     * @param ids
     */
    public async deleteAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], number>(endpoints.TOPUP_PARTNERS_DELETE_ALL, ids).then(res => res.status === 200);
    }

    /**
     * Get partner details by id
     * @param id
     */
    public async getDetails(id: number): Promise<TopUpPartner> {
        return tawreedHttpService.post<number, JObject>(endpoints.TOPUP_PARTNERS_GET_DETAILS, id, undefined, undefined, 'error').then(res => TopUpPartnerMapper.fromJson(res.data!));
    }
}