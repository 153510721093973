import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'

import React, { FC, useState } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { JObject } from '../../../../../common/data/models';
import { TawreedFormField, TawreedInputNumber, TawreedMenuDropdown,TawreedInputText } from '../../../../../common';


export interface QuantityDialogProps {
    visible: boolean;
    hide: () => void;
    accept: (data: JObject) => void
    isEnable: boolean;
}

export const QuantityDialog: FC<QuantityDialogProps> = ({ visible, hide, isEnable, accept }) => {
    const [state, setState] = useState<{ quantity?: number, type?: string, referenceId?: number }>({ quantity: undefined, referenceId: undefined, type: undefined })

    const ImportProductsDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={false} />

            <Button label={tawreedTranslationService.translate('lbl_save')} icon="pi pi-check" onClick={() => accept(state)} disabled={state.quantity === undefined || (!state.type && !isEnable)} />
        </div>);
    }

    return (
        <Dialog style={{ minWidth: '60%' }} header={tawreedTranslationService.translate('lbl_quantity')} visible={visible} footer={<ImportProductsDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <form className="formgrid grid" >
                <TawreedFormField name="amount" title={"lbl_finance_documents_amount"} className="field col-12 lg:col-12 md:col-12">
                <TawreedInputText inputMode='numeric' min={0} value={state?.quantity} name="amount" rules={{ required: "msg_field_is_required" }} render="form" onChange={(e) => {
                        setState({ ...state, quantity: e.target.value ? Number.parseInt(e.target.value) : 0 });
                    }} />
                </TawreedFormField>
                {!isEnable && <TawreedFormField className="field col-12 lg:col-12 md:col-12" name="quantityType" title="lbl_bonus_operation">
                    <TawreedMenuDropdown menu={"quantityType"} value={state?.type} name="quantityType" render="form" rules={{ required: 'msg_field_is_required' }} onChange={(e) => {
                        setState({ ...state, type: e.value })
                    }} />
                </TawreedFormField>}
                <TawreedFormField name="referenceId" title={"lbl_reference"} className="field col-12 lg:col-12 md:col-12">
                    <TawreedInputNumber value={state?.referenceId} name="referenceId" render="form" onChange={(e) => {
                        setState({ ...state, referenceId: e.value ? e.value : 0 });
                    }} />
                </TawreedFormField>
            </form>
        </Dialog>

    )
}
