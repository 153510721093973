import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedFormField, TawreedInputNumber } from '../../../../../common';
import { StatesDropdown } from '../../../../states';

export interface DeliveryCostDialogProps {
    visible: boolean;

    hide: () => void;

    accept: (data: { state: string, stateId?: number, deliveryCost?: number, deliverySelling?: number; }) => void;

}

export const DeliveryCostDialog: FC<DeliveryCostDialogProps> = ({ visible, hide, accept }) => {


    const [dialogState, setDialogState] = useState<{ stateId: number | undefined, 
        state: string | undefined, deliveryCost: number | undefined, deliverySelling?: number | undefined, loading: boolean }>({
        stateId: undefined,
        state: undefined,
        deliveryCost: undefined,
        loading: false,
        deliverySelling: undefined,
    });


    useEffect(() => {

        setDialogState({
            stateId: undefined,
            deliveryCost: undefined,
            state: undefined,
            loading: false,
            deliverySelling: undefined,
        })
    }, [visible])


    const DeliveryCostDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={dialogState.loading} />
            <Button onClick={() => accept({ state: dialogState.state!, stateId: dialogState.stateId!,
             deliveryCost: dialogState.deliveryCost, deliverySelling: dialogState.deliverySelling})} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" disabled={!dialogState || dialogState.loading || !dialogState.stateId
                 || dialogState.deliveryCost==undefined || dialogState.deliverySelling==undefined}
                loading={dialogState.loading} />
        </div>);
    }

    return (
        <Dialog style={{}} header={tawreedTranslationService.translate('lbl_delivery_cost')} visible={visible} footer={<DeliveryCostDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <form className="formgrid grid" >
            <React.Fragment>
                        <TawreedFormField name="stateId" className="field col-12 md:col-12" title="lbl_delivery_cost_state">
                            <StatesDropdown name="stateId" value={dialogState.stateId} rules={{ required: 'msg_field_is_required' }} render="standalone" onChange={(e) => {
                                setDialogState({ ...dialogState, state: e.value, stateId: e.value});
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="deliveryCost" title="lbl_delivery_cost" className="field col-12 md:col-6">
                            <TawreedInputNumber mode={'decimal'} value={dialogState?.deliveryCost} name="deliveryCost" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                                setDialogState({ ...dialogState, deliveryCost: e.value ? e.value : 0 });
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="deliverySelling" title={"lbl_delivery_selling"} className="field col-12 md:col-6">
                            <TawreedInputNumber mode={'decimal'} value={dialogState?.deliverySelling} name="bonus" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                                setDialogState({ ...dialogState, deliverySelling: e.value ? e.value : 0 });
                            }} />
                        </TawreedFormField>
                    </React.Fragment>
            </form>
        </Dialog>
    )
}
