import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { FileUpload, FileUploadBeforeUploadParams, FileUploadErrorParams, FileUploadHandlerParam, FileUploadSelectParams, FileUploadUploadParams } from 'primereact/fileupload';
import React, { FC, useRef, useState } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedResponseStatusCode } from '../../../../../http';
import { PartnerAutoCompleteComponent } from "../../../../partners";
import { PartnerType } from "../../../../partners/data";
import { TawreedFormField, TawreedMenuDropdown } from "../../../../../common/components/form";
import { AuthUtils } from "../../../../auth/domain";
import { toastService } from "../../../../notifications/domain";
import { CategoriesDropdown } from '../../../products/presentaion/components/categories-dropdown';
import { TawreedMenuMultiSelect } from '../../../../../common/components/form/controls/multiselect/multiselect-menu';
import { JObject } from '../../../../../common/data/models';

export interface DownloadProductsDialogProps {
    visible: boolean;

    hide: () => void;


    isStore: boolean;

    partnerId: number;

    download: (data: JObject) => Promise<void>;
    loading: boolean
}

export const DownloadProductsDialog: FC<DownloadProductsDialogProps> = ({ visible, hide, isStore, partnerId, download, loading }) => {

    const [dialogState, setDialogState] = useState<{ categoryId: number | undefined, storeId: number | undefined, priceListId: number | undefined, fileName: string | undefined, storeName?: string, loading: boolean }>({
        storeId: isStore ? partnerId : undefined,
        categoryId: undefined,
        priceListId: undefined,
        fileName: undefined,
        loading: false,
        storeName: undefined,
    });





    const ImportProductsDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={dialogState.loading} />
            <Button label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" onClick={() => download(dialogState)} disabled={!dialogState || !dialogState.storeId || loading || !dialogState.priceListId}
                loading={dialogState.loading} />
        </div>);
    }

    return (
        <Dialog style={{ maxWidth: '512px' }} header={tawreedTranslationService.translate('lbl_store_products_download')} visible={visible} footer={<ImportProductsDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <form className="formgrid grid">
                {!isStore && <TawreedFormField name="storeId" title="lbl_store_id" className="field col-12">
                    <PartnerAutoCompleteComponent
                        partnerType={PartnerType.Store}
                        autoCompleteName="storeId"
                        autoCompleteField="partnerName"
                        autoCompleteRules={{ required: "msg_field_is_required" }}
                        autoCompleteRender="standalone"
                        autoCompleteOnSelect={(e) => setDialogState({ ...dialogState, storeId: e.value.partnerId, storeName: e.value.partnerName })} />
                </TawreedFormField>}

                <TawreedFormField name="priceListId" title="lbl_price_scheme_price_list_name" className="field col-12">
                    <TawreedMenuDropdown menu={"priceLists"} render="standalone" value={dialogState.priceListId} onChange={(e) => setDialogState({ ...dialogState, priceListId: e.value })} />
                </TawreedFormField>

            </form>
        </Dialog>
    )
}
