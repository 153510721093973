import React, { useContext, useState } from "react";
import { CartService, CustomerStoreCartDto, CustomerStoreCartItemDto } from "../../domain";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { Message } from 'primereact/message';
import { TawreedTable, TawreedTableProps, TawreedTableColumnProps } from "../../../../../common/components/table";
import { TawreedAction, TawreedActionName } from "../../../../../common/actions";
import { TawreedTableEditor } from "../../../../../common/components/table/editors";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { CssUtils } from "../../../../../helpers/css";

import './cart-form-store-cart';
import { tawreedTranslationService } from "../../../../../common/translations";
import { Tag } from "primereact/tag";
import { ObjectUtils } from "../../../../../helpers/object";
import { CartSingleFormSummary } from './cart-form-single-summary'
import { GlobalContext } from "../../../../../context";
import { IBonusType } from "../../../../constants";
import { AuthUtils } from "../../../../auth/domain";

export interface CartFormStoreCartProps {
    className: string;
    cart: CustomerStoreCartDto;
    onDelete: (cart: CustomerStoreCartDto) => Promise<void>;
    onDeleteItem: (item: CustomerStoreCartItemDto) => Promise<void>;
    onSave: (item: CustomerStoreCartItemDto) => Promise<boolean>;
    disabled: boolean;
    onCheckout: (cartId?: number) => void;
    priceListId: number;
    priceListName: string;
    salesOfferId?: string | null,
    reload: boolean
}

export const CartFormStoreCart: React.FC<CartFormStoreCartProps> = (props) => {

    const { auth: { user }, constants: { constants } } = useContext(GlobalContext);
    const service: CartService = React.useMemo(() => new CartService(), []);
    const lang = AuthUtils.current().language;

    let columns: TawreedTableColumnProps[] = [
        {
            dataType: 'text',
            field: "productName",
            sortable:true,
            header: "lbl_sales_cart_store_cart_items_product_name",
            body: (rowData: CustomerStoreCartItemDto) => {

                const productName = rowData.productName;
                const availableQuantity = rowData.availableQuantity ?? 0;

                return (
                    <div className="flex flex-column align-items-end md:align-items-start">
                        <span>{productName}</span>
                        {
                            availableQuantity < 0 && <Tag value={tawreedTranslationService.translate('lbl_sales_cart_store_cart_items_not_available')} severity="danger" rounded />
                        }
                    </div>
                )
            },
            style: CssUtils.width('30%'),
            bodyStyle: { 'unicode-bidi': 'plaintext' }
        },
        {
            dataType: 'text',
            field: "quantity",
            sortable:true,
            header: "lbl_sales_cart_store_cart_items_quantity",
            body: props.salesOfferId != null || props.disabled || !AuthUtils.current().authorized(TawreedActionName.CartsUpdate) ? (rowData: CustomerStoreCartItemDto) => {

                const salesOfferItemQuantity = rowData.quantity ?? 0;
                return (
                    <div className="flex flex-column align-items-end md:align-items-start">
                        <span>{salesOfferItemQuantity}</span>

                    </div>
                )
            } : TawreedTableEditor.numeric('int', 1000, 1),
            style: CssUtils.width('10%'),
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
        },
        {
            dataType: 'numeric',
            field: "retailPrice",
            sortable:true,
            header: "lbl_sales_cart_store_cart_items_retail_price",
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            body: (options: any) => ObjectUtils.toLocalString(options.retailPrice, user?.scale)
        },
        {
            dataType: 'numeric',
            field: "discountValue",
            header: "lbl_base_discount",
            sortable:true,
            filter: false,
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            ...TawreedTableFormatters.currencyOrPercentageDepend('discountValue', 'discountType', constants?.info?.currencyCode ?? '', user?.scale!),
        },
        {
            dataType: 'numeric',
            field: "bonus",
            header: "lbl_additional_discount",
            sortable:true,
            filter: false,
            style: CssUtils.width('10%'),
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            className: CssUtils.className.field.amount,
            body: (options: any) => (options.bonusType === IBonusType.DISCOUNT ? ObjectUtils.toLocalString(options.bonus, user?.scale) : 0) + " " + constants?.info?.currencyCode,
        },
        {
            dataType: 'numeric',
            sortable:true,
            field: "bonus",
            header: "lbl_store_products_bonus",
            style: CssUtils.width('10%'),
            body: (options: any) => options.bonusType === IBonusType.EXTRA_ITEMS ? options.bonus : 0
        },

        {
            dataType: 'numeric',
            field: "itemGrossTotal",
            sortable:true,
            header: "lbl_sub_total1",
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            body: (options: any) => ObjectUtils.toLocalString(options.itemGrossTotal - options.itemTotalDiscount, user?.scale)
        },
        {
            dataType: 'numeric',
            field: "storeProductTax",
            sortable:true,
            header: "lbl_sales_cart_store_cart_items_item_total_tax1",
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            style: CssUtils.width('5%'),
            className: CssUtils.className.field.amount
        },
        {
            dataType: 'numeric',
            field: "itemTotal",
            header: "lbl_total1",
            sortable:true,
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            body: (options: any) => ObjectUtils.toLocalString(options.itemGrossTotal - options.itemTotalDiscount + options.itemTotalTax, user?.scale)
        }];
    if (!props.salesOfferId) {
        if(AuthUtils.current().authorized(TawreedActionName.CartsStoreCartItemDelete)){
        columns = [...columns, {
            field: "delete",
            // header: "lbl_delete",
            className: CssUtils.className.field.amount,
            body: (options: any) => {
                return (
                    <Button type="button" icon={PrimeIcons.TRASH} onClick={() => props.onDeleteItem(options)} disabled={props.disabled} className="p-button-rounded p-button-text p-button-secondary" />
                )
            }
        }];
    }
    }
    const tawreed: TawreedTableProps = {
        reload: props.reload,
        pagination: true,
        dataKey: 'cartItemId',
        name: 'tbl.sales.carts.cart.store',
        ds: {
            mode: 'lazy',
            onSearch: (q) =>service.searchItems(props.cart.cartId!,q),
        },
        columns: [
            ...columns

        ],

        editable: {
            onEditComplete: (rowData, _field, _value) => props.onSave(rowData),
        },
        header: {
            left: {
                search: false,
                custom: (
                    <React.Fragment>
                        <span className="flex flex-row">
                            <span className="mr-1">{props.cart.store}</span>
                            <span>({ObjectUtils.toLocalString(props.cart.total, user?.scale) + ' JOD'})({props.priceListName})</span>
                        </span>

                    </React.Fragment>

                )
            },
            right: AuthUtils.current().authorized(TawreedActionName.CartsStoreCartDelete) ? {
                clear: false,
                custom: (
                    <span className="p-panel-header-buttons">
                        {
                            
                            [new TawreedAction(
                                TawreedActionName.CartsStoreCartDelete,
                                'statefull',
                                'lbl_delete',
                                PrimeIcons.TRASH,
                                () => props.onDelete(props.cart),
                                undefined,
                                'confirmDelete')].map(e => <Button type="button" icon={e.icon} onClick={() => e.execute()} disabled={props.disabled} className="p-button-rounded p-button-text p-button-secondary" />)
                        }
                        {/*<Button type="button" icon={PrimeIcons.CHECK} label={tawreedTranslationService.translate('lbl_checkout')} onClick={() => props.onCheckout(props.cart)} disabled={props.disabled} />*/}
                    </span>
                ),
            }: undefined,
            bottom: props.cart.cartMessage ? <Message severity="warn" text={props.cart.cartMessage} className="w-full justify-content-start" /> : undefined,
        },
        selection: {
            mode: 'single',
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'productName',
                sortOrder: -1,
                multiSortMeta: undefined,
            }
        }
    }

    return (
        <div className={props.className}>
            <TawreedTable {...tawreed} />
            <CartSingleFormSummary priceListId={props.priceListId} className="col-12" cart={props.cart} onCheckout={props.onCheckout} disabled={props.disabled || !props.cart.showPlaceOrder} cartId={props.cart.cartId} />
        </div>
    )
}
