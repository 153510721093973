import React from "react";
import { TawreedFormField, TawreedInputText } from "../../../../../../../../common/components/form";
import { PartnerType } from "../../../../../../../partners/data";
import { PartnerDto } from "../../../../../../../partners/domain";
import { tawreedTranslationService } from "../../../../../../../../common/translations";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { PrimeIcons } from "primereact/api";
import { computeTawreedActionTitle, TawreedAction, TawreedActionDialog } from "../../../../../../../../common/actions";
import { PartnerDropdownComponent } from "../../../../../../../partners/presentaion/components/autocomplete/partner-dropdown.component";
import { DeliveryDropdownComponent } from "./delivery-dropdown.component";
import { AuthUtils } from "../../../../../../../auth/domain";

export type OrderConfirmDialogProps = {

    customer: PartnerDto | undefined;

    partner: PartnerDto | undefined;

    carrier: number | undefined;
    payThroughUs: boolean | undefined,
    salesorderId?: number | undefined,

};

export type OrderConfirmDialogState = {
    /**
     *
     */
    carrier: number | undefined;

    visible: boolean;

    title: string;
}

export class OrderConfirmDialog extends React.Component<OrderConfirmDialogProps, OrderConfirmDialogState> implements TawreedActionDialog<any, any, number> {

    constructor(props: OrderConfirmDialogProps) {
        super(props);

        this.state = {
            title: '',
            carrier: undefined,
            visible: false,
        };
    }

    private resolve: ((carrierId: number) => void) | undefined;
    private reject: (() => void) | undefined;

    private OrderConfirmDialogFooter = () => {
        return (
            <div>
                <Button label={tawreedTranslationService.translate('lbl_cancel')} icon={PrimeIcons.TIMES} onClick={() => this.onHide()} className="p-button-text" />
                <Button label={tawreedTranslationService.translate('lbl_accept')}
                    icon={PrimeIcons.CHECK}
                    onClick={() => this.onAccept()}
                    disabled={!this.state || !this.state.carrier} />
            </div>
        );
    }

    private onHide(): void {
        if (this.reject) {
            this.setState({ ...this.state, visible: false });
            this.reject();
        }
    }

    private onAccept(): void {
        if (this.resolve) {
            if (this.state.carrier) {
                this.setState({ ...this.state, visible: false });
                this.resolve(this.state.carrier);
            }
        }
    }

    private onCarrierSelect(value: number): void {
        this.setState({ ...this.state, carrier: value })
    }

    public show(action: TawreedAction): Promise<number> {
        //
        const title = computeTawreedActionTitle({ state: this.state }, action.title);
        //
        this.setState({ ...this.state, visible: true, title: title });
        return new Promise<number>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    public render() {
        //
        //
        return (
            <Dialog header={tawreedTranslationService.translate(this.state.title)}
                footer={this.OrderConfirmDialogFooter()}
                onHide={() => this.onHide()}
                visible={this.state.visible}
                position="top"
                breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '33vw' }}
                draggable={false}
                closable={false}
                onShow={() => {
                    this.setState({
                        visible: true,
                        carrier: this.props.carrier,
                    });
                }}
                focusOnShow
                id="orders_confirm_dialog"
                closeOnEscape={false}>
                <div className="grid formgrid">
                    <TawreedFormField name="address" title="lbl_partners_address_address" className="field col-12">
                        <TawreedInputText render="standalone" disabled value={this.props.customer?.address?.address} />
                    </TawreedFormField>

                    {/* <TawreedFormField name="carrier" title="lbl_sales_orders_carrier" className="field col-12">
                        <PartnerDropdownComponent partnerType={PartnerType.Delivery}
                            preSuggestions={(this.props.partner && !this.props.payThroughUs) ? [this.props.partner] : undefined}
                            dropdownValue={this.state.carrier}
                            dropdownName="carrier"
                            dropdownOptionLabel="partnerName"
                            dropdownOptionValue="partnerId"
                            dropDownPreSelected={value => this.onCarrierSelect(value)}
                            dropdownOnChange={e => this.onCarrierSelect(e.value)}
                            dropdownRender="standalone" />
                    </TawreedFormField> */}

                    <TawreedFormField name="carrier" title="lbl_sales_orders_carrier" className="field col-12">
                        <DeliveryDropdownComponent
                            salesOrderId={this.props.salesorderId!}
                            dropdownValue={this.state.carrier}
                            dropdownName="carrier"
                            dropdownOptionLabel= {AuthUtils.current().language ==="en" ? "titleEn" : "titleAr"}
                            dropdownOptionValue="partnerId"
                            dropDownPreSelected={value => this.onCarrierSelect(value)}
                            dropdownOnChange={e => this.onCarrierSelect(e.value)}
                            dropdownRender="standalone" />
                    </TawreedFormField>
                </div>
            </Dialog>
        );
    }
}
