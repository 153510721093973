import { PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { WalletTransactionRepo } from "../../data";
import { WalletTransactionDto } from "../dtos";

export class WalletTransactionService {

    private readonly repository;

    constructor() {
        this.repository = new WalletTransactionRepo();
    }

    public search(req: PaginatedRequestParams): Promise<PaginatedResponse<WalletTransactionDto>> {
        return this.repository.search(req);
    }
    public getDetails(id: number): Promise<WalletTransactionDto> {
        return this.repository.getDetails(id);
    }


}
