import { PrimeIcons } from "primereact/api";
import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { TawreedTable, TawreedTableColumnProps, TawreedTableProps } from "../../../../common/components/table";
import { GlobalContext } from "../../../../context";
import { ConnectionDto, PartnersService } from "../../domain";
import { tawreedTranslationService } from "../../../../common/translations";
import { CssUtils } from "../../../../helpers/css";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { TawreedFormField, TawreedMenuDropdown } from "../../../../common";
import { Routes } from "../../../../router";
import { PartnerAutoCompleteComponent } from "./autocomplete";
import { PartnerType } from "../../data";

export interface ConnectionListProps {
}
export interface ConnectionListState {
    classification: number | undefined,
    ids: Array<number>
}
export interface ConnectionState {
    classification: number | undefined,
    fromId: number | undefined,
    toId: number | undefined,
}
/**
 *
 * @param param0
 * @returns
 */
export const ConnectionList: FC<ConnectionListProps> = ({ }) => {

    const service = new PartnersService();
    const [reload, setReload] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [addDialogVisible, setAddDialogVisible] = React.useState(false);
    const [state, setState] = React.useState<ConnectionListState>({
        classification: undefined,
        ids: []
    });
    const { auth: { user } } = React.useContext(GlobalContext);
    const [addState, setAddState] = React.useState<ConnectionState>({
        classification: undefined,
        fromId: user?.roleCode === 'STORE_ADMIN' ? user.partnerId: undefined,
        toId: undefined
    });

    const history = useHistory();

    const Add = (): Promise<void> => {
        return new Promise((resolve) => {
            setAddDialogVisible(false);
            service.addClassification(addState.fromId!, addState.toId! ,addState.classification!).then((res) => {
                setReload(true);
            });
        });
    }

    const update = (): Promise<void> => {
        return new Promise((resolve) => {
            setVisible(false);
            service.setClassification(state.ids, state.classification!).then((res) => {
                setReload(true);
            });
        });
    }
    const onUpdate = (sender?: ConnectionDto | Array<ConnectionDto>): Promise<void> => {
        return new Promise((_resolve, reject) => {
            console.log(sender);
            if (!sender) {
                reject('No item selected');

            } else if (Array.isArray(sender)) {

                setReload(false);

                setState({ classification: undefined, ids: sender.map(item => item.partnerConnectionId) });
                setVisible(true);
            } else {
                setReload(false);

                setState({ classification: undefined, ids: [sender.partnerConnectionId] });
                setVisible(true);
            }
        })
    }
    const onRemove = (sender?: ConnectionDto | Array<ConnectionDto>): Promise<void> => {
        return new Promise((_resolve, reject) => {
            if (!sender) {
                reject('No item selected');

            } else if (Array.isArray(sender)) {

                setReload(false);

                return service.setClassification(sender.map(e => e.partnerConnectionId!), undefined).then(() => setReload(true)).catch(err => console.error(err));
            } else {
                setReload(false);
                return service.setClassification([sender.partnerConnectionId], undefined).then(() => setReload(true)).catch(err => console.error(err));
            }
        })
    }
    const onAdd = (): Promise<void> => {
        return new Promise((resolve) => {
            setReload(false);
            setAddDialogVisible(true);
        });
    }
    const getDependOnRole = () => {
        const arr = [];
        if (user?.roleCode !== 'STORE_ADMIN')
            arr.push({
                field: "fromPartner.partnerName",
                header: "lbl_partners_from_partner_name",
                filterField: "fromPartnerName",
                sortField: "fromPartnerName",
                sortable: true,
                filter: true,
                style: CssUtils.width('40%'),
            })
        return arr;
    }

    var columns: TawreedTableColumnProps[] = [

        {
            field: "toPartner.partnerName",
            header: "lbl_partners_to_partner_name",
            filterField: "toPartnerName",
            sortField: "toPartnerName",
            sortable: true,
            filter: true,
            style: user?.roleCode !== 'STORE_ADMIN' ? CssUtils.width('40%') : CssUtils.width('50%'),
        },
        {
            field: "classificationLabel",
            header: "lbl_partners_Classification",
            filterField: "classificationLabel",
            filter: true,
            style: user?.roleCode !== 'STORE_ADMIN' ? CssUtils.width('20%') : CssUtils.width('50%'),
        },
        {
            field: "actions",
            body: (options: any) => {
                return <React.Fragment>
                    <Button
                        type="button"
                        style={{ height: "35px" }}
                        icon={PrimeIcons.EYE}
                        className="p-button-success p-button-text"
                        tooltip={tawreedTranslationService.translate('lbl_info')}
                        onClick={() => {
                            if(user?.roleCode ==='STORE_ADMIN' || user?.roleCode === 'STORE_SALES_MAN'){
                                history.push(Routes.PartnersCustomersView.replace(':id', options!.partnerConnectionId.toString()));
                            }
                            else{
                                history.push(Routes.PartnersConnectionView.replace(':id', options!.partnerConnectionId.toString()));
                            }

                        }} />
                </React.Fragment>
            },
            style: CssUtils.width('10%'),
        }
    ];
    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        reload: reload,
        dataKey: 'partnerConnectionId',
        name: 'tbl.connections',
        title: 'lbl_connections',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.searchConnections(req),
        },
        columns: [
            ...getDependOnRole(),
            ...columns,

        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.PartnerConnectionSetClassification, 'statefull', (e) => tawreedTranslationService.translate('lbl_set', e?.length ?? 0), PrimeIcons.PENCIL, onUpdate),
                new TawreedAction(TawreedActionName.PartnerConnectionRemoveClassification, 'statefull', (e) => tawreedTranslationService.translate('lbl_remove', e?.length ?? 0), PrimeIcons.TRASH, onRemove, 'p-button-danger ml-1', 'confirm'),
                new TawreedAction(TawreedActionName.PartnerConnectionAddClassification, 'stateless', (e) => tawreedTranslationService.translate('lbl_add_connection'), PrimeIcons.PLUS, onAdd),
            ],
        },
        row: {},
        filter: {
            filterDisplay: 'menu',
            initial: {
                'fromPartnerName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'toPartnerName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'classificationLabel': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                }
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'toPartnerName',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
        },
    }
    const DialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" disabled={false} />

            <Button label={tawreedTranslationService.translate('lbl_save')} icon="pi pi-check" onClick={() => update()}
                disabled={state.classification === undefined}
            />
        </div>);
    }
    const AddClassificationFooter = () =>{
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={() => setAddDialogVisible(false)} className="p-button-text" disabled={false} />

            <Button label={tawreedTranslationService.translate('lbl_save')} icon="pi pi-check" onClick={() => Add()}
                disabled={!addState.classification || !addState.fromId || !addState.toId}
            />
        </div>);
    }
    return (
        <React.Fragment>
            <TawreedTable  {...tawreed} />

            {visible && <Dialog style={{ minWidth: '60%' }} header={tawreedTranslationService.translate("lbl_setItem")} visible={visible} footer={<DialogFooter />} onHide={() => setVisible(false)} draggable={false} position="top" closable={true} closeOnEscape={true} maximizable>

                <TawreedFormField name="amount" title={"lbl_partners_Classification"} className="field col-12 lg:col-12 md:col-12">
                    <TawreedMenuDropdown menu="classifications" render="standalone"
                        value={state?.classification} onChange={(e) => setState({ ...state, classification: e.value })} />
                </TawreedFormField>
            </Dialog>
            }
            {addDialogVisible && 
            <Dialog style={{ minWidth: '60%' }} header={tawreedTranslationService.translate("lbl_add_connection")} visible={addDialogVisible} footer={<AddClassificationFooter />} onHide={() => setVisible(false)} draggable={false} position="top" closable={true} closeOnEscape={true} maximizable>
            <div className="grid">
                { user?.roleCode !== 'STORE_ADMIN' &&
                <TawreedFormField className="field col-12 md:col-6" name="store" title="lbl_partners_from_partner_name">
                    <PartnerAutoCompleteComponent partnerType={PartnerType.Store}
                        active={true}
                        autoCompleteName="store"
                        autoCompleteRules={{ required: 'msg_field_is_required' }}
                        autoCompleteField="partnerName"
                        autoCompleteOnSelect={e => {
                            // props.onSelect(e.value);
                            // setFocus('storeProduct');
                            setAddState({...addState, fromId: e.value.partnerId})
                        }}
                        // autoCompleteDisabled={props.disabled}
                        autoCompleteRender="form" />
                </TawreedFormField>
}
                <TawreedFormField className="field col-12 md:col-6" name="customer" title="lbl_partners_to_partner_name">
                    <PartnerAutoCompleteComponent partnerType={PartnerType.Customer}
                        active={true}
                        autoCompleteName="customer"
                        autoCompleteRules={{ required: 'msg_field_is_required' }}
                        autoCompleteField="partnerName"
                        autoCompleteOnSelect={e => {
                            // props.onSelect(e.value);
                            // setFocus('storeProduct');
                            setAddState({...addState, toId: e.value.partnerId})
                        }}
                        // autoCompleteDisabled={props.disabled}
                        autoCompleteRender="form" />
                </TawreedFormField>
                <TawreedFormField name="classification" title={"lbl_partners_Classification"} className="field col-12 lg:col-12 md:col-12">
                    <TawreedMenuDropdown menu="classifications" render="standalone"
                        value={addState?.classification} onChange={(e) => setAddState({ ...addState, classification: e.value })} />
                </TawreedFormField>
                </div>
            </Dialog>
            }
        </React.Fragment>

    );
}