
import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { CommissionMapper } from "../../data";
import { CommissionDto } from "../../domain";
import { JObject } from "../../../../common/data/models";

export class CommissionRepository {

    public getByStoreProductIdAndPartnerId(priceListId: number, partnerId: number): Promise<CommissionDto> {
        return tawreedHttpService.post<JObject, JObject>(endpoints.COMMISSION_GET_BY_PRICE_LIST_ID_AND_PARTNER_ID, { priceListId, partnerId }, undefined, undefined, 'error')
            .then(res => {
                console.log(res);
                return CommissionMapper.toDto(CommissionMapper.fromJson(res.data!));
            });
    }
    public getByStoreProductId(partnerId: number): Promise<CommissionDto[]> {
        return tawreedHttpService.post<JObject, JObject>(endpoints.COMMISSION_GET_BY_PRICE_LIST_ID_AND_PAYMENT_MODE_ID, { partnerId }, undefined, undefined, 'error')
            .then(res => {
                console.log(res);
                return res.data?.map((item:any) => CommissionMapper.toDto(CommissionMapper.fromJson(item)));
            });
    }
    public createOrUpdate(dto: CommissionDto): Promise<CommissionDto> {
        return tawreedHttpService.post<JObject, JObject>(endpoints.COMMISSION_CREATE_OR_UPDATE, dto, undefined, undefined, 'error')
            .then(res => {
                return CommissionMapper.toDto(CommissionMapper.fromJson(res.data!));
            });
    }
    public createOrUpdateAll(dtos: CommissionDto[]): Promise<CommissionDto> {
        return tawreedHttpService.post<JObject[], JObject>(endpoints.COMMISSION_CREATE_OR_UPDATE, dtos, undefined, undefined, 'error')
            .then(res => {
                return CommissionMapper.toDto(CommissionMapper.fromJson(res.data!));
            });
    }
    public checkPrice(dto: CommissionDto): Promise<JObject> {
        return tawreedHttpService.post<JObject, JObject>(endpoints.COMMISSION_CHECK_PRICE, dto, undefined, undefined, 'none')
            .then(res => {
                return res.data!;
            });
    }
    public validateComissionOrderMin(dto: CommissionDto): Promise<JObject> {
        return tawreedHttpService.post<JObject, JObject>(endpoints.COMMISSION_CHECK_ORDER_MIN, dto, undefined, undefined, 'error')
            .then(res => {
                return res.data!;
            });
    }

    public deleteCommissionById(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.PRICE_SCHEME_DELETE_COMMISSION_BY_ID, id).then(res => res.status === 200);
    }





}
