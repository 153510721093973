import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { CategoryService, SettingCategoryDto } from '../../domain';
import { TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputFile, TawreedInputText } from '../../../../common';
import { TawreedAction, TawreedActionName } from '../../../../common/actions';
import { PrimeIcons } from 'primereact/api';
import { BusniessTypeMultiSelect } from './BusniessTypeMultiSelect';
import { Panel } from 'primereact/panel';
import { tawreedTranslationService } from '../../../../common/translations';
import { Routes } from '../../../../router';


export const CategoryForm: React.FC = (props) => {
    const { id } = useParams<{ id: string | undefined }>();
    const service: CategoryService = React.useMemo(() => new CategoryService(), []);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<SettingCategoryDto>();
    const history = useHistory();

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {

                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {

            if (mounted) {
                setMode('Create');
            }
        }
        return () => {
            mounted = false;
        };
    }, [id, service]);
    const onCreateOrSave = (data: SettingCategoryDto, cb: TawreedFormSubmitCallback<SettingCategoryDto>): Promise<void> => {

        setLoading(true);
        console.log(data);
        return service.addOrUpdata(data)
            .then(res => {
                setLoading(false);
                if (!data.categoryId && res.categoryId) {
                    history.replace(Routes.SettingsCategoryDetials.replace(':id', res.categoryId.toString()));
                }
                cb(res);
            })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    }
    const onDelete = () => {
        setLoading(true);
        return service.delete(data?.categoryId!).then(res => {
            setLoading(false);
            if (res) {
                history.replace(Routes.SettingsCategory);
            }
        }).catch(err => {
            setLoading(false);
            throw err;
        });
    }

    const onSubmit: TawreedFormSubmitProps<SettingCategoryDto> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<SettingCategoryDto>(TawreedActionName.CategoryNew, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave),
    };



    return (
        <React.Fragment>
            <TawreedForm title="lbl_category"
                dataKey="categoryId"
                data={data}
                submit={onSubmit}
                loading={loading} mode={mode}
                useBack=".." useReset actions={data?.categoryId ? [new TawreedAction(TawreedActionName.CategoryDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete')] : []} className="grid form-grid">

                <Panel header={tawreedTranslationService.translate('lbl_category_id')} className="col-12">

                    <div className="grid">


                        <TawreedFormField className="field col-12 md:col-12" name="categoryAr" title="lbl_category_ar">
                            <TawreedInputText name="categoryAr" rules={{ required: 'msg_field_is_required' }} render="form" />
                        </TawreedFormField>

                        <TawreedFormField className="field col-12 md:col-12" name="categoryEn" title="lbl_category_en">
                            <TawreedInputText name="categoryEn" rules={{ required: 'msg_field_is_required' }} render="form" />
                        </TawreedFormField>
                        <TawreedFormField name="businessTypes" className="field col-12 md:col-12" title="lbl_partners_business_type">
                            <BusniessTypeMultiSelect disabled={false} menu={'businessTypesCustomer'} name="businessTypes" render="form" rules={{ required: 'msg_field_is_required' }} />
                        </TawreedFormField>

                        <div className="col-12">
                            <div className="grid">
                                <TawreedFormField name="image" className="field col-12 md:col-3" title="lbl_category_image">
                                    <TawreedInputFile render="form" name="image" accept="image/*" />
                                </TawreedFormField>
                            </div>
                        </div>
                    </div>
                </Panel>
            </TawreedForm>

        </React.Fragment >
    )
}
