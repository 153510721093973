import React from 'react'
import { TawreedReportGenerator } from './report-generator'
import { TawreedCalendar, TawreedDropdown, TawreedFormField, TawreedInputText } from "../../../../common/components/form";
import { PartnerAutoCompleteComponent } from "../../../partners";
import { GlobalContext } from "../../../../context";
import { DateUtils } from "../../../../helpers/date";


/**
 *
 * @constructor
 */
export const ReportFinancialDocuments: React.FC = () => {

    const { constants: { constants }, auth: { user } } = React.useContext(GlobalContext);

    return (
        <TawreedReportGenerator name="documents" title="lbl_reports_financial_documents" initial={{ startDate: DateUtils.now(-90), endDate: DateUtils.now() }}>
            <TawreedFormField name="startDate" title="lbl_reports_start_date" className="field col-12 md:col-6">
                <TawreedCalendar render="form" name="startDate" rules={{ required: 'msg_field_is_required' }} />
            </TawreedFormField>

            <TawreedFormField name="endDate" title="lbl_reports_end_date" className="field col-12 md:col-6">
                <TawreedCalendar render="form" name="endDate" rules={{ required: 'msg_field_is_required' }} />
            </TawreedFormField>

            {(user?.roleCode === 'ADMIN' || user?.roleCode === 'MANAGER' || user?.roleCode === 'SYS_ADMIN' || user?.roleCode === 'FINANCE') && <TawreedFormField name="partner" title="lbl_reports_financial_documents_partner" className="field col-12 md:col-6">
                <PartnerAutoCompleteComponent partnerType={undefined} autoCompleteName="partner" autoCompleteField="partnerName" autoCompleteRender="form" />
            </TawreedFormField>}

            <TawreedFormField name="documentNo" title="lbl_reports_financial_documents_document_no" className="field col-12 md:col-6">
                <TawreedInputText render="form" name="documentNo" />
            </TawreedFormField>

            <TawreedFormField name="typeId" title="lbl_reports_financial_documents_type_id" className="field col-12 md:col-6">
                <TawreedDropdown render="form" name="typeId" options={constants?.documentTypes ?? []} optionLabel="label" dataKey={"value"} optionValue="value" />
            </TawreedFormField>
        </TawreedReportGenerator>
    )
}
