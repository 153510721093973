import { JObject } from "../../../../common/data/models";
import { PartnerMapper, PartnerType } from "../../../partners/data";
import { DeliveryCost } from "../../domain";

export const DeliveryCostMapper = {

    fromJson(obj: JObject): DeliveryCost {
        return {
            createdAt: obj.createdAt,
            updatedAt: obj.updatedAt,
            deliveryCost: obj.deliveryCost,
            deliveryCostId: obj.deliveryCostId,
            deliveryPartnerId: obj.deliveryPartnerId,
            deliverySelling: obj.deliverySelling,
            state: obj.state,
            stateId: obj.stateId
        };
    },

    toJson(obj: DeliveryCost): JObject {
        return {
            createdAt: obj.createdAt,
            updatedAt: obj.updatedAt,
            deliveryCost: obj.deliveryCost,
            deliveryCostId: obj.deliveryCostId,
            deliveryPartnerId: obj.deliveryPartnerId,
            deliverySelling: obj.deliverySelling,
            state: obj.state,
            stateId: obj.stateId
        };
    }
}
