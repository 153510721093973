import { SalesDto } from "../../domain";
import { PrimeIcons } from "primereact/api";
import { ObjectUtils } from "../../../../helpers/object";
import totalImg from "../../../../assets/dashoardicon/Total.svg"


export const SalesMapper = {

    fromJson(sales: number | undefined, scale?: number): SalesDto {
        return {
            name: 'sales',
            label: 'lbl_dashboard_sales_total',
            image: totalImg,
            icon: PrimeIcons.BARS,
            value: (constants) => `${ObjectUtils.toCurrency(sales,scale)} ${constants.info.currencyCode}`,
            to: undefined,
        };
    },
}
